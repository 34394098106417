import React, { FC } from "react";
import { Button, Flex } from "antd";
import { InvoicesListModule } from "@inv/modules";
import { PageHeader } from "@inv/components/PageHeader/PageHeader";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { Path } from "@inv/types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { InvoiceListHeader } from "@inv/components/InvoiceListHeader";

export const InvoicesListLayout: FC = () => {
    return (
        <Flex
            vertical
            style={{
                height: "100%",
            }}
        >
            <InvoiceListHeader />
            <InvoicesListModule />
        </Flex>
    );
};

import React, { createContext, FC, ReactNode } from "react";
import { BanksApi } from "@banks/scripts/api";
import { ITransaction, ITransactionUpdateInput } from "@banks/types";

type TValue = object;
type TActionValue = {
    updateTransaction: (
        arg: ITransactionUpdateInput & {
            contact: {
                name: string;
                id: string;
            };
        }
    ) => Promise<true | undefined>;
};

const initialActionValue = {
    updateTransaction: () => Promise.reject(),
};

export const TransactionEditContext = createContext<TValue>({});
export const TransactionEditControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const EditBankContextProvider: FC<TProps> = ({ children }) => {
    const value = {};

    const actions = {
        updateTransaction: async (
            arg: ITransactionUpdateInput & {
                contact: {
                    name: string;
                    id: string;
                };
            }
        ): Promise<true> => {
            const { contact, ...data } = arg;

            if (arg.contact) {
                data.contactId = contact.id;
            }
            return (await BanksApi.transactionTransaction({ ...data })) as unknown as true | undefined;
        },
    };

    return (
        <TransactionEditContext.Provider value={value}>
            <TransactionEditControlContext.Provider value={actions}>{children}</TransactionEditControlContext.Provider>
        </TransactionEditContext.Provider>
    );
};

import { IBank } from "@banks/types";

export const getTransactionBankTitle = (arg?: { exist?: IBank; missing?: { iban?: string } }) => {
    if (arg?.exist) {
        const accountInfo = arg.exist.iban
            ? `IBAN: ${arg.exist.iban}`
            : `BANK ID: ${arg.exist.bankId}, account number: ${arg.exist.accountNumber}`;

        return `${arg.exist.bankName}, ${accountInfo}`;
    }

    if (arg?.missing) {
        return `Please, create new Bank ${arg?.missing.iban ? `, with IBAN: ${arg?.missing.iban}` : ""}`;
    }

    return "Bank not defined. Select Bank or match IBAN field";
};

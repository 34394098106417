import { useIntl } from "react-intl";
import { useContext, useMemo } from "react";
import { CompanyContext } from "../../scripts/context/CompanyContext";
import { InvoicesListContext } from "@inv/modules/InvocesListModule/context";
import { ContactsContext } from "../../scripts/context/ContactsContext";
import { Contacts } from "@binale-tech/shared";
import { decimalFormatter, formatDefault } from "@dms/scripts/helpers";
import { transformServicePeriod } from "@inv/scripts/utils/utils";
import { getName } from "i18n-iso-countries";
// eslint-disable-next-line import/no-unresolved
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { createPdf } from "pdfmake/build/pdfmake";
import pdfMakeFonts from "../../scripts/workers/pdf/pdfmake.fonts";
import { InvoiceFormInputTranslate, InvoiceLayout, InvoicesListColumn, InvoicesListColumnTranslate } from "@inv/types";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";

const mmToPoints = (mm: number) => Math.round(mm * 2.83465);

const invoiceLayoutMm: Record<InvoiceLayout, number> = {
    [InvoiceLayout.TOP]: 10,
    [InvoiceLayout.LEFT]: 20,
    [InvoiceLayout.RIGHT]: 10,
    [InvoiceLayout.BOTTOM]: 10,

    [InvoiceLayout.HEADER_HEIGHT]: 2,
    [InvoiceLayout.FOOTER_HEIGHT]: 10,

    [InvoiceLayout.ADDRESS_TOP]: 35,
    [InvoiceLayout.ADDRESS_LEFT]: 200,
    [InvoiceLayout.ADDRESS_RIGHT]: 40,
    [InvoiceLayout.ADDRESS_HEIGHT]: 45,

    [InvoiceLayout.ADDRESS_WIDTH]: 85,
    [InvoiceLayout.ADDRESS_HEADER_HEIGHT]: 17.7,
    [InvoiceLayout.ADDRESS_CONTENT_HEIGHT]: 27.3,
    [InvoiceLayout.INFO_WIDTH]: 75,

    [InvoiceLayout.INFO_TOP]: 50,
    [InvoiceLayout.INFO_RIGHT]: 10,
    [InvoiceLayout.INFO_LEFT]: 20,

    [InvoiceLayout.CONTENT_SPACING]: 8.4,

    [InvoiceLayout.CONTENT_LEFT]: 20,
    [InvoiceLayout.CONTENT_RIGHT]: 10,

    [InvoiceLayout.CONTENT_WIDTH]: 170,
};

const invoiceLayoutPt: Record<InvoiceLayout, number> = Object.entries(invoiceLayoutMm).reduce(
    (result, [key, value]) => {
        return {
            ...result,
            [key as InvoiceLayout]: mmToPoints(value),
        };
    },
    {} as Record<InvoiceLayout, number>
);

export const useGenerateInvoicePdf = (invoiceId: string) => {
    const intl = useIntl();
    const { companyGQL } = useContext(CompanyContext);
    const { invoicesData } = useContext(InvoicesListContext);
    const { contacts } = useContext(ContactsContext);

    const { invoiceEmail, invoiceAddress, invoiceReceiver, legalName, legalForm, invoiceTax } = companyGQL;
    const invoiceData = useMemo(
        () => invoicesData.find(invoice => invoice.id === invoiceId),
        [invoicesData, invoiceId]
    );

    const { lineItems, currencyRate, isTaxIncluded } = invoiceData;
    const contact = contacts.find(c => invoiceData?.contactId === c.uuid);
    const contactName = contact ? Contacts.getLabelName(contact) : null;

    const { subtotal, tax7, tax19, total } = useMemo(() => {
        let originalAmount = 0;
        let resultTotal = 0;
        let calculateTax7 = 0;
        let calculateTax19 = 0;

        lineItems.forEach(item => {
            const totalWithoutTaxForItem = item.discount * item.quantity;
            const taxForItem = totalWithoutTaxForItem * (item.tax / 100);
            const totalWithTaxForItem = totalWithoutTaxForItem + taxForItem;

            originalAmount += totalWithoutTaxForItem;
            resultTotal += totalWithTaxForItem;

            if (item.tax === 7) {
                calculateTax7 += taxForItem;
            } else if (item.tax === 19) {
                calculateTax19 += taxForItem;
            }
        });

        if (!isTaxIncluded) {
            return {
                subtotal: formatDefault(originalAmount),
                tax7: null,
                tax19: null,
                total: null,
            };
        }

        return {
            subtotal: formatDefault(originalAmount),
            tax7: calculateTax7 ? formatDefault(calculateTax7) : null,
            tax19: calculateTax19 ? formatDefault(calculateTax19) : null,
            total: formatDefault(resultTotal),
        };
    }, [isTaxIncluded, lineItems]);

    const serviceDate = useMemo(() => {
        const servicePeriodDaysValue = invoiceData?.servicePeriodDays
            ? transformServicePeriod(invoiceData?.servicePeriodDays)
            : null;
        const servicePeriodMonthsValue = invoiceData?.servicePeriodMonths
            ? transformServicePeriod(invoiceData?.servicePeriodMonths)
            : null;

        return (
            invoiceData?.serviceDate ?? invoiceData?.deliveryDate ?? servicePeriodDaysValue ?? servicePeriodMonthsValue
        );
    }, [invoiceData]);

    const ContactContent = [
        contactName
            ? {
                  text: contactName,
                  bold: true,
                  marginBottom: 2,
              }
            : null,
        invoiceData?.street && invoiceData?.houseNumber
            ? { text: `${invoiceData.street} ${invoiceData.houseNumber}`, marginBottom: 2 }
            : null,
        invoiceData?.city && invoiceData?.zipCode
            ? { text: `${invoiceData.zipCode} ${invoiceData.city}`, marginBottom: 2 }
            : null,
        invoiceData?.countryCode ? { text: getName(invoiceData.countryCode, intl.locale), marginBottom: 2 } : null,
    ].filter(Boolean);

    const AnschriftContent = [
        invoiceReceiver ? { text: invoiceReceiver, bold: true, marginBottom: 2 } : null,
        invoiceAddress?.street && invoiceAddress?.house
            ? { text: `${invoiceAddress.street} ${invoiceAddress.house}`, marginBottom: 2 }
            : null,
        invoiceAddress?.city && invoiceAddress?.postCode
            ? { text: `${invoiceAddress.postCode} ${invoiceAddress.city}`, marginBottom: 2 }
            : null,
        invoiceAddress?.countryCode
            ? { text: getName(invoiceAddress.countryCode, intl.locale), marginBottom: 2 }
            : null,
    ].filter(Boolean);

    const TableBody = [
        [
            { text: intl.formatMessage({ id: InvoiceFormInputTranslate.PRODUCTS_SERVICES }), alignment: "center" },
            { text: intl.formatMessage({ id: InvoiceFormInputTranslate.UNIT }), alignment: "center" },
            { text: intl.formatMessage({ id: InvoiceFormInputTranslate.QUANTITY }), alignment: "center" },
            {
                text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.PRICE })} (${invoiceData.currencyCode})`,
                alignment: "center",
            },
            ...(isTaxIncluded
                ? [
                      {
                          text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.TAX })} (%)`,
                          alignment: "center",
                      },
                  ]
                : []),
            { text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.DISCOUNT })} (%)`, alignment: "center" },
            {
                text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.TOTAL })} (${invoiceData.currencyCode})`,
                alignment: "center",
            },
        ].filter(Boolean),
        ...lineItems
            .map((item, index) => {
                return [
                    { text: `${index + 1}) ${item.productsServices ? item.productsServices : "Product"}` },
                    {
                        text: InvoiceFormColumnsConfig.unitOption.find(opt => opt.value === item.unit)?.label ?? "",
                    },
                    { text: item.quantity, alignment: "right" },
                    { text: formatDefault(item.price), alignment: "right" },
                    ...(isTaxIncluded ? [{ text: item.tax, alignment: "right" }] : []),
                    { text: decimalFormatter(((item.price - item.discount) / item.price) * 100), alignment: "right" },
                    { text: formatDefault(item.discount * item.quantity), alignment: "right" },
                ];
            })
            .filter(Boolean),
    ];

    const tableWidths = ["*", 50, 50, 70].concat(isTaxIncluded ? [40, 65, 70] : [65, 70]);

    const TableTotalBody = [
        subtotal
            ? [
                  {
                      text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.TOTAL_AMOUNT })} (Netto)`,
                  },
                  { text: subtotal ?? "", alignment: "right" },
              ]
            : null,
        tax7 ? ["zzgl. Umsatzsteuer 7%", { text: tax7 ?? "", alignment: "right" }] : null,
        tax19 ? ["zzgl. Umsatzsteuer 19%", { text: tax19 ?? "", alignment: "right" }] : null,
        total
            ? [
                  {
                      text: `${intl.formatMessage({ id: InvoiceFormInputTranslate.TOTAL_AMOUNT })} (Brutto)`,
                      bold: true,
                  },
                  { text: total ?? "", alignment: "right", bold: true },
              ]
            : null,
    ].filter(Boolean);

    return () => {
        const docDefinition: TDocumentDefinitions = {
            pageSize: "A4",
            pageMargins: [
                invoiceLayoutPt[InvoiceLayout.LEFT],
                invoiceLayoutPt[InvoiceLayout.TOP] +
                    invoiceLayoutPt[InvoiceLayout.HEADER_HEIGHT] +
                    invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                invoiceLayoutPt[InvoiceLayout.RIGHT],
                invoiceLayoutPt[InvoiceLayout.BOTTOM] +
                    invoiceLayoutPt[InvoiceLayout.FOOTER_HEIGHT] +
                    invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
            ],
            header(currentPage, pageCount) {
                return {
                    text: `Seite: ${currentPage.toString()} / ${pageCount}`,
                    marginTop: invoiceLayoutPt[InvoiceLayout.TOP],
                    marginRight: invoiceLayoutPt[InvoiceLayout.RIGHT],
                    alignment: "right",
                    fontSize: 8,
                };
            },
            footer: [
                {
                    fontSize: 7,
                    opacity: 0.7,
                    columnGap: 10,
                    lineHeight: 1.2,
                    marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                    marginLeft: invoiceLayoutPt[InvoiceLayout.LEFT],
                    columns: [
                        {
                            stack: [
                                `Sitz der Gesellschaft: ${invoiceAddress.city}`,
                                `USt-ID (VAT): ${invoiceTax.bundesland} ${invoiceTax.stnr}`,
                            ],
                        },
                        {
                            stack: [
                                // "Kreditinstitut: Olinda",
                                // "IBAN: DE60 1001 0123 3396 3165 30",
                                // "BIC/SWIFT: QNTODEB2XXX",
                            ],
                        },
                        {
                            stack: ["Komplementär:", `${legalName} ${legalForm}, ${invoiceAddress.city}`],
                        },
                        {
                            stack: ["Geschäftsführer:", `Dipl.-Kfm. ${invoiceReceiver}`],
                        },
                    ],
                },
            ],
            content: [
                {
                    columns: [
                        {
                            marginTop: invoiceLayoutPt[InvoiceLayout.ADDRESS_TOP],
                            width: invoiceLayoutPt[InvoiceLayout.ADDRESS_WIDTH],
                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEIGHT],
                            stack: [
                                {
                                    stack: ContactContent,
                                    fontSize: 10,
                                },
                            ],
                        },
                        {
                            marginLeft: invoiceLayoutPt[InvoiceLayout.INFO_LEFT],
                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEIGHT],
                            marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                            stack: [
                                {
                                    opacity: 0.7,
                                    fontSize: 8,
                                    columnGap: 10,
                                    lineHeight: 1.1,
                                    marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                                    columns: [
                                        {
                                            text: "Anschrift",
                                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                            alignment: "right",
                                        },
                                        {
                                            stack: AnschriftContent,
                                            height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                        },
                                    ],
                                },
                                {
                                    opacity: 0.7,
                                    fontSize: 8,
                                    columnGap: 10,
                                    lineHeight: 1.1,
                                    marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                                    columns: invoiceEmail
                                        ? [
                                              {
                                                  text: "Email",
                                                  height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                                  alignment: "right",
                                              },
                                              {
                                                  text: invoiceEmail,
                                                  height: invoiceLayoutPt[InvoiceLayout.ADDRESS_HEADER_HEIGHT],
                                              },
                                          ]
                                        : null,
                                },
                                {
                                    columnGap: 10,
                                    columns: [
                                        {
                                            lineHeight: 1.1,
                                            alignment: "right",
                                            fontSize: 10,
                                            stack: [
                                                {
                                                    text: "Rechnungsnummer",
                                                    bold: true,
                                                },
                                                "Rechnungsdatum",
                                                "Leistungsdatum",
                                            ],
                                        },
                                        {
                                            lineHeight: 1.1,
                                            fontSize: 10,
                                            stack: [
                                                {
                                                    text: invoiceData.invoiceNumber
                                                        ? invoiceData.invoiceNumber
                                                        : "XX-XXXX",
                                                    bold: true,
                                                },
                                                {
                                                    text: invoiceData.date ? invoiceData.date : "MM.DD.YYYY",
                                                },
                                                {
                                                    text: serviceDate ? serviceDate : "Empty",
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                invoiceData.documentTitle
                    ? {
                          fontSize: 10,
                          text: invoiceData.documentTitle,
                          marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                      }
                    : null,
                invoiceData.introductionText
                    ? {
                          fontSize: 10,
                          text: invoiceData.introductionText,
                          marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                      }
                    : null,
                {
                    marginTop: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                    width: invoiceLayoutPt[InvoiceLayout.CONTENT_WIDTH],
                    stack: [
                        {
                            fontSize: 10,
                            table: {
                                headerRows: 1,
                                widths: tableWidths,

                                body: TableBody,
                            },
                            layout: {
                                fillColor: (rowIndex: number, node: any) => (rowIndex === 0 ? "#DEEAF6" : null),
                            },
                        },
                        {
                            fontSize: 10,
                            bold: true,
                            table: {
                                headerRows: 1,
                                widths: ["*", 70],
                                body: TableTotalBody,
                            },
                            layout: {
                                fillColor: (rowIndex: number, node: any) =>
                                    rowIndex === node.table.body.length - 1 ? "#DEEAF6" : null,
                            },
                            marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                        },
                        invoiceData.description
                            ? {
                                  fontSize: 10,
                                  text: invoiceData.description,
                                  marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                              }
                            : null,
                        // {
                        //     fontSize: 10,
                        //     marginBottom: invoiceLayoutPt[InvoiceLayout.CONTENT_SPACING],
                        //     bold: true,
                        //     alignment: "left",
                        //     columns: [
                        //         {
                        //             marginRight: 10,
                        //             width: "auto",
                        //             alignment: "left",
                        //             stack: [
                        //                 {
                        //                     text: "Empfänger:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "Kreditinstitut:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "IBAN:",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "BIC/SWIFT:",
                        //                     marginBottom: 2,
                        //                 },
                        //             ],
                        //         },
                        //         {
                        //             alignment: "left",
                        //             width: "*",
                        //             stack: [
                        //                 {
                        //                     text: "Binale GmbH & Co. KG",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "Olinda",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "DE60 1001 0123 3396 3165 30",
                        //                     marginBottom: 2,
                        //                 },
                        //                 {
                        //                     text: "QNTODEB2XXX",
                        //                     marginBottom: 2,
                        //                 },
                        //             ],
                        //         },
                        //     ],
                        // },
                    ],
                },
            ],
        };
        return new Promise(resolve => {
            createPdf(docDefinition, null, null, pdfMakeFonts).getBlob(blob => {
                const url = URL.createObjectURL(blob);
                const filename = "invoice.pdf";
                const type = "application/pdf";

                resolve({ url, filename, type });
            });
        });
    };
};

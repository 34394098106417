import { FormInstance } from "antd";
import React, { MutableRefObject } from "react";
import { GQL, Utils } from "@binale-tech/shared";
import { dropPressIdsEnum } from "@dms/components/DocumentForm/types/enums";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { CurrencyValue } from "@binale-tech/ui-components";
import { parseNumber } from "@dms/scripts/helpers";
import dayjs from "dayjs";

export const handlePress = (
    e: React.KeyboardEvent<HTMLFormElement>,
    keyType: "up" | "down",
    keyboardKeys: Set<string>,
    parentID: string
) => {
    const elementId = (e.target as HTMLElement).id;

    if (
        !(e.shiftKey && e.key === "Enter") &&
        (elementId === dropPressIdsEnum.SUBMIT ||
            elementId === dropPressIdsEnum.CANCEL ||
            elementId === dropPressIdsEnum.CURRENCY_SELECT)
    ) {
        return;
    }

    if (keyType === "down" && keyboardKeys.has(e.key)) {
        return;
    }

    if (keyType === "down") {
        if (keyboardKeys.has(e.key)) {
            return;
        }
        keyboardKeys.add(e.key);
    } else if (keyType === "up") {
        keyboardKeys.delete(e.key);
    }

    DmsUtils.formFocus(e, keyboardKeys, keyType, parentID);
};

export const handleKeyDownSubmit = (e: React.KeyboardEvent<HTMLElement>, form: FormInstance) => {
    if (!(e.shiftKey && e.key === "Enter") && e.key === "Enter") {
        e.stopPropagation();
        form.submit();
    }

    if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
    }
};
export const handleKeyDownCancel = (e: React.KeyboardEvent<HTMLElement>, handleCancel: () => void) => {
    if (e.shiftKey && e.key === "Enter") {
        return;
    }
    if (e.key === "Enter") {
        e.stopPropagation();
        handleCancel();
    }
};

const goNextElement = (type: GQL.ICurrencyCode) => {
    const selectEl = document.getElementById("currencySelectID");
    const amountEl = document.getElementById("currencyAmountID");
    const finalAmountEl = document.getElementById("currencyFinalAmountID");

    if (!selectEl) {
        return;
    }

    selectEl.blur();

    requestAnimationFrame(() => {
        if (type === GQL.ICurrencyCode.Eur) {
            finalAmountEl?.focus();
        } else {
            amountEl?.focus();
        }
    });
};

export const handleKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    {
        ccCode,
        prevCcCode,
        openOptions,
    }: { ccCode: GQL.ICurrencyCode; prevCcCode: MutableRefObject<GQL.ICurrencyCode>; openOptions: boolean }
) => {
    if (e.shiftKey && e.key === "Enter") {
        return;
    }

    if (e.key === "Enter") {
        goNextElement(ccCode);
        e.preventDefault();
    }

    if (e.key === "Enter" && openOptions) {
        let newValue;
        if (ccCode === prevCcCode.current) {
            newValue = ccCode;
        } else {
            newValue = ccCode === GQL.ICurrencyCode.Eur ? GQL.ICurrencyCode.Usd : GQL.ICurrencyCode.Eur;
        }
        goNextElement(newValue);
    }
};

export const composeCurrencyValue = (
    euroValue: number | null,
    code: GQL.ICurrencyCode,
    originalAmountStr: string,
    rateStr: string
): CurrencyValue => {
    const value: CurrencyValue = {
        amount: euroValue,
    };
    if (code !== GQL.ICurrencyCode.Eur) {
        value.originalAmount = Math.round(parseNumber(originalAmountStr) * 100);
        value.currency = {
            rate: parseNumber(rateStr),
            code,
        };
    }
    return value;
};

export const getEuroAmount = (originalAmountStr: string, rateStr: string) => {
    const originalAmountNum = Math.round(parseNumber(originalAmountStr) * 100);
    return Utils.CurrencyUtils.getEuroFromCurrency(originalAmountNum, parseNumber(rateStr) ?? 1);
};

export const isRetrieveCurrencyRate = (date: string): boolean => {
    if (!date) {
        return false;
    }
    const documentDate = dayjs(date, "DD.MM.YYYY");
    const currentDate = dayjs();

    return currentDate.isAfter(documentDate) || currentDate.isSame(documentDate);
};

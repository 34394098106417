import React, { type FC, useEffect, useState } from "react";
import { Modal } from "antd";
import { PdfViewer } from "@binale-tech/ui-components";
import { getAppCheckToken } from "../../../scripts/api/firebase/firebase";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import { GQL } from "@binale-tech/shared";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

export const ModalPdfViewer: FC<{
    document?: GQL.IDocument;
    onClose: () => void;
}> = ({ document, onClose }) => {
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [viewerUrl, setViewerUrl] = useState<string>();

    useEffect(() => {
        if (!document) {
            return;
        }
        getAppCheckToken().then(token => {
            setAppCheckToken(token);
            setViewerUrl(document.fileUrl);
        });
    }, [document]);

    if (!document) {
        return null;
    }

    return (
        <Modal
            open={Boolean(document)}
            onCancel={onClose}
            destroyOnClose
            closable={false}
            footer={null}
            style={{
                top: "5px",
                padding: "10px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            styles={{
                body: {
                    minWidth: "70vw",
                    height: `calc(100vh - 70px)`,
                    backgroundColor: "white",
                    display: "flex",
                },
            }}
        >
            {viewerUrl && (
                <PdfViewer
                    url={viewerUrl}
                    fileName={DmsUtils.getDownloadName(document)}
                    isModal
                    appCheckToken={appCheckToken}
                />
            )}
            <CloseButton onClose={onClose} />
        </Modal>
    );
};

import React, { createContext, FC, ReactNode, useContext } from "react";
import { BanksApi } from "@banks/scripts/api";
import { IBankCreateInput } from "@banks/types";
import { BanksAppControlContext } from "@banks/scripts/context";

type TValue = {};
type TActionValue = {
    createBank: (arg: IBankCreateInput) => Promise<void>;
};

const initialActionValue = {
    createBank: () => Promise.resolve(),
};

export const AddBankContext = createContext<TValue>({});
export const AddBankControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
    isNeedSave?: true;
};

export const AddBankContextProvider: FC<TProps> = ({ children, isNeedSave }) => {
    const { setLastBankCreateId } = useContext(BanksAppControlContext);
    const value = {};

    const actions = {
        createBank: async (arg: IBankCreateInput) => {
            const id = await BanksApi.createBank(arg);
            isNeedSave && setLastBankCreateId(id);
        },
    };

    return (
        <AddBankContext.Provider value={value}>
            <AddBankControlContext.Provider value={actions}>{children}</AddBankControlContext.Provider>
        </AddBankContext.Provider>
    );
};

import React, { createContext, FC, ReactNode, useState } from "react";
import { IInvoiceFormLineItem, TInvoicesValues } from "@inv/types";
import { GQL } from "@binale-tech/shared";
import { InvoicesApi } from "@inv/scripts/api";

type TValue = {
    initRow: IInvoiceFormLineItem;
    invoiceTotalView: boolean;
    initialValues: TInvoicesValues;
};

type TActionValue = {
    setInvoiceTotalView: (value: boolean) => void;
    setInitialValues: (value: TInvoicesValues) => void;
    createInvoice: (arg: TInvoicesValues) => Promise<void>;
};

const initialValue: TValue = {
    invoiceTotalView: true,
    initRow: {
        productsServices: "",
        quantity: 1,
        price: 0,
        tax: 0,
        discount: 0,
        total: 0,
    },
    initialValues: {
        id: null,
        contactId: null,
        countryCode: null,
        city: null,
        address: null,
        zipCode: null,
        street: null,
        invoiceNumber: null,
        customerNumber: null,
        date: null,
        documentTitle: null,
        introductionText: null,
        deliveryDate: null,
        serviceDate: null,
        servicePeriodDays: null,
        servicePeriodMonths: null,
        currencyCode: GQL.ICurrencyCode.Eur,
        isTaxIncluded: true,
        lineItems: [],
        paymentTerm: null,
        description: null,
    },
};

const initialActionValue = {
    setInvoiceTotalView: () => {},
    setInitialValues: () => {},
    createInvoice: () => Promise.resolve(),
};

export const InvoiceFormContext = createContext<TValue>(initialValue);
export const InvoiceFormControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoiceFormContextProvider: FC<TProps> = ({ children }) => {
    const initRow = { ...initialValue.initRow };
    const [invoiceTotalView, setInvoiceTotalView] = useState<boolean>(initialValue.invoiceTotalView);
    const [initialValues, setInitialValues] = useState<TInvoicesValues>({
        ...initialValue.initialValues,
        lineItems: [{ ...initRow }],
    });

    const value = { invoiceTotalView, initialValues, initRow };
    const actions = {
        setInvoiceTotalView,
        setInitialValues,
        createInvoice: async (arg: TInvoicesValues) => {
            await InvoicesApi.invoiceCreate(arg);
        },
    };

    return (
        <InvoiceFormContext.Provider value={value}>
            <InvoiceFormControlContext.Provider value={actions}>{children}</InvoiceFormControlContext.Provider>
        </InvoiceFormContext.Provider>
    );
};

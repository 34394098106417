import React, { createContext, FC, ReactNode } from "react";
import { InvoicesApi } from "@inv/scripts/api";
import { TUpdateInvoicesValues } from "@inv/types";

type TValue = {};
type TActionValue = {
    updateInvoice: (arg: TUpdateInvoicesValues) => Promise<void>;
};

const initialActionValue = {
    updateInvoice: () => Promise.resolve(),
};

export const EditInvoiceContext = createContext<TValue>({});
export const EditInvoiceControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const EditInvoiceContextProvider: FC<TProps> = ({ children }) => {
    const value = {};

    const actions = {
        updateInvoice: async (arg: TUpdateInvoicesValues) => {
            await InvoicesApi.invoiceUpdate(arg);
        },
    };

    return (
        <EditInvoiceContext.Provider value={value}>
            <EditInvoiceControlContext.Provider value={actions}>{children}</EditInvoiceControlContext.Provider>
        </EditInvoiceContext.Provider>
    );
};

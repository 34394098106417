import { gql } from "@apollo/client";

export const bankCreate = gql`
    mutation bankCreate($input: BankCreateInput!) {
        bankCreate(input: $input) {
            id
        }
    }
`;

export const bankUpdate = gql`
    mutation bankUpdate($input: BankUpdateInput!) {
        bankUpdate(input: $input) {
            id
        }
    }
`;

export const bankDelete = gql`
    mutation bankDelete($input: BankDeleteInput!) {
        bankDelete(input: $input)
    }
`;

export const markFavoriteBank = gql`
    mutation markFavoriteBank($input: MarkFavoriteBankInput!) {
        markFavoriteBank(input: $input)
    }
`;

export const transactionsCreate = gql`
    mutation transactionCreate($input: [TransactionCreateInput!]!) {
        transactionsCreate(input: $input)
    }
`;

export const transactionsUpdate = gql`
    mutation transactionsUpdate($input: [TransactionUpdateInput!]!) {
        transactionsUpdate(input: $input)
    }
`;

export const transactionsDelete = gql`
    mutation transactionsDelete($input: [TransactionDeleteInput!]!) {
        transactionsDelete(input: $input)
    }
`;

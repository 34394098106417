import React, { FC, useContext, useState } from "react";
import { ConfigProvider, Dropdown } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { IBank } from "@banks/types";
import { BanksApi } from "@banks/scripts/api";
import { BanksAppControlContext } from "@banks/scripts/context";

type TProps = {
    bank: IBank;
    handleActionLoading: (arg: boolean) => void;
    children: React.ReactNode;
};

export const DropdownActions: FC<TProps> = ({ bank, handleActionLoading, children }) => {
    const [open, setOpen] = useState(false);

    const { setEditBankData } = useContext(BanksAppControlContext);

    const handleDeleteBank = async () => {
        await BanksApi.deleteBank({
            ...bank,
        });
    };

    const items = [
        {
            label: (
                <div
                    onClick={event => {
                        event.stopPropagation();
                        if (bank) {
                            setEditBankData({
                                bank,
                                isModalOpen: true,
                            });
                        }

                        setOpen(false);
                    }}
                >
                    <EditOutlined /> <FormattedMessage id="app.button.edit" />
                </div>
            ),
            key: "0",
        },
        {
            label: (
                <div
                    onClick={async () => {
                        setOpen(false);
                        handleActionLoading(true);
                        if (bank) {
                            await handleDeleteBank();
                        }
                        handleActionLoading(false);
                    }}
                >
                    <DeleteOutlined /> <FormattedMessage id="app.button.delete" />
                </div>
            ),
            key: "1",
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Dropdown: {
                        zIndexPopup: 10,
                    },
                },
            }}
        >
            <Dropdown
                menu={{ items }}
                trigger={["click"]}
                destroyPopupOnHide={true}
                open={open}
                onOpenChange={() => {
                    setOpen(!open);
                }}
                overlayStyle={{ minWidth: 150 }}
            >
                {children}
            </Dropdown>
        </ConfigProvider>
    );
};

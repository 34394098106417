import { GridOptions } from "ag-grid-community";
import { ITransaction } from "@banks/types";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

export const gridOptions: GridOptions<ITransaction> = {
    ...baseOptions,
    suppressCellFocus: true,
    rowSelection: {
        mode: "multiRow",
        selectAll: "currentPage",
        headerCheckbox: true,
    },
};

import React, { useContext } from "react";
import { Button, Drawer, Dropdown, Input, Menu, Space } from "antd";
import {
    CheckCircleOutlined,
    DownOutlined,
    PlusOutlined,
    SearchOutlined,
    SettingOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";
import { CompanyContext } from "scripts/context/CompanyContext";
import { UserCompaniesContext, UserContext, UserControlContext } from "scripts/context/UserProvider";
import { CompanyStepper } from "@app/views/auth/CompanyStepper";

const SEARCH_KEY = "search";
export const CompanyDropdown: React.FC = () => {
    const user = useContext(UserContext);
    const { companyGQL, isLoaded } = useContext(CompanyContext);
    const companies = useContext(UserCompaniesContext);
    const { onSelectCompany } = useContext(UserControlContext);
    const [term, setTerm] = React.useState("");
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const handleMenuClick: React.ComponentProps<typeof Menu>["onClick"] = e => {
        if (e.key === AppRoutes.manageCompanies) {
            return navigate(AppRoutes.manageCompanies);
        }
        if (e.key === AppRoutes.admin) {
            return navigate(AppRoutes.admin);
        }
        if (!e.key || e.key === SEARCH_KEY) {
            return;
        }
        navigate("/");
        onSelectCompany(e.key.toString());
    };

    const menuItems: React.ComponentProps<typeof Menu>["items"] = React.useMemo(() => {
        const menuCompanies = [...companies];
        menuCompanies.sort((a, b) => a.name.localeCompare(b.name));
        const items = [
            {
                key: SEARCH_KEY,
                label: (
                    <Space
                        onClick={e => e.stopPropagation()}
                        onBlur={e => e.stopPropagation()}
                        onKeyDown={e => {
                            e.stopPropagation();
                            if (e.key === "Escape") {
                                setTerm("");
                            }
                        }}
                    >
                        <Input
                            onChange={e => setTerm(e.target.value)}
                            value={term}
                            allowClear
                            prefix={<SearchOutlined />}
                        />
                    </Space>
                ),
            },
            ...menuCompanies
                .filter(c => !term.length || c.name.toLowerCase().includes(term))
                .map(c => {
                    const isActive = c.id === companyGQL?.id;
                    return {
                        key: c.id,
                        label: (
                            <Space>
                                {c.name} <CheckCircleOutlined style={{ opacity: isActive ? 1 : 0 }} />
                            </Space>
                        ),
                    };
                }),
            { type: "divider" },
            {
                key: AppRoutes.manageCompanies,
                label: (
                    <Space onClick={() => navigate(AppRoutes.manageCompanies)}>
                        <SettingOutlined />
                        <FormattedMessage id="app.titles.settings.manage" />
                    </Space>
                ),
            },
        ] as React.ComponentProps<typeof Menu>["items"];
        if (user.isAdmin) {
            items.push({
                key: AppRoutes.admin,
                label: (
                    <Space onClick={() => navigate(AppRoutes.admin)}>
                        <WarningOutlined />
                        Admin
                    </Space>
                ),
            });
        }
        return items;
    }, [companies, term, user.isAdmin, companyGQL?.id]);

    if (!user.isUserDataLoaded) {
        return null;
    }
    if (isLoaded && !companyGQL) {
        return (
            <>
                <Button
                    type={"primary"}
                    icon={<PlusOutlined />}
                    onClick={() => setIsDrawerOpen(true)}
                    style={{ marginRight: 5 }}
                >
                    <FormattedMessage id={"app.titles.company"} />
                </Button>
                <Drawer open={isDrawerOpen} width={1200} onClose={() => setIsDrawerOpen(false)}>
                    <CompanyStepper />
                </Drawer>
            </>
        );
    }
    if (!companyGQL) {
        return null;
    }

    return (
        <Dropdown
            menu={{
                onBlur: () => setTerm(""),
                onClick: handleMenuClick,
                selectedKeys: [location.pathname || ""],
                className: "Menu--MaxHeight",
                items: menuItems,
            }}
            trigger={["click"]}
        >
            <Button className="Header--Button Header--Button__margin" loading={!isLoaded}>
                {companyGQL?.name || "..."} <DownOutlined />
            </Button>
        </Dropdown>
    );
};

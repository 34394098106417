import React, { Dispatch, FC, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Button, Flex, Spin } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { InvoiceFormInputTranslate } from "@inv/types";

interface IProps {
    success: boolean;
    isLoading: boolean;
    validateSubmit: boolean;
    setIsClose: Dispatch<SetStateAction<boolean>>;
    handleCancel: () => void;
}

export const ControlBlock: FC<IProps> = ({ success, isLoading, handleCancel, setIsClose, validateSubmit }) => {
    const intl = useIntl();

    return (
        <Flex justify={"end"} gap={10} style={{ width: "100%", padding: 10 }}>
            {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
            {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <Button onClick={handleCancel}>{intl.formatMessage({ id: InvoiceFormInputTranslate.CANCEL })}</Button>
            <Button type="primary" htmlType="submit" disabled={!validateSubmit}>
                {intl.formatMessage({ id: InvoiceFormInputTranslate.SAVE })}
            </Button>
            <Button type="primary" htmlType="submit" disabled={!validateSubmit} onClick={() => setIsClose(true)}>
                {intl.formatMessage({ id: InvoiceFormInputTranslate.CONF_CLOSE })}
            </Button>
        </Flex>
    );
};

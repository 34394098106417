import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Select, Space } from "antd";
import dayjs from "dayjs";
import React, { type FC, useContext } from "react";
import { useIntl } from "react-intl";

import { DmsAppContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

export const MonthSelectBtn: FC = () => {
    const { activeType, selectedRowKeys } = useContext(DmsAppContext);
    const { userConfig, setFilterConfig } = useContext(DmsUserSettingsContext);
    const typeConfig = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);

    const intl = useIntl();

    const monthSet = [
        { value: -1, label: intl.formatMessage({ id: "app.components.all" }) },
        { value: 0, label: dayjs().month(0).format("MMMM") },
        { value: 1, label: dayjs().month(1).format("MMMM") },
        { value: 2, label: dayjs().month(2).format("MMMM") },
        { value: 3, label: dayjs().month(3).format("MMMM") },
        { value: 4, label: dayjs().month(4).format("MMMM") },
        { value: 5, label: dayjs().month(5).format("MMMM") },
        { value: 6, label: dayjs().month(6).format("MMMM") },
        { value: 7, label: dayjs().month(7).format("MMMM") },
        { value: 8, label: dayjs().month(8).format("MMMM") },
        { value: 9, label: dayjs().month(9).format("MMMM") },
        { value: 10, label: dayjs().month(10).format("MMMM") },
        { value: 11, label: dayjs().month(11).format("MMMM") },
    ];

    const handleChangeMonth = (value: number): void => {
        setFilterConfig(activeType, { month: value });
    };

    const handleBtnChangeMonth = (arg: number) => {
        let count;

        if (typeConfig !== undefined) {
            count = typeConfig.month || typeConfig.month === 0 ? typeConfig.month : -1;
        } else {
            count = -1;
        }

        if (count === undefined) {
            return;
        }

        const updateCount = count + arg < 11 ? (count + arg < -1 ? -1 : count + arg) : 11;

        setFilterConfig(
            activeType,

            {
                month: updateCount,
            }
        );
    };

    const year = typeConfig?.year ? typeConfig.year : "all";
    const isDisabled = selectedRowKeys.length > 0 || year === "all" || year === "empty";

    return (
        <Space.Compact>
            <Select
                value={typeConfig?.month ?? -1}
                onChange={handleChangeMonth}
                options={monthSet}
                style={{ minWidth: 115 }}
                disabled={isDisabled}
            />

            <Button
                icon={<ArrowLeftOutlined />}
                style={{ width: 32, padding: 0 }}
                onClick={() => handleBtnChangeMonth(-1)}
                disabled={isDisabled}
            />
            <Button
                icon={<ArrowRightOutlined />}
                style={{ width: 32, padding: 0 }}
                onClick={() => handleBtnChangeMonth(1)}
                disabled={isDisabled}
            />
        </Space.Compact>
    );
};

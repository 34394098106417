import React, { FC, useEffect, useRef, useState } from "react";
import { Col, Form, type InputProps, Row, Typography } from "antd";
import { BaseInput } from "../../../../appearance/components/shared/form/baseComponents";
import { ICountriesOption } from "../../../../appearance/views/productContacts/components/CountriesCombobox";
import { Contacts } from "@binale-tech/shared";
import { validateInput } from "../../../../scripts/infrastructure/helpers/validateVatInput";
import cn from "classnames";

import styles from "./documetInputs.module.scss";

interface IProps extends Omit<InputProps, "onChange" | "value"> {
    onChange?: (val: string) => void;
    value?: string;
    options: ICountriesOption[];
    disabled?: boolean;
}
export const UStIdNrInput: FC<IProps> = ({ options, value, onChange, disabled, ...restProps }) => {
    const form = Form.useFormInstance();
    const landCode = form.getFieldValue("landCode");
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState<string>("");
    const [focused, setFocused] = useState(false);
    const prevValue = useRef(landCode);

    const maxLength = landCode ? Contacts.ContactConstants.CountriesUstIdLength[landCode] : 10;

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleInputChange = (val: string) => {
        setInputValue(val);
        onChange?.(val);
        const isValid = validateInput(landCode, val);

        if (isValid) {
            setError("");
            form.setFields([
                {
                    name: "UStIdNr",
                    errors: [],
                },
            ]);
        } else {
            setError("Incorrect data format");
            form.setFields([
                {
                    name: "UStIdNr",
                    errors: ["Incorrect data format"],
                },
            ]);
        }
    };

    useEffect(() => {
        if (prevValue.current !== landCode) {
            setInputValue("");
            onChange?.("");
        }
    }, [landCode]);

    return (
        <Row align="middle" gutter={10}>
            <Col lg={10} xl={11} xxl={12}>
                <BaseInput
                    {...restProps}
                    id="vatNbId"
                    maxLength={maxLength}
                    value={inputValue}
                    status={error ? "error" : undefined}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <div
                    className={cn({
                        [styles.charCounter]: true,
                        [styles.show]: focused,
                    })}
                >
                    {value?.length} / {maxLength}
                </div>
            </Col>
            <Col lg={14} xl={13} xxl={12}>
                <Typography style={{ whiteSpace: "nowrap" }}>
                    {options.find(option => option.value === landCode)?.name || ""}
                </Typography>
            </Col>
        </Row>
    );
};

import React, { useEffect, useState } from "react";
import { Base } from "@binale-tech/shared";
import { BruttoInput, FieldLabel, FieldLabelProps } from "@binale-tech/ui-components";
import { EditOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import { CurrencyPopover } from "./CurrencyPopover";

import "./BruttoInputBlock.css";

interface BruttoInputBlockProps extends Omit<React.ComponentProps<typeof BruttoInput>, "onKeyDown"> {
    originalAmount?: number;
    currency?: Base.CurrencyConfig;
    withCurrencyControl?: boolean;
    focusNextElement?: () => void;
    labelProps?: FieldLabelProps;
    recordDate?: Date;
}

export const BruttoInputBlock = React.forwardRef<any, BruttoInputBlockProps>(function BruttoInputBlock2(
    { originalAmount, currency, withCurrencyControl, focusNextElement, labelProps, recordDate, ...rest },
    ref
) {
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
        if (!showPopover) {
            focusNextElement && focusNextElement();
        }
        // do not remove disabling - either bug with focusing field
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopover]);

    const hasCurrency = Boolean(currency);
    const hasMasterCurrency = Boolean(withCurrencyControl);
    const displayAddon = hasCurrency || hasMasterCurrency;

    const currencyPopover = (
        <CurrencyPopover
            recordDate={recordDate}
            currencyValue={{
                amount: rest.value,
                // amount: this.state.brutto,
                currency,
                originalAmount,
            }}
            showPopover={showPopover}
            onVisibleChange={value => {
                setShowPopover(value);
            }}
            allowConfigEdit={hasMasterCurrency}
            onChange={value => {
                rest.onChange({
                    ...value,
                    amount: value.amount,
                });
            }}
        />
    );
    const getAddonBefore = () => {
        if (!displayAddon) {
            return null;
        }
        if (!rest.disabled) {
            return currencyPopover;
        }
        return hasCurrency ? <EditOutlined /> : <small>EUR</small>;
    };

    return (
        <FieldLabel
            label={<FormattedMessage id="app.fields.betrag" />}
            labelAddon={hasCurrency ? "EUR" : undefined}
            {...labelProps}
            style={{ minWidth: 100, width: 136, ...labelProps?.style }}
        >
            <BruttoInput
                ref={ref}
                type="text"
                {...rest}
                disabled={rest.disabled || hasCurrency}
                className={rest.disabled ? undefined : "BruttoInputBlock--activeAddon"}
                addonBefore={getAddonBefore()}
                onKeyDown={(value, e) => {
                    if (e.key === " ") {
                        setShowPopover(true);
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                }}
            />
        </FieldLabel>
    );
});

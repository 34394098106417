import React, { FC, useContext } from "react";
import { InvoiceFormContext } from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";
import { InvoiceTableContext } from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { themeConfig } from "@inv/theme";
import { FormattedMessage, useIntl } from "react-intl";
import { InvoiceFormInputTranslate } from "@inv/types";
import { Flex } from "antd";
import { TotalItem } from "@inv/components/InvoiceTableBlock/components/InvoiceTotal/TotalItem";

interface IProps {
    currencyCode: string;
}

export const InvoiceTotal: FC<IProps> = ({ currencyCode }) => {
    const intl = useIntl();
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const { totalState } = useContext(InvoiceTableContext);

    return (
        <Flex
            vertical
            align="start"
            style={{
                height: "100%",
                maxWidth: 360,
                padding: 2,
                width: "100%",
                border: themeConfig.border,
                borderRadius: 8,
            }}
        >
            {invoiceTotalView && (
                <>
                    <TotalItem
                        label={<FormattedMessage id={InvoiceFormInputTranslate.SUB_TOTAL} />}
                        value={totalState.subtotal}
                        currencyCode={currencyCode}
                        isBold={false}
                    />
                    {totalState.tax0 && (
                        <TotalItem
                            label={`${intl.formatMessage({ id: InvoiceFormInputTranslate.VAT })} 0%`}
                            value={totalState.tax0}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                    {totalState.tax7 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 7%`}
                            value={totalState.tax7}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                    {totalState.tax19 && (
                        <TotalItem
                            label={`zzgl. Umsatzsteuer 19%`}
                            value={totalState.tax19}
                            currencyCode={currencyCode}
                            isBold={false}
                        />
                    )}
                </>
            )}

            <TotalItem
                label={<FormattedMessage id={InvoiceFormInputTranslate.TOTAL_AMOUNT} />}
                value={totalState.total}
                currencyCode={currencyCode}
                isBold={true}
            />
        </Flex>
    );
};

import { GridApi } from "ag-grid-community";
import { TInvoicesListData } from "@inv/types";
import { decimalFormatter } from "@dms/scripts/helpers";

export const calculateFormattedNum = (num: number) => decimalFormatter(num * 100) ?? "0,00";

export const stringToFloat = (value: string) => +parseFloat(value.replace(",", ".")).toFixed(2);

export const invoicesListTotal = (api: GridApi<TInvoicesListData>) => {
    const rowData: TInvoicesListData[] = api.getRenderedNodes().map(node => node.data);
    return rowData.reduce(
        (totals, item) => {
            const netto = item.originalAmount * item.currencyRate;
            const taxRate = item.generalTax * item.currencyRate;

            totals.vat += taxRate;
            totals.amountNetto += netto;
            totals.amountBrutto += netto + taxRate;

            return totals;
        },
        {
            vat: 0,
            amountNetto: 0,
            amountBrutto: 0,
        }
    );
};

export const transformServicePeriod = (period: string): string => {
    const parsePeriod = JSON.parse(period);
    const firstDate = parsePeriod[0];
    const endDate = parsePeriod[1];
    if (firstDate === endDate) {
        return firstDate;
    }
    return `${firstDate} - ${endDate}`;
};

export const searchInAllFields = (doc: TInvoicesListData, term: string): boolean => {
    term = term.toLowerCase();

    return Object.values(doc).some(value => {
        const stringValue = String(value).toLowerCase();
        return stringValue.includes(term);
    });
};

import React, { FC, useContext, useMemo } from "react";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";

import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { RecordFormStateContext } from "../context/RecordFormState";
import { CurrencyInputBlock } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";
import { BruttoInputBlock } from "../../shared/form/FormBlocks";
import { focusNextFieldFromRef } from "../../../../scripts/infrastructure/helpers/focus";
import { useProgramSettings } from "../hooks/useProgramSettings";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerItemBrutto } from "../hooks/handlers/useFormHandlerItemBrutto";

export const FormItemBrutto: FC = () => {
    const { refsHtml } = useContext(RecordFormPropsContext);
    const isDisabled = useFormIsRefDisabled();
    const programSettings = useProgramSettings();
    const { isNettoMode, editableRecordItem, editableRecord, itemValidationStates } =
        useContext(RecordFormStateContext);
    const onBruttoItemChange = useFormHandlerItemBrutto();
    const checkAutoBinding = useCheckAutoPaymentConnection();

    const validation = itemValidationStates.get(refsHtml.REF_iBRUTTO);
    const { itemBrutto, itemOriginalAmount } = editableRecordItem;
    const { recordCurrency } = editableRecord;
    const withCurrencyControl = Boolean(recordCurrency) || programSettings.useCurrency;

    const label = useMemo(() => {
        if (isNettoMode) {
            return (
                <>
                    <FormattedMessage id="app.fields.partialAmount" /> <FormattedMessage id="app.fields.netto" />
                </>
            );
        }
        return <FormattedMessage id="app.fields.partialAmount" />;
    }, [isNettoMode]);
    const onBruttoChange: React.ComponentProps<typeof BruttoInputBlock>["onChange"] = ({ amount, originalAmount }) => {
        onBruttoItemChange(amount, originalAmount);
    };
    if (withCurrencyControl) {
        return (
            <CurrencyInputBlock
                ref={refsHtml.REF_iBRUTTO}
                currencyValue={{
                    amount: itemBrutto,
                    originalAmount: itemOriginalAmount,
                    currency: recordCurrency,
                }}
                label={label}
                onChange={onBruttoChange}
            />
        );
    }
    return (
        <BruttoInputBlock
            ref={refsHtml.REF_iBRUTTO}
            value={itemBrutto}
            onChange={onBruttoChange}
            currency={editableRecord.recordCurrency}
            originalAmount={itemOriginalAmount}
            disabled={isDisabled(refsHtml.REF_iBRUTTO)}
            labelProps={{
                label,
                fieldError: validation ? { type: "error", message: " " } : undefined,
            }}
            onBlur={checkAutoBinding}
            focusNextElement={() => focusNextFieldFromRef(refsHtml.REF_iBRUTTO)}
        />
    );
};

import { DmsDefaultSubType, DmsType } from "@dms/types";
import { Divider, Form, RefSelectProps, Typography } from "antd";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { getSubTypeOptions } from "@dms/scripts/helpers";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { TypeInput } from "../DocumentInputs/TypeInput";
import { TOptions } from "../types";
import style from "./DocumentFormField.module.scss";
import { DmsTypeOptions } from "@dms/configs/constants";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { FormItemCheckbox } from "@dms/components/DocumentForm/DocumentInputs/FormItemCheckbox";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

const { Text } = Typography;

type TProps = {
    options: TOptions;
};

const SingleDocumentTypeSelectField = forwardRef<RefSelectProps, TProps>((props, ref) => {
    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.dms.typeOfDocuments" />
                </Text>
            }
            key={"type"}
            name={"type"}
        >
            <TypeInput ref={ref} options={props.options} />
        </FieldLabel>
    );
});

const GroupDocumentTypeSelectField = forwardRef<RefSelectProps, TProps>((props, ref) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField?.includes("type") ? "Diverse" : undefined;

    return (
        <div className={"BinaleForm__Item DMSForm__Item"}>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"documentTypeCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"documentType"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        <FormattedMessage id="app.dms.typeOfDocuments" />
                    </Text>
                }
                key={"type"}
                name={"type"}
            >
                <TypeInput ref={ref} options={props.options} disabled={isDisabled} placeholder={placeholder} />
            </FieldLabel>
        </div>
    );
});

export const DocumentTypeSelectField = forwardRef<RefSelectProps>((props, ref) => {
    const { isGroupEditor } = useContext(FormWrapperContext);
    const { documentTypes } = useContext(DmsDataContext);

    const options: TOptions = DmsTypeOptions.map(({ value, isGroup, translationKey, isDivider, defaultSubtypes }) => {
        if (isDivider) {
            return {
                label: <Divider className={style.selectorDivider} />,
                disabled: true,
                className: `${style.selectorOption} ${style.selectorOptionDisabled}`,
            };
        }
        if (value && isGroup) {
            if (defaultSubtypes) {
                const defaultSubtypesArr = defaultSubtypes
                    .filter(el => el.value === DmsDefaultSubType.no_subTypes)
                    .map(defaultSubtype => {
                        return {
                            value,
                            label: defaultSubtype.translationKey ? (
                                <>
                                    <FormattedMessage id={defaultSubtype.translationKey} />
                                    {" / "}
                                    <FormattedMessage id={translationKey} />
                                </>
                            ) : (
                                `${value} / ${defaultSubtype.value}`
                            ),
                        };
                    });

                return {
                    label: <FormattedMessage id={translationKey} />,
                    options: [...defaultSubtypesArr, ...getSubTypeOptions(documentTypes, value)],
                };
            }

            return {
                label: <FormattedMessage id={translationKey} />,
                options: getSubTypeOptions(documentTypes, value),
            };
        }
        return {
            label: <FormattedMessage id={translationKey} />,
            value,
            disabled: value === DmsType.new_documents,
        };
    });

    return (
        <>
            {!isGroupEditor ? (
                <SingleDocumentTypeSelectField ref={ref} options={options} />
            ) : (
                <GroupDocumentTypeSelectField ref={ref} options={options} />
            )}
        </>
    );
});

import React, { useState } from "react";
import { Datev, GQL } from "@binale-tech/shared";

import CompanyQueriesGraphql from "./companyQueries.graphql";
import {
    Badge,
    Button,
    Collapse,
    ColorPicker,
    Divider,
    Flex,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Space,
    Spin,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { LegalFormSelect } from "appearance/components/shared/form/baseComponents";
import { PaymentUtils } from "scripts/models/utils/PaymentUtils";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { YearPeriodContext } from "scripts/context/CompanyContext";
import { UserContext, UserControlContext } from "scripts/context/UserProvider";
import { useGQLRetriever } from "scripts/graphql/gqlRetriever";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";
import { validation } from "scripts/infrastructure/helpers/validation";

import "./CompanyFormModal.css";
import { DatevContext } from "scripts/context/DatevContext";
import { CheckCircleTwoTone, ReloadOutlined, SmileTwoTone } from "@ant-design/icons";
import { datevTokenRefresh, datevTokenRevoke, redirectToDatevOAuth } from "../../../scripts/infrastructure/oauth/oauth";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { Link } from "react-router-dom";
import { DatevButton } from "../oauth/DatevButton";
import { useApolloClient } from "@apollo/client";
import { DatevDescriptions } from "../oauth/DatevDescriptions";
import { DatevClients } from "../oauth/DatevClients";

type Props = {
    onHide?: () => void;
    onComplete?: (v: GQL.ICompany) => void;
    companyId: string;
    isStepperMode?: boolean;
};

const DEFAULT_COLOR = "#F8F8F8";
const colors = [
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#5300EB",
    "#EB9694",
    "#FAD0C3",
    "#FEF3BD",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "#E7E7E7",
    DEFAULT_COLOR,
].reverse();

interface ColorProps {
    value?: string;
    onChange?: (value: string) => void;
}

type FormValues = Pick<
    GQL.ICompany,
    "name" | "color" | "favouritePaymentType" | "datevNrConsultant" | "datevNrCompany" | "type"
> &
    Required<Pick<GQL.ICompany, "legalName" | "legalForm">>;

const FormColorPicker: React.FC<ColorProps> = ({ value, onChange }) => {
    return (
        <ColorPicker
            value={value}
            onChange={(color, hex) => onChange(hex)}
            presets={[{ colors, label: "" }]}
            format={"hex"}
        />
    );
};

export const CompanyFormModal: React.FC<Props> = ({ companyId, onHide, onComplete, isStepperMode }) => {
    const intl = useIntl();
    const { fireUser } = React.useContext(UserContext);
    const { year } = React.useContext(YearPeriodContext);
    const { fetchUserData } = React.useContext(UserControlContext);
    const { isLoading: isDatevLoading, datevUserInfo } = React.useContext(DatevContext);
    const [form] = Form.useForm<FormValues>();
    const formDatevNrConsultant = Form.useWatch("datevNrConsultant", form);
    const formDatevNrCompany = Form.useWatch("datevNrCompany", form);
    const Mutator = useGqlMutator();
    const client = useApolloClient();
    const retriever = useGQLRetriever<"company">();
    const [loading, setLoading] = React.useState(false);
    const [company, setCompany] = React.useState<GQL.ICompany | null>();
    const [datevCompanyUserInfo, setDatevCompanyUserInfo] = useState<GQL.IDatevUserInfo>();
    const hasAccounting = Boolean(company?.id) && ProductAccessUtils.hasCompanyAccounting(company);

    const isDatevConnectionAvailable = (datevUserInfo?.clients || []).some(
        c => c.clientNumber === formDatevNrCompany && c.consultantNumber === formDatevNrConsultant && company
    );
    const isDatevConnected = company?.datevOAuth && Datev.getRefreshTokenValidity(company?.datevOAuth) > 0;

    const getCompanyData = React.useCallback(() => {
        setLoading(true);
        retriever
            .query({ id: companyId, query: CompanyQueriesGraphql.queryCompanyForEdit })
            .then(data => {
                setCompany(data?.company);
                return data?.company;
            })
            .then(companyData => {
                if (!companyData?.datevOAuth) {
                    return Promise.resolve(null);
                }
                return client.query({
                    query: CompanyQueriesGraphql.queryDatevCompanyUserInfo,
                    variables: { companyId },
                    fetchPolicy: "network-only",
                });
            })
            .then(res => setDatevCompanyUserInfo(res?.data?.datevCompanyUserInfo))
            .finally(() => setLoading(false));
    }, [client, companyId, retriever]);

    React.useEffect(() => {
        if (companyId) {
            getCompanyData();
        } else {
            setCompany(null);
            setDatevCompanyUserInfo(null);
        }
    }, [getCompanyData, companyId]);

    React.useEffect(() => {
        form.setFieldsValue(company ? { ...company } : {});
    }, [form, company]);

    const handleSubmit = async (values: FormValues) => {
        setLoading(true);

        const optionalsInputs: Pick<GQL.ICompany, "datevNrCompany" | "datevNrConsultant"> = {};
        const { datevNrCompany, datevNrConsultant, ...restValues } = values;
        if (datevNrCompany) {
            optionalsInputs.datevNrCompany = Number(datevNrCompany);
        }
        if (datevNrConsultant) {
            optionalsInputs.datevNrConsultant = Number(datevNrConsultant);
        }

        let gqlCompany: GQL.ICompany;
        if (!company) {
            await Mutator.mutate<"companyCreate", GQL.ICompanyCreateInput>({
                mutation: CompanyQueriesGraphql.mutationCreateCompany,
                input: {
                    ...restValues,
                    ...optionalsInputs,
                },
            }).then(res => (gqlCompany = res.companyCreate));
        } else {
            const { name, favouritePaymentType, color, legalName, legalForm, type } = values;

            await Mutator.mutate<"companyUpdate", GQL.ICompanyUpdateInput>({
                mutation: CompanyQueriesGraphql.mutationUpdateCompany,
                input: {
                    name,
                    ...optionalsInputs,
                    favouritePaymentType,
                    id: company.id,
                    legalName,
                    legalForm,
                    color,
                    type,
                },
            }).then(res => (gqlCompany = res.companyUpdate));
        }
        await fetchUserData();
        setLoading(false);
        form.resetFields();
        onHide();
        onComplete && onComplete(gqlCompany);
    };

    const selectOptions = React.useMemo(() => {
        if (!company) {
            return [];
        }
        return PaymentUtils.getCompanyPaymentMethods(company, year);
    }, [company, year]);

    const handleDatevAuth = async () => {
        await redirectToDatevOAuth(client, fireUser.uid, company);
    };
    const handleRefresh = async () => {
        client
            .mutate<Pick<GQL.IMutation, "datevTokenRefresh">, GQL.IMutationDatevTokenRefreshArgs>({
                mutation: datevTokenRefresh,
                variables: { input: { companyId, forceRefresh: true } },
            })
            .then(() => getCompanyData())
            .catch(e => message.error(e.message));
    };
    const handleDatevTokenRevocation = async () => {
        client
            .mutate<Pick<GQL.IMutation, "datevTokenRevoke">, GQL.IMutationDatevTokenRevokeArgs>({
                mutation: datevTokenRevoke,
                variables: { companyId },
            })
            .then(res => getCompanyData())
            .catch(e => message.error(e.message));
    };

    return (
        <Modal
            className="CompanyForm-Modal"
            maskClosable
            destroyOnClose
            width={700}
            style={{ top: 24 }}
            title={
                <span>
                    <FormattedMessage id="app.titles.company" /> {company?.name}
                </span>
            }
            open
            onOk={form.submit}
            okText={intl.formatMessage({ id: "app.button.save" })}
            onCancel={onHide}
            cancelText={intl.formatMessage({ id: "app.button.cancel" })}
        >
            <Spin spinning={loading}>
                <div>
                    <section>
                        <Form onFinish={handleSubmit} layout="vertical" form={form}>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.internalDesignation" />}
                                name="name"
                                rules={[validation.required(intl), validation.max(intl, 100)]}
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.companyName" />}
                                name="legalName"
                                rules={[validation.required(intl), validation.max(intl, 60)]}
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                            <Flex justify={"space-between"}>
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.legalForm" />}
                                    name="legalForm"
                                    rules={[validation.required(intl), validation.max(intl, 60)]}
                                >
                                    <LegalFormSelect />
                                </Form.Item>
                                {selectOptions.length > 0 && (
                                    <Form.Item
                                        label={<FormattedMessage id="app.fields.favouritePaymentType" />}
                                        name="favouritePaymentType"
                                    >
                                        <Select
                                            notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
                                            options={selectOptions.map(option => {
                                                return {
                                                    value: option.uuid || option.value,
                                                    label: PaymentUtils.getPaymentTypeStringValue(
                                                        option,
                                                        company,
                                                        year
                                                    ),
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.type" />}
                                    name="type"
                                    style={{ minWidth: 100 }}
                                >
                                    <Select
                                        notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
                                        options={[
                                            {
                                                label: "Gastronomie",
                                                options: [
                                                    {
                                                        label: "Restaurant",
                                                        value: "restaurant",
                                                    },
                                                    {
                                                        label: "Café",
                                                        value: "cafe",
                                                    },
                                                    {
                                                        label: "Bäckerei",
                                                        value: "bakery",
                                                    },
                                                ],
                                            },
                                            {
                                                label: "Handel",
                                                options: [
                                                    {
                                                        label: "Einzelhandel",
                                                        value: "retail",
                                                    },
                                                    {
                                                        label: "Großhandel",
                                                        value: "wholesale",
                                                    },
                                                ],
                                            },
                                            {
                                                label: "Dienstleistungen",
                                                options: [
                                                    {
                                                        label: "Beratungsleistungen",
                                                        value: "consulting",
                                                    },
                                                    {
                                                        label: "Sonstige Dienstleistungen",
                                                        value: "services_other",
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id="app.fields.color" />} name="color">
                                    <FormColorPicker />
                                </Form.Item>
                            </Flex>

                            {hasAccounting && (
                                <Form.Item label="Accounting years">
                                    <pre>{company.accountingYears.toString()}</pre>
                                </Form.Item>
                            )}

                            <Divider orientation="left">DATEV</Divider>
                            <Space direction="horizontal">
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.datevNrConsultant" />}
                                    name="datevNrConsultant"
                                    rules={[validation.onlyNumber, validation.inRange(1001, 9999999)]}
                                >
                                    <Input maxLength={7} disabled={isDatevConnected} />
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.datevNrCompany" />}
                                    name="datevNrCompany"
                                    rules={[validation.onlyNumber, validation.inRange(1, 99999)]}
                                >
                                    <Input maxLength={5} disabled={isDatevConnected} />
                                </Form.Item>
                                {!isDatevConnected && !isStepperMode && (
                                    <Form.Item
                                        label={
                                            datevUserInfo ? (
                                                <>&nbsp;</>
                                            ) : (
                                                <div>
                                                    <Link
                                                        to={AppRoutes.profile + "?tab=datev"}
                                                        style={{ color: "green", textDecoration: "underline" }}
                                                    >
                                                        DATEV Login
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    >
                                        <Badge
                                            count={
                                                isDatevConnectionAvailable ? <SmileTwoTone twoToneColor="#96d551" /> : 0
                                            }
                                        >
                                            <DatevButton
                                                disabled={
                                                    !formDatevNrConsultant ||
                                                    !formDatevNrCompany ||
                                                    !company ||
                                                    !datevUserInfo?.clients
                                                }
                                                loading={isDatevLoading}
                                                onClick={handleDatevAuth}
                                            >
                                                DATEV verbinden
                                            </DatevButton>
                                        </Badge>
                                    </Form.Item>
                                )}
                            </Space>
                            {isDatevConnected && (
                                <Collapse
                                    size="small"
                                    items={[
                                        {
                                            key: "1",
                                            label: (
                                                <span>
                                                    DATEV ist verbunden <CheckCircleTwoTone twoToneColor={"#96d551"} />
                                                </span>
                                            ),
                                            children: (
                                                <div>
                                                    <DatevDescriptions
                                                        layout="vertical"
                                                        token={company.datevOAuth}
                                                        datevUserInfo={datevCompanyUserInfo}
                                                    />
                                                    <DatevClients datevUserInfo={datevCompanyUserInfo} />
                                                    <Flex justify={"space-between"} style={{ marginTop: 8 }}>
                                                        <Button
                                                            disabled={
                                                                Datev.getRefreshTokenValidity(company?.datevOAuth) === 0
                                                            }
                                                            onClick={handleRefresh}
                                                            icon={<ReloadOutlined />}
                                                        >
                                                            Refresh Token
                                                        </Button>
                                                        <Popconfirm
                                                            onConfirm={handleDatevTokenRevocation}
                                                            title={<FormattedMessage id="app.confirmation.header" />}
                                                            okText={<FormattedMessage id="app.button.confirm" />}
                                                            cancelText={<FormattedMessage id="app.button.cancel" />}
                                                        >
                                                            <Button type="dashed" danger>
                                                                DATEV Verbindung löschen
                                                            </Button>
                                                        </Popconfirm>
                                                    </Flex>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            )}
                        </Form>
                    </section>
                </div>
            </Spin>
        </Modal>
    );
};

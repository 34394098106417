import { TBankData } from "@banks/types";
import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { BanksContext } from "../../../../scripts/context/BanksContext";

type TValue = {
    banks: TBankData;
};

type TActionValue = {};

const initialValue: TValue = {
    banks: [],
};

export const BankListContext = createContext<TValue>(initialValue);
export const BankListControlContext = createContext<TActionValue>({});

type TProps = {
    children?: ReactNode;
};

export const BanksLitContextProvider: FC<TProps> = ({ children }) => {
    const [bankList, setBankList] = useState<TBankData>();

    const { banksData } = useContext(BanksContext);

    useEffect(() => {
        const isFavoriteArr: TBankData = [];
        const isNotFavoriteArr: TBankData = [];

        Object.keys(banksData).forEach(bank => {
            if (banksData[bank].isFavorite) {
                isFavoriteArr.push(banksData[bank]);
            } else {
                isNotFavoriteArr.push(banksData[bank]);
            }
        });

        isFavoriteArr.sort((a, b) => a?.bankName?.localeCompare(b?.bankName));
        isNotFavoriteArr.sort((a, b) => a?.bankName?.localeCompare(b?.bankName));

        setBankList([...isFavoriteArr, ...isNotFavoriteArr]);
    }, [banksData]);

    const value = useMemo(() => {
        return {
            banks: bankList,
        };
    }, [bankList]);

    const actions = {} as TActionValue;

    return (
        <BankListContext.Provider value={value}>
            <BankListControlContext.Provider value={actions}>{children}</BankListControlContext.Provider>
        </BankListContext.Provider>
    );
};

import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IBank, ITransactionCreateInput, TParseTransaction } from "@banks/types";
import dayjs, { extend as dayjsExtend } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { BanksApi } from "@banks/scripts/api";
import { parseNumber } from "@dms/scripts/helpers";
import { BanksContext } from "../../../../scripts/context/BanksContext";
import { getDateFormat, getParsedDateRange } from "@banks/scripts/helpers";
import { PreviewParseDataContext } from "@banks/modules/ParseTransactionModule/context/PreviewParseDataProvider";

type TValue = {
    filteredRows: TParseTransaction[];
    selectDateRange: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs };
    selectedRows: TParseTransaction[];
    transactionBank: IBank;
    existingCsvLines: Set<string>;
};
type TActionValue = {
    setSelectDateRange: (arg: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }) => void;
    saveParseData: () => Promise<true | undefined>;
    selectRows: (arg: TParseTransaction[]) => void;
    saveSelectedTransactions: () => Promise<true | undefined>;
};

const initialActionValue = {
    setSelectDateRange: () => {},
    selectRows: () => {},
    saveSelectedTransactions: () => Promise.reject(),
    saveParseData: () => Promise.reject(),
};

export const ParseDataContext = createContext<TValue>({} as TValue);
export const ParseDataControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const ParseDataContextProvider: FC<TProps> = ({ children }) => {
    const { companyId, banksData, transactionsData } = useContext(BanksContext);
    const { parseBankData, reducedParseResult } = useContext(PreviewParseDataContext);

    const [selectDateRange, setSelectDateRange] = useState<{ firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }>();
    const [filteredRows, setFilteredRows] = useState<TParseTransaction[]>(reducedParseResult as TParseTransaction[]);
    const [selectedRows, setSelectedRows] = useState<TParseTransaction[]>([]);

    const transactionBank = banksData[parseBankData.id];

    useEffect(() => {
        const dateRange = getParsedDateRange(reducedParseResult as TParseTransaction[]);
        setSelectDateRange(dateRange);
    }, [reducedParseResult]);

    useEffect(() => {
        if (!reducedParseResult.length) {
            return setFilteredRows([]);
        }

        if (!selectDateRange) {
            return setFilteredRows(reducedParseResult as TParseTransaction[]);
        }

        dayjsExtend(isBetween);

        const rowArr = reducedParseResult.filter(el => {
            if (!el.bookingDate) {
                return;
            }
            const date = dayjs(el.bookingDate, getDateFormat(el.bookingDate));
            return dayjs(date).isBetween(selectDateRange.firstData, selectDateRange.lastData, "month", "[]");
        });

        setFilteredRows(rowArr as TParseTransaction[]);
    }, [reducedParseResult, selectDateRange]);

    const saveTransactions = useCallback(
        async (transactionsArr: TParseTransaction[]) => {
            const rowsData = transactionsArr.map(el => {
                const result = { ...el };
                delete result.myBankAccount;
                delete result.myBankIban;

                const data: ITransactionCreateInput = {
                    ...result,
                    amount: result?.amount ? Math.round(parseNumber(result?.amount) * 100) : null,
                    bankId: transactionBank?.id,
                    transactionType: result?.transactionType ?? "",
                    companyId,
                };

                return data;
            });

            const res = await BanksApi.transactionsCreate(rowsData as never as ITransactionCreateInput);

            setSelectedRows([]);

            return res as unknown as true;
        },
        [companyId, transactionBank?.id]
    );

    const existingCsvLines = useMemo(() => {
        return new Set<string>(Object.values(transactionsData[transactionBank.id] || {}).map(v => v.csvLine));
    }, [transactionBank.id, transactionsData]);

    const value = useMemo(() => {
        return {
            filteredRows,
            selectDateRange,
            selectedRows,
            transactionBank,
            existingCsvLines,
        };
    }, [existingCsvLines, filteredRows, selectDateRange, selectedRows, transactionBank]);

    const actions = useMemo(() => {
        return {
            setSelectDateRange: (arg: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }) => {
                setSelectDateRange(arg);
            },
            selectRows: (arg: TParseTransaction[]) => {
                setSelectedRows(arg);
            },
            saveSelectedTransactions: () => {
                if (!selectedRows.length) {
                    return;
                }
                return saveTransactions(selectedRows);
            },
            saveParseData: async () => {
                if (!existingCsvLines.size) {
                    return saveTransactions(filteredRows);
                }

                const arr = filteredRows.filter(row => !existingCsvLines.has(row.csvLine));
                return saveTransactions(arr);
            },
        };
    }, [selectedRows, saveTransactions, existingCsvLines, filteredRows]);

    return (
        <ParseDataContext.Provider value={value}>
            <ParseDataControlContext.Provider value={actions}>{children}</ParseDataControlContext.Provider>
        </ParseDataContext.Provider>
    );
};

import { blue } from "@ant-design/colors";
import { CloseOutlined, CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { IDocumentEnriched } from "@dms/types";
import { Button, ConfigProvider, Flex, message, Tooltip } from "antd";
import React, { type FC, ReactNode, useCallback, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

import styles from "./documentData.module.scss";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { useDmsAccountingConverter } from "../../../scripts/models/converters/useDmsAccountingConverter";
import { GQL } from "@binale-tech/shared";
import { BinaleError } from "../../../scripts/errors/errors";
import { DocumentsApi } from "@dms/scripts/DocumentsApi";
import { RecordDataPopover } from "@dms/components/RecordDataTable/RecordDataPopover";

interface IProps {
    documentData?: IDocumentEnriched;
    children: ReactNode;
}

export const DocumentData: FC<IProps> = ({ documentData, children }) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { documentTypes } = useContext(DmsDataContext);
    const { isGroupEditor } = useContext(FormWrapperContext);
    const { dmsRecordsData } = useContext(DmsDataContext);
    const [importing, setImporting] = useState(false);
    const [tooltipTitleId, setTooltipTitleId] = useState(<FormattedMessage id="app.dms.copyId" />);

    const recordData = documentData &&
        dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(documentData.fileUrl)) && (
            <RecordDataPopover url={documentData.fileUrl} position={"form"} />
        );

    const groupId = documentData
        ? DmsUtils.getGroupId(documentData.type, documentData.subType, documentTypes)
        : undefined;
    const { converter, actions: configActions } = useDmsAccountingConverter(
        documentData?.type as GQL.IProductKey,
        groupId
    );
    const isAccountingExportDisabled =
        documentData && DmsUtils.isAccountingExportDisabled([documentData], documentData.type, groupId);

    const toGenericRecord = useCallback(async () => {
        if (!documentData || !configActions) {
            return;
        }

        setImporting(true);

        try {
            const record = converter.convertDocumentToRecord(documentData);
            if (!record) {
                throw new BinaleError("Unable to construct record", "app.error.message.incomplete_data");
            }
            await configActions.importMany([record], true).catch(e => message.warning(e.message));
            await message.success(intl.formatMessage({ id: "app.button.done" }));
        } catch (err) {
            if (err instanceof BinaleError) {
                message.warning(intl.formatMessage({ id: err.translationKey }));
            } else {
                message.error(err.message);
            }
        } finally {
            setImporting(false);
        }
    }, [configActions, converter, document, intl]);

    const resetTooltipId = (open: boolean): void => {
        if (!open) {
            setTooltipTitleId(<FormattedMessage id="app.dms.copyId" />);
        }
    };

    const copyId = async (): Promise<void> => {
        if (documentData && !isGroupEditor) {
            await navigator.clipboard.writeText(documentData.key);
            setTooltipTitleId(<FormattedMessage id="app.dms.idCopied" />);
        }
    };

    return (
        <div>
            <Flex align="center" justify="flex-end" gap={10} className={styles.dataWrapper}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: blue[0],
                            colorBorder: blue[1],
                            colorText: blue.primary,
                        },
                    }}
                >
                    <Tooltip
                        title={isGroupEditor ? null : tooltipTitleId}
                        onOpenChange={(open: boolean) => {
                            resetTooltipId(open);
                        }}
                    >
                        <CopyOutlined className={isGroupEditor ? styles.disabledIcon : undefined} onClick={copyId} />
                    </Tooltip>

                    <Button
                        icon={<CloseOutlined />}
                        onClick={() => {
                            navigate("/documents");
                        }}
                    >
                        <span>
                            <FormattedMessage id="app.dms.closeEditor" />
                        </span>
                    </Button>
                </ConfigProvider>
                {!isGroupEditor ? (
                    <Button
                        icon={<ExportOutlined />}
                        disabled={importing || isAccountingExportDisabled}
                        onClick={toGenericRecord}
                    >
                        FIBU {recordData}
                    </Button>
                ) : null}
            </Flex>

            <div className={styles.formWrapper}>{children}</div>
        </div>
    );
};

import { WalletOutlined } from "@ant-design/icons";
import { Base, GQL } from "@binale-tech/shared";
import { Menu, type MenuProps } from "antd";
import React, { type FC, useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ALL_DOCUMENTS } from "@dms/configs/constants";
import { useMenuDocumentTypes } from "@dms/hooks/useMenuDocumentTypes";
import { DmsAppContext, DmsAppControlContext, DmsDataContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

import { SidebarMenuItemContent } from "./components/SidebarMenuItemContent/SidebarMenuItemContent";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { UserContext as UserContextGlobal } from "../../../scripts/context/UserProvider";
import { ProductAccessUtils } from "../../../scripts/models/utils/ProductAccessUtils";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { DmsType } from "@dms/types";

const backgroundColorSelectedType = "#c4e5ff";

export const hasFolderAccess = (type: string, userCtx: Base.UserInterface, companyGQL: GQL.ICompany) => {
    const hasDmsAccess = ProductAccessUtils.canRead(GQL.IProductKey.Dms, companyGQL, userCtx);
    if (!hasDmsAccess) {
        return false;
    }
    const hasDmsAdminRole = ProductAccessUtils.hasUserRole(companyGQL, userCtx, GQL.IUserRole.DmsAdmin);
    if (hasDmsAdminRole) {
        return true;
    }
    const hasDmsNewDocsRole = ProductAccessUtils.hasUserRole(companyGQL, userCtx, GQL.IUserRole.DmsNewDocuments);
    return type === DmsType.new_documents && hasDmsNewDocsRole;
};

export const SidebarMenu: FC = () => {
    const navigate = useNavigate();
    const [activeTypeChangeMode, setActiveTypeChangeMode] = useState<string>("");
    const documentTypesArray = useMenuDocumentTypes(activeTypeChangeMode);
    const mutator = useGqlMutator();

    const { activeType, isTypeChangeMode, isDraggingRightNow, selectedRowKeys, openedMenuItemKeys } =
        useContext(DmsAppContext);
    const { setActiveType, setOpenedMenuItemKeys, typeChangeModeToggle } = useContext(DmsAppControlContext);
    const { documents } = useContext(DmsDataContext);
    const { setFilterConfig } = useContext(DmsUserSettingsContext);
    const userCtx = useContext(UserContextGlobal);
    const { companyGQL } = useContext(CompanyContext);

    const allDocumentsDraftCount = useMemo(
        () => documents.filter(el => DmsUtils.getDocumentStatus(el) === GQL.IDocumentStatus.Draft).length,
        [documents]
    );

    const onOpenChange = useCallback((keys: string[]): void => {
        setOpenedMenuItemKeys(keys);
    }, []);

    const handleChangeDocumentsType = async (typeKeyPath: string[]): Promise<void> => {
        const { type, subType } = DmsUtils.getActiveTypeAndSubType(typeKeyPath);

        const updateDocuments = documents
            .filter(el => selectedRowKeys.includes(el.key))
            .map(el => ({ ...el, type, subType }));
        await DocumentsApi.changeDocumentsType(updateDocuments, mutator);
        setActiveTypeChangeMode("");
    };

    const handleMenuLink: MenuProps["onClick"] = async (data): Promise<void> => {
        setFilterConfig(activeType, {
            selectedFilter: false,
            filterState: null,
            sortState: null,
        });

        const { type } = DmsUtils.getActiveTypeAndSubType(data.keyPath);

        const isSelectTypeMode =
            isTypeChangeMode && data.key !== DmsType.new_documents && data.key !== `create_new_${type}`;
        if (isSelectTypeMode) {
            setActiveTypeChangeMode(data.key);
            typeChangeModeToggle(false);
            await handleChangeDocumentsType(data.keyPath);
            return;
        }

        if (data.key !== `create_new_${type}`) {
            setActiveType(data.keyPath);
            setActiveTypeChangeMode("");
            navigate("/documents");
            return;
        }
    };

    const items: MenuProps["items"] = [
        {
            label: (
                <SidebarMenuItemContent
                    id={ALL_DOCUMENTS}
                    name={<FormattedMessage id="app.dms.allDocuments" />}
                    count={allDocumentsDraftCount}
                    isSelected={activeType[0] === ALL_DOCUMENTS}
                    isNode={false}
                    typeKeyPath={[ALL_DOCUMENTS]}
                />
            ),
            key: ALL_DOCUMENTS,
            disabled: isTypeChangeMode || isDraggingRightNow || !hasFolderAccess(ALL_DOCUMENTS, userCtx, companyGQL),
            icon: <WalletOutlined />,
            style: {
                backgroundColor: activeType[0] === ALL_DOCUMENTS ? backgroundColorSelectedType : "",
            },
        },
        ...documentTypesArray.map(item => {
            const { type } = DmsUtils.getActiveTypeAndSubType(item.typeKeyPath);
            return {
                ...item,
                disabled: (item as any).disabled || !hasFolderAccess(type, userCtx, companyGQL),
            };
        }),
    ];

    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={[`${activeType || GQL.IProductKey.Er}`]}
            openKeys={openedMenuItemKeys}
            style={{
                borderRight: 0,
                background: "#F4F6F7",
                marginTop: "5px",
            }}
            items={items}
            onClick={handleMenuLink}
            onOpenChange={onOpenChange}
        />
    );
};

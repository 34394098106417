import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from "react";
import { IBank, ITransaction } from "@banks/types";
import { BanksContext } from "../../../scripts/context/BanksContext";

type TValue = {
    isUploadModalOpen: boolean;
    addBankData: { bankData?: Partial<IBank> | null; isModalOpen: boolean; isNeedSave?: true };
    editBankData: { bank: IBank | null; isModalOpen: boolean };
    lastBankCreateId?: string;
    activeBankId: string;
    bankToUpload?: IBank;
    editTransactionData: { transaction: Partial<ITransaction> | null; isModalOpen: boolean };
};
type TActionValue = {
    openTransactionUploader: (bankData?: IBank) => void;
    setAddBankData: (arg: { bankData?: Partial<IBank> | null; isModalOpen: boolean; isNeedSave?: true }) => void;
    setEditBankData: (arg: { bank: IBank | null; isModalOpen: boolean }) => void;
    resetModalState: () => void;
    setLastBankCreateId: (arg: string) => void;
    setActiveBankId: (id: string) => void;
    resetBankToUpload: () => void;
    closeTransactionUploader: () => void;
    setEditTransactionData: (arg: {
        transactionData: { transactionId: string; bankId: string } | null;
        isModalOpen: boolean;
    }) => void;
};

const initialValue: TValue = {
    isUploadModalOpen: false,
    activeBankId: null,
    addBankData: { bankData: null, isModalOpen: false },
    editBankData: { bank: null, isModalOpen: false },
    editTransactionData: { transaction: null, isModalOpen: false },
};

const initialActionValue = {
    openTransactionUploader: () => {},
    setAddBankData: () => {},
    setEditBankData: () => {},
    resetModalState: () => {},
    setLastBankCreateId: () => {},
    setActiveBankId: () => {},
    closeTransactionUploader: () => {},
    resetBankToUpload: () => {},
    setEditTransactionData: () => {},
};

export const BanksAppContext = createContext<TValue>(initialValue);
export const BanksAppControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const BanksAppContextProvider: FC<TProps> = ({ children }) => {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [bankToUpload, setBankToUpload] = useState(null);
    const [activeBankId, setActiveBankId] = useState<string>(null);
    const [addBankData, setAddBankData] = useState<{
        bankData?: Partial<IBank> | null;
        isModalOpen: boolean;
        isNeedSave?: true;
    }>({
        isModalOpen: false,
    });
    const [editBankData, setEditBankData] = useState<{ bank: IBank; isModalOpen: boolean }>({
        bank: null,
        isModalOpen: false,
    });

    const [editTransactionData, setEditTransactionData] = useState<{
        transaction: Partial<ITransaction> | null;
        isModalOpen: boolean;
    }>({
        transaction: null,
        isModalOpen: false,
    });

    const [lastBankCreateId, setLastBankCreateId] = useState<string>();

    const { transactionsData } = useContext(BanksContext);

    const value = useMemo(() => {
        return {
            isUploadModalOpen,
            editBankData,
            addBankData,
            lastBankCreateId,
            activeBankId,
            bankToUpload,
            editTransactionData,
        };
    }, [
        addBankData,
        editBankData,
        isUploadModalOpen,
        lastBankCreateId,
        activeBankId,
        bankToUpload,
        editTransactionData,
    ]);

    const actions = useMemo(() => {
        return {
            openTransactionUploader: (bankData?: IBank) => {
                setIsUploadModalOpen(true);
                setBankToUpload(bankData ?? null);
            },
            closeTransactionUploader: () => {
                setIsUploadModalOpen(false);
            },
            setEditBankData,
            setAddBankData,
            setActiveBankId,
            setEditTransactionData: (arg: {
                transactionData: { transactionId: string; bankId: string } | null;
                isModalOpen: boolean;
            }) => {
                const { transactionData, isModalOpen } = arg;

                if (!transactionData) {
                    return setEditTransactionData({ transaction: null, isModalOpen: false });
                }

                const transaction =
                    transactionsData?.[transactionData?.bankId]?.[transactionData?.transactionId] || null;
                setEditTransactionData({ transaction, isModalOpen });
            },
            resetModalState: () => {
                setAddBankData({ isModalOpen: false });
                setEditBankData({
                    bank: null,
                    isModalOpen: false,
                });
            },
            resetBankToUpload: () => {
                setBankToUpload(null);
            },
            setLastBankCreateId: (arg: string) => {
                setLastBankCreateId(arg);
            },
        };
    }, [transactionsData]);

    return (
        <BanksAppContext.Provider value={value}>
            <BanksAppControlContext.Provider value={actions}>{children}</BanksAppControlContext.Provider>
        </BanksAppContext.Provider>
    );
};

import { gql } from "@apollo/client";

export const invoiceCreate = gql`
    mutation invoiceCreate($input: InvoiceCreateInput!) {
        invoiceCreate(input: $input) {
            id
        }
    }
`;

export const invoiceUpdate = gql`
    mutation invoiceUpdate($input: InvoiceUpdateInput!) {
        invoiceUpdate(input: $input) {
            id
        }
    }
`;

export const invoiceApplyCurrencyRate = gql`
    mutation invoiceApplyCurrencyRate($input: invoiceApplyCurrencyRateInput!) {
        invoiceApplyCurrencyRate(input: $input)
    }
`;

export const invoiceDelete = gql`
    mutation invoiceDelete($input: InvoiceDeleteInput!) {
        invoiceDelete(input: $input)
    }
`;

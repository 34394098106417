import { RightOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Collapse, CollapseProps, ConfigProvider, Flex, List, Popover, Switch, Typography } from "antd";
import React, { type FC, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DmsAppContext, DmsAppControlContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { AppConfigUtils } from "@dms/scripts/utils/AppConfigUtils";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { IInitColumnsType, TDmsTableCols, TExcludedColsFromSetting, TTableCols } from "@dms/types";

import styles from "./settingsColumns.module.scss";
import { ConfigColumnGroups, IColumnState, TSwitchedGroup } from "./type";
import { currencyColumns, deadlineColumns, discountColumns, infoColumns } from "./constants";
import { blue } from "@ant-design/colors";
import { DmsTableCols, ExcludedColsFromSetting, TranslateDmsTableColumns } from "../../consts";

interface IProps {
    activeType: string[];
    configCols: TTableCols[];
    className?: string;
}

const { Text } = Typography;

export const SettingsColumns: FC<IProps> = memo(({ activeType, configCols, className }) => {
    const [columnState, setColumnState] = useState<IColumnState>();
    const [switchedGroup, setSwitchedGroup] = useState<TSwitchedGroup>();
    const [switchedAll, setSwitchedAll] = useState(false);
    const [resetDisabled, setResetDisabled] = useState(true);
    const [isDefaultState, setIsDefaultState] = useState(true);

    const { isOpenSettingsCol } = useContext(DmsAppContext);
    const { toggleIsOpenSettingsCol } = useContext(DmsAppControlContext);
    const { setColumnsTableConfig } = useContext(DmsUserSettingsContext);

    const initialconfigCols = useRef(configCols);

    const columnsList = useMemo(() => {
        if (!configCols) {
            return;
        }

        const values = Object.values(DmsTableCols) as TDmsTableCols[];
        const filteredValues = values.filter(
            value => !Object.values(ExcludedColsFromSetting).includes(value as unknown as TExcludedColsFromSetting)
        );

        const createColumnValues = (columns: TDmsTableCols[]) =>
            filteredValues
                .filter(el => columns.includes(el))
                .map(value => ({
                    value,
                    checked: configCols.some(config => config[value]),
                    translate: TranslateDmsTableColumns[value],
                }));

        return {
            discountValues: createColumnValues(discountColumns),
            currencyValues: createColumnValues(currencyColumns),
            deadlineValues: createColumnValues(deadlineColumns),
            infoValues: createColumnValues(infoColumns),
            otherValues: createColumnValues(
                filteredValues.filter(
                    el =>
                        !(
                            [
                                ...discountColumns,
                                ...currencyColumns,
                                ...deadlineColumns,
                                ...infoColumns,
                            ] as unknown as TDmsTableCols
                        ).includes(el)
                )
            ),
        };
    }, [configCols]);

    useEffect(() => {
        toggleIsOpenSettingsCol(false);
    }, [activeType]);

    const setInitialState = () => {
        setColumnState(columnsList);
        const switchGroupCol = {} as TSwitchedGroup;
        Object.keys(columnsList).forEach((key: keyof IColumnState) => {
            if (key === "otherValues") {
                return;
            }
            switchGroupCol[key] = columnsList[key].every(el => el.checked);
        });

        setSwitchedGroup(switchGroupCol);
    };

    useEffect(() => {
        setInitialState();
    }, [columnsList, activeType]);

    const handleClose = async () => {
        const updatedTableConfig = configCols.map(config => {
            const updatedConfig = { ...config } as TTableCols;
            for (const group in columnState) {
                columnState[group as keyof IColumnState].forEach(item => {
                    if (item.value in updatedConfig) {
                        updatedConfig[item.value] = item.checked;
                    }
                });
            }
            return updatedConfig;
        });

        await setColumnsTableConfig(activeType, updatedTableConfig);
        initialconfigCols.current = [...configCols];

        toggleIsOpenSettingsCol(false);
        setResetDisabled(true);
    };

    const handleChange = (value: keyof TTableCols, checked: boolean, configGroup?: ConfigColumnGroups) => {
        const newLocalConfigState =
            columnState[configGroup]?.map(item => (item.value === value ? { ...item, checked } : item)) || [];

        const newAllConfigState = {
            ...columnState,
            [configGroup]: newLocalConfigState,
        };

        setColumnState(newAllConfigState);

        setSwitchedGroup(prev => ({
            ...prev,
            [configGroup]: newLocalConfigState.every(el => el.checked),
        }));

        setResetDisabled(false);
    };

    const handleChangeGroupAll = (checked: boolean, configGroup: ConfigColumnGroups) => {
        setColumnState(prev => {
            return {
                ...prev,
                [configGroup]: prev[configGroup].map(el => {
                    return {
                        ...el,
                        checked,
                    };
                }),
            };
        });

        setSwitchedGroup(prev => {
            return {
                ...prev,
                [configGroup]: checked,
            };
        });
    };

    const handleAll = (checked: boolean) => {
        const newAllConfigState = {} as IColumnState;
        Object.keys(columnState).forEach((key: keyof IColumnState) => {
            newAllConfigState[key] = columnState[key].map(item => ({
                ...item,
                checked,
            }));
        });

        setColumnState(newAllConfigState);

        setSwitchedGroup(prev => {
            const nextState = {} as TSwitchedGroup;
            Object.keys(prev).forEach((key: keyof TSwitchedGroup) => {
                nextState[key] = checked;
            });
            return nextState;
        });

        setSwitchedAll(checked);
        setResetDisabled(false);
    };

    const handleResetToInitial = () => {
        setInitialState();
        setResetDisabled(true);
    };

    const handleResetToDefault = () => {
        const { type } = DmsUtils.getActiveTypeAndSubType(activeType);
        const { defaultColumnsConfig } = AppConfigUtils.getDefaultConfig(type as keyof IInitColumnsType);

        const newAllConfigState = {} as IColumnState;
        Object.keys(columnState).forEach((key: keyof IColumnState) => {
            newAllConfigState[key] = columnState[key].map(item => ({
                ...item,
                checked: defaultColumnsConfig[item.value],
            }));
        });
        setColumnState(newAllConfigState);

        setSwitchedGroup(prev => {
            const nextState = {} as TSwitchedGroup;
            Object.keys(prev).forEach((key: keyof Omit<IColumnState, "otherValues">) => {
                nextState[key] = newAllConfigState[key].every(el => el.checked);
            });
            return nextState;
        });

        setIsDefaultState(true);
        setResetDisabled(false);
    };

    const isGroupChecked = (arg: ConfigColumnGroups) => {
        if (!switchedGroup) {
            return;
        }
        const key = arg as keyof TSwitchedGroup;
        return switchedGroup[key];
    };

    const checkToDefaultConfig = () => {
        const { type } = DmsUtils.getActiveTypeAndSubType(activeType);
        const { defaultColumnsConfig } = AppConfigUtils.getDefaultConfig(type as keyof IInitColumnsType);

        const isDefault = Object.keys(columnState).every((key: keyof IColumnState) => {
            return columnState[key].every(item => item.checked === defaultColumnsConfig[item.value]);
        });

        setIsDefaultState(isDefault);
    };

    const getGroupTextColorStatus = (arg: keyof Omit<IColumnState, "otherValues">) => {
        if (!columnState || !switchedGroup) {
            return "inherit";
        }
        return columnState[arg].some(el => el.checked) && !switchedGroup[arg] ? blue[6] : "inherit";
    };

    useEffect(() => {
        if (!columnState) {
            return;
        }
        setSwitchedAll(
            Object.values(columnState)
                .flat()
                .every(item => item.checked)
        );

        checkToDefaultConfig();
    }, [columnState]);

    const groupSwitchItems: CollapseProps["items"] = [
        {
            key: ConfigColumnGroups.INFO_VALUES,
            label: (
                <div className={styles.groupSwitchWrapper}>
                    <Switch
                        id={ConfigColumnGroups.INFO_VALUES as string}
                        checked={isGroupChecked(ConfigColumnGroups.INFO_VALUES)}
                        onChange={(checked, event) => {
                            event.stopPropagation();
                            handleChangeGroupAll(checked, ConfigColumnGroups.INFO_VALUES);
                        }}
                        size={"small"}
                    />
                    <Text
                        style={{ marginLeft: "15px", color: getGroupTextColorStatus(ConfigColumnGroups.INFO_VALUES) }}
                        strong
                    >
                        <FormattedMessage id={"app.dms.fileInformation"} />
                    </Text>
                </div>
            ),
            children: (
                <List
                    itemLayout="horizontal"
                    dataSource={columnState?.[ConfigColumnGroups.INFO_VALUES]}
                    grid={{ column: 2 }}
                    renderItem={item => (
                        <List.Item>
                            <Switch
                                id={item.value as string}
                                checked={item.checked}
                                onChange={checked => handleChange(item.value, checked, ConfigColumnGroups.INFO_VALUES)}
                                size={"small"}
                            />
                            <Text style={{ marginLeft: "15px" }}>
                                <FormattedMessage id={item.translate} />
                            </Text>
                        </List.Item>
                    )}
                />
            ),
        },
        {
            key: ConfigColumnGroups.CURRENCY_VALUES,
            label: (
                <div className={styles.groupSwitchWrapper}>
                    <Switch
                        id={ConfigColumnGroups.CURRENCY_VALUES as string}
                        checked={isGroupChecked(ConfigColumnGroups.CURRENCY_VALUES)}
                        onChange={(checked, event) => {
                            event.stopPropagation();
                            handleChangeGroupAll(checked, ConfigColumnGroups.CURRENCY_VALUES);
                        }}
                        size={"small"}
                    />
                    <Text
                        style={{
                            marginLeft: "15px",
                            color: getGroupTextColorStatus(ConfigColumnGroups.CURRENCY_VALUES),
                        }}
                        strong
                    >
                        <FormattedMessage id="app.fields.currency" />
                    </Text>
                </div>
            ),
            children: (
                <List
                    itemLayout="horizontal"
                    dataSource={columnState?.[ConfigColumnGroups.CURRENCY_VALUES]}
                    grid={{ column: 2 }}
                    renderItem={item => (
                        <List.Item>
                            <Switch
                                id={item.value as string}
                                checked={item.checked}
                                onChange={checked =>
                                    handleChange(item.value, checked, ConfigColumnGroups.CURRENCY_VALUES)
                                }
                                size={"small"}
                            />
                            <Text style={{ marginLeft: "15px" }}>
                                <FormattedMessage id={item.translate} />
                            </Text>
                        </List.Item>
                    )}
                />
            ),
        },
        {
            key: ConfigColumnGroups.DISCOUNT_VALUES,
            label: (
                <div className={styles.groupSwitchWrapper}>
                    <Switch
                        id={ConfigColumnGroups.DISCOUNT_VALUES as string}
                        checked={isGroupChecked(ConfigColumnGroups.DISCOUNT_VALUES)}
                        onChange={(checked, event) => {
                            event.stopPropagation();
                            handleChangeGroupAll(checked, ConfigColumnGroups.DISCOUNT_VALUES);
                        }}
                        size={"small"}
                    />
                    <Text
                        style={{
                            marginLeft: "15px",
                            color: getGroupTextColorStatus(ConfigColumnGroups.DISCOUNT_VALUES),
                        }}
                        strong
                    >
                        <FormattedMessage id="app.fields.skonto" />
                    </Text>
                </div>
            ),
            children: (
                <List
                    itemLayout="horizontal"
                    dataSource={columnState?.[ConfigColumnGroups.DISCOUNT_VALUES]}
                    grid={{ column: 2 }}
                    renderItem={item => (
                        <List.Item>
                            <Switch
                                id={item.value as string}
                                checked={item.checked}
                                onChange={checked =>
                                    handleChange(item.value, checked, ConfigColumnGroups.DISCOUNT_VALUES)
                                }
                                size={"small"}
                            />
                            <Text style={{ marginLeft: "15px" }}>
                                <FormattedMessage id={item.translate} />
                            </Text>
                        </List.Item>
                    )}
                />
            ),
        },
        {
            key: ConfigColumnGroups.DEADLINE_VALUES,
            label: (
                <div className={styles.groupSwitchWrapper}>
                    <Switch
                        id={ConfigColumnGroups.DEADLINE_VALUES}
                        checked={isGroupChecked(ConfigColumnGroups.DEADLINE_VALUES)}
                        onChange={(checked, event) => {
                            event.stopPropagation();
                            handleChangeGroupAll(checked, ConfigColumnGroups.DEADLINE_VALUES);
                        }}
                        size={"small"}
                    />
                    <Text
                        style={{
                            marginLeft: "15px",
                            color: getGroupTextColorStatus(ConfigColumnGroups.DEADLINE_VALUES),
                        }}
                        strong
                    >
                        <FormattedMessage id={"app.fields.falligkeit"} />
                    </Text>
                </div>
            ),
            children: (
                <List
                    itemLayout="horizontal"
                    dataSource={columnState?.[ConfigColumnGroups.DEADLINE_VALUES]}
                    grid={{ column: 2 }}
                    renderItem={item => (
                        <List.Item>
                            <Switch
                                id={item.value as string}
                                checked={item.checked}
                                onChange={checked =>
                                    handleChange(item.value, checked, ConfigColumnGroups.DEADLINE_VALUES)
                                }
                                size={"small"}
                            />
                            <Text style={{ marginLeft: "15px" }}>
                                <FormattedMessage id={item.translate} />
                            </Text>
                        </List.Item>
                    )}
                />
            ),
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Divider: {
                        marginLG: 8,
                        margin: 8,
                    },
                    Collapse: {
                        headerPadding: 0,
                        padding: 0,
                    },
                },
            }}
        >
            <div className={className ? className : styles.wrapper}>
                <Popover
                    destroyTooltipOnHide={true}
                    title={
                        <div className={styles.title}>
                            <Flex justify="end" gap="10">
                                <Button type={"link"} onClick={handleResetToInitial} disabled={resetDisabled}>
                                    <FormattedMessage id={"app.button.reset"} />
                                </Button>
                                <Button type={"link"} onClick={handleResetToDefault} disabled={isDefaultState}>
                                    <FormattedMessage id={"app.button.default"} />
                                </Button>
                                <div className={styles.switchWrapper}>
                                    <Switch
                                        id={"all"}
                                        checked={switchedAll}
                                        onChange={checked => {
                                            handleAll(checked);
                                        }}
                                        size={"small"}
                                    />
                                    <Text style={{ marginLeft: "15px" }} strong>
                                        <FormattedMessage id={"app.dms.selectAll"} />
                                    </Text>
                                </div>
                            </Flex>
                        </div>
                    }
                    placement="bottomLeft"
                    onOpenChange={async isOpen => {
                        if (!isOpen) {
                            await handleClose();
                        }
                        toggleIsOpenSettingsCol(isOpen);
                    }}
                    open={isOpenSettingsCol}
                    trigger="click"
                    content={
                        <div className={styles.content}>
                            <List
                                itemLayout="horizontal"
                                dataSource={columnState?.[ConfigColumnGroups.OTHER_VALUES]}
                                grid={{ column: 2 }}
                                renderItem={item => (
                                    <List.Item>
                                        <Switch
                                            id={item.value as string}
                                            checked={item.checked}
                                            onChange={checked =>
                                                handleChange(item.value, checked, ConfigColumnGroups.OTHER_VALUES)
                                            }
                                            size={"small"}
                                        />
                                        <Text style={{ marginLeft: "15px" }}>
                                            <FormattedMessage id={item.translate} />
                                        </Text>
                                    </List.Item>
                                )}
                            />

                            <div className={styles.groupSwitch}>
                                <Collapse
                                    ghost
                                    items={groupSwitchItems}
                                    expandIcon={({ isActive }) => {
                                        return (
                                            <div style={{ position: "absolute", right: "60%" }}>
                                                <RightOutlined rotate={isActive ? 90 : 0} />
                                            </div>
                                        );
                                    }}
                                    expandIconPosition={"end"}
                                ></Collapse>

                                <Flex justify={"flex-end"} align={"center"} gap={10}>
                                    <Button onClick={handleClose} type={"primary"}>
                                        <FormattedMessage id={`app.button.confirm`} />
                                    </Button>
                                </Flex>
                            </div>
                        </div>
                    }
                >
                    <button className={styles.settings} onClick={() => toggleIsOpenSettingsCol(!isOpenSettingsCol)}>
                        <SettingOutlined />
                    </button>
                </Popover>
            </div>
        </ConfigProvider>
    );
});

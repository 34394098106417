import React, { FC } from "react";
import { Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { CurrencySelect } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";

import { Contacts, GQL } from "@binale-tech/shared";
import {
    InputNumber as InputNumberBinale
} from "appearance/components/shared/form/baseComponents/InputNumber/InputNumber";
import { InputPercents } from "appearance/components/shared/form/baseComponents/InputPercents/InputPercents";
import { useNothingSelectedMessage } from "./utils";

interface PartialProps {
    index: number;
    type: GQL.IContactRelation;
}

export const PartialTerms: FC<PartialProps> = React.memo(function PartialTerms({ type, index }) {
    return (
        <div>
            <Divider orientation="left" plain style={{ borderTopColor: "#1890ff" }}>
                <FormattedMessage id={`app.titles.contacts.${type}`} tagName="b" />
            </Divider>
            <Row>
                <Space>
                    <Controller
                        name={`relations.${index}.paymentTerms.dueDays`}
                        shouldUnregister
                        render={({ field }) => (
                            <Form.Item
                                label={
                                    <>
                                        <FormattedMessage id="app.fields.falligkeit" /> (
                                        <FormattedMessage id="app.fields.days" />)
                                    </>
                                }
                                style={{ maxWidth: 130 }}
                            >
                                <InputNumberBinale
                                    style={{ width: "100%" }}
                                    negative={false}
                                    onlyInteger
                                    maxLength={3}
                                    {...field}
                                />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name={`relations.${index}.paymentTerms.discountDueDate`}
                        shouldUnregister
                        render={({ field }) => (
                            <Form.Item
                                label={
                                    <>
                                        <FormattedMessage id="app.fields.skonto" /> (
                                        <FormattedMessage id="app.fields.days" />)
                                    </>
                                }
                                style={{ maxWidth: 120 }}
                            >
                                <InputNumberBinale
                                    style={{ width: "100%" }}
                                    onlyInteger
                                    negative={false}
                                    maxLength={3}
                                    {...field}
                                />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name={`relations.${index}.paymentTerms.discount`}
                        shouldUnregister
                        render={({ field: { ref, ...rest } }) => (
                            <Form.Item
                                label={
                                    <>
                                        <FormattedMessage id="app.fields.skonto" /> (%)
                                    </>
                                }
                            >
                                <InputPercents {...rest} />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name={`relations.${index}.paymentTerms.paymentType`}
                        shouldUnregister
                        render={({ field }) => (
                            <Form.Item
                                label={<FormattedMessage id="app.fields.paymentMethod" />}
                                style={{ minWidth: 150 }}
                            >
                                <Select<Contacts.ContactConstants.PaymentTermTypes>
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    {...field}
                                    options={Object.keys(Contacts.ContactConstants.PaymentTermTypes).map(value => ({
                                        value,
                                        label: Contacts.ContactConstants.PaymentTermTypes[
                                            value as Contacts.ContactConstants.PaymentTermTypes
                                        ],
                                    }))}
                                />
                            </Form.Item>
                        )}
                    />
                </Space>
                <Col span={24}>
                    <Controller
                        name={`relations.${index}.paymentTerms.paymentPurpose`}
                        shouldUnregister
                        render={({ field }) => (
                            <Form.Item label={<FormattedMessage id="app.fields.paymentPurpose" />}>
                                <Input.TextArea rows={2} maxLength={140} showCount autoSize={true} {...field} />
                            </Form.Item>
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
});

export const PaymentTerms: React.FC = React.memo(function PaymentTerms() {
    const nothingSelectedText = useNothingSelectedMessage();
    const { watch } = useFormContext<Contacts.Contact>();
    const relations = watch("relations");
    const relationsToRender = relations.filter(r => r.type);

    return (
        <>
            <Controller
                name="defaultCurrencyCode"
                render={({ field }) => (
                    <Form.Item
                        label={<FormattedMessage id="app.fields.currency" />}
                        data-testid="legalInfo-currency"
                        style={{ maxWidth: 130 }}
                    >
                        <CurrencySelect {...field} />
                    </Form.Item>
                )}
            />
            {relationsToRender.length ? (
                <>
                    {Contacts.ContactConstants.relationTypeKontoOrder.map((type, index) => {
                        const relation = relationsToRender.find(r => r.type === type);
                        if (!relation) {
                            return null;
                        }
                        return <PartialTerms type={relation.type} key={relation.type} index={index} />;
                    })}
                </>
            ) : (
                nothingSelectedText
            )}
        </>
    );
});

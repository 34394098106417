import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { BanksAppControlContext } from "@banks/scripts/context";
import { IBank } from "@banks/types";
import { getAutomappingKey } from "@banks/scripts/helpers/headerKeyTools";
import { OptionsValues } from "@banks/types/enums";
import { getTransactionBankTitle } from "@banks/scripts/helpers";
import { BanksContext } from "../../../../scripts/context/BanksContext";

type TValue = {
    fieldMatching: Record<string, keyof typeof transactionFieldsDict | "unmapped">;
    unmappedDataKeys: string[];
    parseBankData: IBank;
    missingBankData: Partial<IBank>;
    bankTitleValue: string;
    isResetIbanSelector: boolean;
    previewParseResult: Record<string, string>[];
    reducedParseResult: Record<string, string>[];
};
type TActionValue = {
    setFieldMatching: (field: string, value: keyof typeof transactionFieldsDict | "unmapped") => void;
    intoParseTransaction: () => void;
    resetMissingBankData: () => void;
    setBankData: (arg: IBank) => void;
    setIsResetIbanSelector: (arg: boolean) => void;
    intoParsePreview: () => void;
};

const initialActionValue = {
    setFieldMatching: () => {},
    intoParseTransaction: () => {},
    resetMissingBankData: () => {},
    setBankData: () => {},
    setIsResetIbanSelector: () => {},
    intoParsePreview: () => {},
};

export const PreviewParseDataContext = createContext<TValue>({} as TValue);
export const PreviewParseDataControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
    initialBankData?: IBank;
    lastBankCreateId?: string;
    parseResult: Record<string, string>[];
    setPreviewMode: (arg: boolean) => void;
};

export const PreviewParseDataContextProvider: FC<TProps> = ({
    children,
    parseResult,
    initialBankData,
    lastBankCreateId,
    setPreviewMode,
}) => {
    const [fieldMatching, setFieldMatching] = useState<Record<string, keyof typeof transactionFieldsDict | "unmapped">>(
        {}
    );
    const [parseBankData, setParseBankData] = useState<IBank>(initialBankData);
    const [missingBankData, setMissingBankData] = useState<Partial<IBank>>();
    const [bankTitleValue, setBankTitleValue] = useState<string>();
    const [unmappedDataKeys, setUnmappedDataKeys] = useState<string[]>(Object.keys(transactionFieldsDict));

    const [reducedParseResult, setReducedParseResult] = useState<Record<string, string>[]>([]);

    const [isResetIbanSelector, setIsResetIbanSelector] = useState(false);

    const { banksData } = useContext(BanksContext);
    const { setLastBankCreateId } = useContext(BanksAppControlContext);

    const tableHeader = useMemo(() => {
        return Object.keys(parseResult[0] || {}).filter(rk => rk !== "csvLine");
    }, [parseResult]);

    const getBankByIban = (ibanKey: string) => {
        const iban = parseResult.find(el => el?.[ibanKey]?.length)?.[ibanKey];
        const bankByIban = Object.values(banksData).find(el => el.iban === iban);
        return { iban, bankByIban };
    };

    useEffect(() => {
        if (!lastBankCreateId) {
            return;
        }

        const lastBankCreated = banksData[lastBankCreateId];
        if (!lastBankCreated) {
            return;
        }

        setMissingBankData(null);
        setBankTitleValue(getTransactionBankTitle({ exist: lastBankCreated }));
        setParseBankData(lastBankCreated);

        setLastBankCreateId(null);
    }, [lastBankCreateId, banksData]);

    useEffect(() => {
        if (!parseResult.length) {
            setBankTitleValue(null);
            setMissingBankData(null);
            setParseBankData(null);
            return;
        }
    }, [parseResult]);

    useEffect(() => {
        if (!tableHeader.length) {
            return;
        }

        tableHeader.forEach(el => {
            const headerHardKey = getAutomappingKey(el);
            if (headerHardKey) {
                setFieldMatching(prev => {
                    return {
                        ...prev,
                        [el]: headerHardKey,
                    };
                });
            }
        });
    }, [tableHeader]);

    useEffect(() => {
        if (!Object.keys(fieldMatching).length) {
            return setUnmappedDataKeys(Object.keys(transactionFieldsDict));
        }

        setUnmappedDataKeys(
            Object.keys(transactionFieldsDict).filter(
                el => !Object.values(fieldMatching).includes(el as keyof typeof transactionFieldsDict | "unmapped")
            )
        );
    }, [fieldMatching]);

    const reduceParseData = useCallback(() => {
        const resultArr: Record<string, string>[] = [];
        Object.values(parseResult).forEach(row => {
            const resultRowData: Record<string, string> = {};
            Object.entries(row).forEach(entry => {
                const key = entry[0];
                const matchedKey = fieldMatching[key];

                if (matchedKey) {
                    resultRowData[matchedKey] = entry[1];
                }
            });

            resultRowData.csvLine = row.csvLine;
            resultArr.push(resultRowData);
        });

        return resultArr;
    }, [fieldMatching, parseResult]);

    const value = useMemo(() => {
        return {
            fieldMatching,
            unmappedDataKeys,
            parseBankData,
            missingBankData,
            bankTitleValue,
            isResetIbanSelector,
            reducedParseResult,
            previewParseResult: parseResult,
        };
    }, [
        fieldMatching,
        unmappedDataKeys,
        parseBankData,
        missingBankData,
        bankTitleValue,
        isResetIbanSelector,
        reducedParseResult,
        parseResult,
    ]);

    const actions = useMemo(() => {
        return {
            setFieldMatching: (field: string, val: keyof typeof transactionFieldsDict | "unmapped") => {
                setFieldMatching(prev => {
                    if (val === OptionsValues.UNMAPPED) {
                        delete prev[field];
                        return { ...prev };
                    }

                    return {
                        ...prev,
                        [field]: val,
                    };
                });
            },
            intoParseTransaction: () => {
                setReducedParseResult(reduceParseData());
                setPreviewMode(false);
            },
            intoParsePreview: () => {
                setPreviewMode(true);
            },
            resetMissingBankData: () => {
                setMissingBankData(null);
            },
            setBankData: (arg: IBank) => {
                setParseBankData(arg);
                setMissingBankData(null);
                setBankTitleValue(getTransactionBankTitle({ exist: arg }));
            },
            setIsResetIbanSelector,
        };
    }, [reduceParseData]);

    return (
        <PreviewParseDataContext.Provider value={value}>
            <PreviewParseDataControlContext.Provider value={actions}>
                {children}
            </PreviewParseDataControlContext.Provider>
        </PreviewParseDataContext.Provider>
    );
};

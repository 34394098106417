import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { IInvoiceFormLineItem, ITotalState, TInvoiceFormColumnsConfig } from "@inv/types";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { InvoiceFormContext } from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";
import { decimalFormatter } from "@dms/scripts/helpers";

type TValue = {
    columnsConfig: TInvoiceFormColumnsConfig;
    isOpenSettingsCol: boolean;
    totalState: ITotalState;
    currency: string;
};

type TActionValue = {
    setColumnsConfig: (newValue: TInvoiceFormColumnsConfig) => void;
    setIsOpenSettingsCol: (newValue: boolean) => void;
    calculateTotal: (data: IInvoiceFormLineItem[]) => void;
    setCurrency: (newValue: string) => void;
};

const initialValue: TValue = {
    columnsConfig: InvoiceFormColumnsConfig.InitColumnConfig,
    isOpenSettingsCol: false,
    totalState: {
        subtotal: null,
        tax0: null,
        tax7: null,
        tax19: null,
        total: null,
    },
    currency: null,
};

const initialActionValue = {
    setColumnsConfig: () => {},
    setIsOpenSettingsCol: () => {},
    calculateTotal: () => {},
    setCurrency: () => {},
};

export const InvoiceTableContext = createContext<TValue>(initialValue);
export const InvoiceTableControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoiceTableContextProvider: FC<TProps> = ({ children }) => {
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const [columnsConfig, setColumnsConfig] = useState<TInvoiceFormColumnsConfig>(initialValue.columnsConfig);
    const [isOpenSettingsCol, setIsOpenSettingsCol] = useState<boolean>(false);
    const [totalState, setTotalState] = useState<ITotalState>(initialValue.totalState);
    const [currency, setCurrency] = useState<string | null>(null);

    const calculateTotal = useCallback(
        (data: IInvoiceFormLineItem[] = []) => {
            const { originalAmount, tax0, tax7, tax19 } = data.reduce(
                (acc, row) => {
                    const originalAmount = row.total / 100;
                    acc.originalAmount += originalAmount;
                    const taxTotal = originalAmount * (row.tax / 100);

                    if (row.tax === 7) {
                        acc.tax7 += taxTotal;
                    } else if (row.tax === 19) {
                        acc.tax19 += taxTotal;
                    } else {
                        acc.tax0 += taxTotal;
                    }

                    return acc;
                },
                { originalAmount: 0, tax0: 0, tax7: 0, tax19: 0 }
            );

            const total = originalAmount + tax7 + tax19;

            setTotalState({
                subtotal: decimalFormatter(originalAmount),
                tax0: tax0 ? decimalFormatter(tax0) : null,
                tax7: tax7 ? decimalFormatter(tax7) : null,
                tax19: tax19 ? decimalFormatter(tax19) : null,
                total: decimalFormatter(invoiceTotalView ? total : originalAmount),
            });
        },
        [invoiceTotalView]
    );

    useEffect(() => {
        setColumnsConfig(prev => ({
            ...prev,
            tax: invoiceTotalView,
        }));
    }, [invoiceTotalView]);

    const value = {
        columnsConfig,
        isOpenSettingsCol,
        totalState,
        currency,
    };

    const actions = {
        setColumnsConfig,
        setIsOpenSettingsCol,
        setCurrency,
        calculateTotal,
    };

    return (
        <InvoiceTableContext.Provider value={value}>
            <InvoiceTableControlContext.Provider value={actions}>{children}</InvoiceTableControlContext.Provider>
        </InvoiceTableContext.Provider>
    );
};

import React, { ChangeEvent, useState } from "react";
import { Input } from "antd";
import { CustomCellEditorProps } from "ag-grid-react";
import { IInvoiceFormLineItem, NavigationKeys } from "@inv/types";
import { stringToFloat } from "@inv/scripts/utils/utils";

import styles from "@inv/modules/InvoiceTableModule/Editors/editors.module.scss";

interface IProps extends CustomCellEditorProps<IInvoiceFormLineItem> {
    max: number;
}

export const DiscountEditor: React.FC<IProps> = ({ data, max, value, onValueChange, stopEditing }) => {
    const [inputValue, setInputValue] = useState<string>(value);

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => setInputValue(target.value);
    const handleFocus = ({ target }: React.FocusEvent<HTMLInputElement>) => target.select();

    const handleConfirm = (newValue: string) => {
        const parseValue = stringToFloat(newValue);

        if (isNaN(parseValue) || parseValue > max || parseValue < 0) {
            return stopEditing();
        }

        const discount = data.price * (parseValue / 100);
        const discountPerUnit = data.price - discount;

        onValueChange(discountPerUnit);
        stopEditing();
    };

    return (
        <Input
            value={inputValue}
            onChange={handleChange}
            className={styles.agEditor}
            variant="borderless"
            onKeyDown={e => {
                if (e.key === NavigationKeys.ENTER) {
                    handleConfirm(inputValue);
                }
            }}
            onFocus={handleFocus}
            onBlur={() => handleConfirm(inputValue)}
            autoFocus
        />
    );
};

import React, { memo, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, ExportOutlined, EyeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { IRowNode } from "ag-grid-community";
import { LineItemsModal } from "@inv/components/LineItemsModal";
import { InvoiceFormBlocksTranslate, Path, TInvoicesListData } from "@inv/types";
import { InvoicesApi } from "@inv/scripts/api";

import styles from "./actionList.module.scss";
import { InvoiceModalControlContext } from "@inv/modules/InvoiceModalModule/context/InvoiceModalModule";
import { InvoiceViewer } from "@inv/components/InvoiceViewer";

interface IProps {
    node: IRowNode<TInvoicesListData>;
}
export const ActionList = memo<IProps>(({ node }) => {
    const intl = useIntl();
    const { openModal } = useContext(InvoiceModalControlContext);

    if (node.rowPinned) {
        return null;
    }

    const editPath = `/${Path.INVOICES}/${Path.EDITING}/${node.data.id}`;

    const handleDeleteInvoice = async () => {
        await InvoicesApi.invoiceDelete({ id: node.data.id });
    };

    const handleInvoiceVew = () => {
        openModal({
            title: InvoiceFormBlocksTranslate.INVOICE_VIEWER,
            height: "calc(100vh - 300px)",
            component: <InvoiceViewer invoiceId={node.data.id} />,
        });
    };

    const handleLineItems = () => {
        openModal({
            title: InvoiceFormBlocksTranslate.LINE_ITEMS,
            height: "300px",
            component: (
                <LineItemsModal isTaxIncluded={node.data.isTaxIncluded} lineItemsList={node.data.lineItemsList} />
            ),
        });
    };

    return (
        <Flex
            className={styles.action}
            align="center"
            justify="space-between"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                <Button
                    icon={<EyeOutlined />}
                    className={styles.actionsItem}
                    type={"text"}
                    onClick={handleInvoiceVew}
                />
            </Tooltip>
            <Tooltip
                title={`${intl.formatMessage({ id: "app.dms.view" })} ${intl.formatMessage({ id: InvoiceFormBlocksTranslate.LINE_ITEMS })}`}
            >
                <Button
                    icon={<UnorderedListOutlined />}
                    className={styles.actionsItem}
                    disabled={node.data.lineItemsList.length <= 1}
                    type={"link"}
                    onClick={handleLineItems}
                />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.send_to_dms" />}>
                <Button icon={<ExportOutlined />} className={styles.actionsItem} type="text" disabled={true} />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.edit" />}>
                <Link to={editPath}>
                    <Button className={styles.actionsItem} type="text" icon={<EditOutlined />} />
                </Link>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.delete" />}>
                <Popconfirm
                    title={intl.formatMessage({ id: "app.confirmation.invoice_remove.body" })}
                    onConfirm={handleDeleteInvoice}
                    onCancel={null}
                    okText={intl.formatMessage({ id: "app.global.yes" })}
                    cancelText={intl.formatMessage({ id: "app.global.no" })}
                >
                    <Button icon={<DeleteOutlined />} className={styles.actionsItem} type="text" />
                </Popconfirm>
            </Tooltip>
        </Flex>
    );
});

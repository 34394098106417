import React, { FC } from "react";
import { InvoicesListContextProvider } from "@inv/modules/InvocesListModule/context";
import { InvoiceFormContextProvider } from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";
import { InvoiceTableContextProvider } from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { EditInvoiceContextProvider } from "@inv/modules/EditInvoiceModule/context";
import { InvoiceModalContextProvider } from "@inv/modules/InvoiceModalModule/context/InvoiceModalModule";

export const InvoiceProvider: FC<React.PropsWithChildren> = props => {
    return (
        <InvoicesListContextProvider>
            <InvoiceFormContextProvider>
                <EditInvoiceContextProvider>
                    <InvoiceTableContextProvider>
                        <InvoiceModalContextProvider>{props.children}</InvoiceModalContextProvider>
                    </InvoiceTableContextProvider>
                </EditInvoiceContextProvider>
            </InvoiceFormContextProvider>
        </InvoicesListContextProvider>
    );
};

import React, { type FC } from "react";

import { SingleDocumentForm } from "@dms/components/DocumentForm/SingleDocumentForm";
import { DocumentInfo } from "@dms/components/DocumentInfo/DocumentInfo";
import { IDocumentEnriched } from "@dms/types";
import { SingleFormProvider } from "./context/SingleFormContext";

interface IProps {
    documentInitialValue?: IDocumentEnriched;
}

export const SingleFormModule: FC<IProps> = React.memo(({ documentInitialValue }) => {
    return documentInitialValue ? (
        <>
            <DocumentInfo document={documentInitialValue} />
            <SingleFormProvider documentInitialValue={documentInitialValue}>
                <SingleDocumentForm />
            </SingleFormProvider>
        </>
    ) : null;
});

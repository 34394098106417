import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { ContactsContext } from "../../../../../scripts/context/ContactsContext";
import { ContactSelect } from "@app/components/shared/form/baseComponents/ContactSelect/ContactSelect";
import { Contacts } from "@binale-tech/shared";

type TValue = {
    name: string;
    id?: string;
};

interface InvoiceContactProps {
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
}

export const InvoiceContact: FC<InvoiceContactProps> = ({ value, onChange, placeholder }) => {
    const { contacts } = useContext(ContactsContext);
    const [inputValue, setInputValue] = useState<TValue>(null);

    const selectedContact = useMemo(() => {
        const contact = contacts.find(c => c.uuid === value);

        if (!contact) {
            return null;
        }

        return {
            id: contact.uuid,
            name: Contacts.getLabelName(contact),
        };
    }, [value]);

    useEffect(() => {
        if (!inputValue?.id) {
            return;
        }
        onChange?.(inputValue.id);
    }, [inputValue]);

    return <ContactSelect value={selectedContact} onChange={setInputValue} placeholder={placeholder} />;
};

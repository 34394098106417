import React, { FC } from "react";
import InvoicesApp from "@inv/InvoicesApp";
import { InvoiceProvider } from "@inv/scripts/context";

export const Invoices: FC = () => {
    return (
        <InvoiceProvider>
            <InvoicesApp />
        </InvoiceProvider>
    );
};

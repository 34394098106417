import RecordFormState from "../types/RecordFormState";
import Tag, { TAG_EMPTY } from "scripts/models/Tag";
import { Base, Bu, GQL, Periods, Utils } from "@binale-tech/shared";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { BuTimeframe } from "scripts/context/BuContext";
import { Category, Creditor, Debitor, GenericItem, GenericRecord } from "scripts/models";
import { CategoryCreditorMode, CategoryCreditorModes, ProductConfig } from "scripts/core/Product";
import { IGenericItem, ItemRecordContext } from "scripts/models/Interfaces";
import { CurrencyValue } from "@binale-tech/ui-components";

class RecordFormUtils {
    static getRest(
        editableRecord: Pick<
            RecordFormState["editableRecord"],
            "recordCurrency" | "recordOriginalAmount" | "recordBrutto"
        >,
        ri: Pick<IGenericItem, "brutto" | "originalAmount">[] = []
    ): { rest: number; hasCurrency: boolean; restEuro: number } {
        const { recordBrutto = 0, recordOriginalAmount = 0, recordCurrency } = editableRecord;
        const hasCurrency = Boolean(recordCurrency);

        const restEuro = RecordFormUtils.getRestBrutto(recordBrutto, ri);
        const rest = hasCurrency ? RecordFormUtils.getRestOriginal(recordOriginalAmount, ri) : restEuro;
        return { rest, hasCurrency, restEuro };
    }

    protected static getRestBrutto(recordBrutto: number, ri: IGenericItem[] = []) {
        const rest = recordBrutto - ri.reduce((v, c) => v + (c.brutto || 0), 0);
        return Object.is(-0, rest) ? 0 : rest;
    }

    protected static getRestOriginal(recordOriginalAmount: number, ri: IGenericItem[] = []) {
        const rest = recordOriginalAmount - ri.reduce((v, c) => v + (c.originalAmount || 0), 0);
        return Object.is(-0, rest) ? 0 : rest;
    }

    static getBrutto(currency: Base.CurrencyConfig, ri: Pick<IGenericItem, "brutto" | "originalAmount">[]) {
        const hasCurrency = Boolean(currency);
        if (hasCurrency) {
            const originalBrutto = ri.reduce((v, c) => v + (c.originalAmount || 0), 0);
            return Utils.CurrencyUtils.getEuroFromCurrency(originalBrutto, currency.rate);
        } else {
            return ri.reduce((v, c) => v + (c.brutto || 0), 0);
        }
    }

    static getRestNetto(
        editableRecord: Pick<RecordFormState["editableRecord"], "recordCurrency" | "recordCategoryCreditor">,
        recordDate: RecordFormState["recordDate"],
        skr: Base.CompanySKR,
        recordTmpNetto = 0,
        productKey: GQL.IProductKey,
        ri: Pick<IGenericItem, "getNetto" | "getOriginalCurrencyNetto">[] = []
    ): { rest: number; hasCurrency: boolean; restEuro: number } {
        const hasCurrency = Boolean(editableRecord.recordCurrency);
        const { date, period } = recordDate;
        const { recordCategoryCreditor } = editableRecord;
        const itemRecordCtx: ItemRecordContext = {
            recordKonto: recordCategoryCreditor,
            product: productKey,
            period,
            year: date?.getFullYear(),
        };
        const nettos = ri.map(v => {
            const restEuro = v.getNetto(itemRecordCtx, skr);
            const rest = hasCurrency ? v.getOriginalCurrencyNetto(itemRecordCtx, skr) : restEuro;
            return { rest, restEuro };
        });

        const restNettoEuro = recordTmpNetto - nettos.reduce((v, c) => v + c.restEuro, 0);
        const restNetto = recordTmpNetto - nettos.reduce((v, c) => v + c.rest, 0);
        return {
            hasCurrency,
            rest: Object.is(-0, restNetto) ? 0 : restNetto,
            restEuro: Object.is(-0, restNettoEuro) ? 0 : restNettoEuro,
        };
    }

    static recalculateCurrencyInItems(items: IGenericItem[] = [], currency: Base.CurrencyConfig) {
        return items.map(item => {
            if (currency) {
                const originalAmount = item.originalAmount || item.brutto;
                const euroAmount = Utils.CurrencyUtils.getEuroFromCurrency(originalAmount, currency.rate);
                if (euroAmount !== item.brutto) {
                    item.originalAmount = originalAmount;
                    item.brutto = euroAmount;
                }
            }
            if (!currency && item.originalAmount) {
                item.brutto = item.originalAmount;
                item.originalAmount = null;
            }
            return item;
        });
    }

    static resolveCCD = (i: Category | Creditor | Debitor) => {
        let category: Category;
        let creditor: Creditor;
        let debitor: Debitor;
        if (i instanceof Category) {
            category = i;
        } else if (i instanceof Debitor) {
            debitor = i;
        } else if (i instanceof Creditor) {
            creditor = i;
        }
        return { category, creditor, debitor };
    };

    static getCC(
        i: Category | Creditor | Debitor,
        date: { date: Date; period: number },
        skr: number,
        buTimeframes: BuTimeframe[],
        mode: CategoryCreditorMode,
        resolver: ProductConfig["itemAccountResolver"] | ProductConfig["recordAccountResolver"],
        selectedRecordGroup?: string
    ): { creditor: Creditor; debitor: Debitor; category: Category } {
        let { creditor, debitor, category } = this.resolveCCD(i);
        const year = date.date.getFullYear();
        const period = date.period;
        if (mode === CategoryCreditorModes.RESOLVER) {
            if (resolver) {
                const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(skr, buTimeframes, year, period);
                const defaultCategories = buTimeframe?.defaultCategories || new Map();
                const staticCat = resolver(year, defaultCategories, selectedRecordGroup);
                category = staticCat;
            } else {
                throw new Error("mode === CategoryCreditorModes.RESOLVER, but resolver is empty");
            }
        }
        return {
            debitor,
            category,
            creditor,
        };
    }

    static constructRecordItem(
        state: Pick<RecordFormState, "editableRecordItem" | "isNettoMode" | "recordDate">,
        productFormConfig: ProductConfig,
        skr: number,
        buTimeframes: BuTimeframe[],
        selectedRecordGroup: string
    ) {
        const {
            itemCategoryCreditor,
            itemBu,
            itemBrutto,
            itemTag,
            itemOriginalAmount,
            itemBelegfeld1,
            itemBelegfeld2,
            itemText,
            itemText2,
            itemUSt13b,
        } = state.editableRecordItem;

        const { recordDate } = state;
        const processBrutto = (bruttoVal = 0) => {
            bruttoVal = bruttoVal || 0;
            const buTaxDB = BuTaxesSKR.getBuTaxYearPeriod(
                itemBu,
                skr,
                recordDate.date.getFullYear(),
                recordDate.period,
                buTimeframes
            );
            const brutto = state.isNettoMode ? bruttoVal + (bruttoVal * buTaxDB.percent) / 100 : bruttoVal;
            return Number(brutto.toFixed(0));
        };
        const { category, creditor, debitor } = this.getCC(
            itemCategoryCreditor,
            recordDate,
            skr,
            buTimeframes,
            productFormConfig.itemAccountMode,
            productFormConfig.itemAccountResolver,
            selectedRecordGroup
        );
        const brutto = processBrutto(itemBrutto);
        const tag = itemTag instanceof Tag && itemTag !== TAG_EMPTY ? itemTag : undefined;
        const bu = productFormConfig.itemBuResolver ? productFormConfig.itemBuResolver(itemBu) : itemBu;
        const extraData = productFormConfig.itemExtraDataResolver
            ? productFormConfig.itemExtraDataResolver(itemBu)
            : undefined;
        const originalAmount = Number.isFinite(itemOriginalAmount)
            ? processBrutto(Number(itemOriginalAmount.toFixed(0)))
            : undefined;
        return new GenericItem({
            bu,
            brutto,
            originalAmount,
            belegfeld1: (itemBelegfeld1 || "").trim(),
            belegfeld2: (itemBelegfeld2 || "").trim(),
            text: (itemText || "").trim(),
            text2: (itemText2 || "").trim(),
            category,
            creditor,
            debetor: debitor,
            tag,
            extra: extraData,
            USt13b: itemUSt13b,
        });
    }

    static isDate(v: Date) {
        return v instanceof Date && !isNaN(v.getTime());
    }

    static getFormDate(date: Date, periodBound?: number): RecordFormState["recordDate"] {
        return {
            date,
            period: Number.isFinite(periodBound) ? periodBound : date.getMonth() + 1,
        };
    }

    static getFormCurrency(
        v: CurrencyValue
    ): Pick<RecordFormState["editableRecord"], "recordBrutto" | "recordOriginalAmount" | "recordCurrency"> {
        // there was a floating bug discovered once that originalAmount was a string
        const recordOriginalAmount = v.currency ? Number(v.originalAmount) : undefined;
        return {
            recordBrutto: v.amount,
            recordOriginalAmount,
            recordCurrency: v.currency,
        };
    }
    static getUstFromAccountChange(
        account: RecordFormState["editableRecordItem"]["itemCategoryCreditor"]
    ): null | Pick<RecordFormState["editableRecordItem"], "itemUSt13b" | "itemBu"> {
        if (!account) {
            return { itemBu: Bu.Bu.KU, itemUSt13b: null };
        }
        // if it's Creditor or Debitor, set Bu KU then.
        if (account instanceof Creditor) {
            return { itemBu: Bu.Bu.KU, itemUSt13b: null };
        }

        const cat = account;
        if (cat.isAutoBu()) {
            const categoryAutoBu = cat.getAutoBu();
            if (categoryAutoBu !== null) {
                return { itemBu: categoryAutoBu, itemUSt13b: cat.sv13b };
            }
        }
        return null;
    }

    static constructRecord(
        state: Pick<RecordFormState, "editableRecord" | "recordDate">,
        productFormConfig: ProductConfig,
        skr: number,
        buTimeframes: BuTimeframe[],
        recordGroup: string
    ) {
        const {
            recordCategoryCreditor,
            recordFalligkeit,
            recordKey,
            recordNum,
            recordBrutto,
            recordCurrency,
            recordOriginalAmount,
            recordLastschrift,
            recordDocuments,
            recordColor,
            recordJournaled,
            recordDraft,
            recordPriority,
            recordCreatedAt,
            recordReview,
            recordContact,
        } = state.editableRecord;
        const { recordDate } = state;
        const ccData = this.getCC(
            recordCategoryCreditor,
            recordDate,
            skr,
            buTimeframes,
            productFormConfig.recordAccountMode,
            productFormConfig.recordAccountResolver,
            recordGroup
        );
        let { category } = ccData;
        const { creditor, debitor } = ccData;
        const catOverride = this.getAutoRecordCategoryOverride(
            recordDate,
            productFormConfig,
            skr,
            buTimeframes,
            recordGroup
        );
        if (catOverride) {
            category = catOverride;
        }
        let falligkeit = recordFalligkeit;
        if (productFormConfig.useFalligkeit && !this.isDate(falligkeit)) {
            falligkeit = recordDate.date;
        }

        const r = new GenericRecord({
            key: recordKey,
            date: recordDate.date,
            period: recordDate.period,
            num: (recordNum || "").trim() || undefined,
            brutto: recordBrutto,
            currency: recordCurrency || undefined,
            originalAmount: Number.isFinite(recordOriginalAmount) ? recordOriginalAmount : undefined,
            creditor,
            category,
            debetor: debitor,
            lastschrift: recordLastschrift,
            falligkeit,
            documents: recordDocuments,
            color: recordColor,
            review: recordReview,
            partner: recordContact,
            journaled: recordJournaled,
            draft: recordDraft,
            priority: recordPriority,
            createdAt: recordCreatedAt,
        });
        return r;
    }

    protected static getAutoRecordCategoryOverride(
        recordDate: { date: Date; period: number },
        productFormConfig: ProductConfig,
        skr: number,
        buTimeframes: BuTimeframe[],
        recordGroup: string
    ) {
        if (productFormConfig.recordAccountMode === CategoryCreditorModes.RESOLVER) {
            if (productFormConfig.recordAccountResolver) {
                const buTimeframe = BuTaxesSKR.getBuTimeframeYearPeriod(
                    skr,
                    buTimeframes,
                    recordDate.date.getFullYear(),
                    recordDate.period
                );
                const defaultCategories = buTimeframe?.defaultCategories || new Map();
                const staticCat = productFormConfig.recordAccountResolver(
                    recordDate.date.getFullYear(),
                    defaultCategories,
                    recordGroup
                );
                return staticCat;
            } else {
                throw new Error("recordCategoryCreditorMode === CategoryCreditorModes.RESOLVER, but resolver is empty");
            }
        }
        return null;
    }

    static resolveDateChange(
        stateRecordDate: RecordFormState["recordDate"],
        year: number,
        period: number,
        propsData: { date: Date; isUpdating: boolean }
    ): RecordFormState["recordDate"] | undefined {
        const { date: recordDate, period: recordPeriod } = stateRecordDate;
        const { date: propsDate, isUpdating } = propsData;
        const propsRecordDate = isUpdating ? propsDate : undefined;
        if (!Number.isFinite(period)) {
            // covering a case when form is being initialised with "Entire Year option"
            if (!recordDate || year !== recordDate.getFullYear()) {
                const newRecordDate = new Date(year, new Date().getMonth(), new Date().getDate());
                return { date: newRecordDate, period: newRecordDate.getMonth() + 1 };
                // when you change from 01.01 or 31.12 we should switch to the monthly periods
            } else if (!isUpdating) {
                if (recordPeriod === Periods.Period.FirstDayOfYear || recordPeriod === Periods.Period.LastDayOfYear) {
                    return { date: recordDate, period: recordDate.getMonth() + 1 };
                }
            }
            return undefined;
        }

        const { month, day: strictDay } = Periods.getMonthAndDay(period);

        const isPropsDateMatchesCurrentMonthYear = month === new Date().getMonth() && year === new Date().getFullYear();
        // propsRecordDate - we update an existing record
        const isPropsDateMatchesRecord =
            propsRecordDate && month === propsRecordDate.getMonth() && year === propsRecordDate.getFullYear();
        let dateProposal = isPropsDateMatchesCurrentMonthYear ? new Date() : new Date(year, month);
        if (isPropsDateMatchesRecord) {
            dateProposal = propsRecordDate;
        }
        if (strictDay && dateProposal.getDate() !== strictDay) {
            dateProposal.setDate(strictDay);
        }
        if (
            !recordDate ||
            recordDate.getMonth() !== month ||
            recordDate.getFullYear() !== year ||
            recordPeriod !== period ||
            (strictDay && recordDate.getDate() !== strictDay)
        ) {
            return { date: dateProposal, period };
        }
        return undefined;
    }
    static getFormStateDetails = (state: RecordFormState) => {
        const { editableRecord, recordItems } = state;
        const { rest, hasCurrency, restEuro } = RecordFormUtils.getRest(editableRecord, recordItems);
        const isSaveActive = rest === 0 && recordItems?.length > 0;
        return { isSaveActive, hasCurrency, rest, restEuro };
    };
}

export default RecordFormUtils;

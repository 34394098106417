import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { child, DataSnapshot } from "firebase/database";
import { TInvoicesListColumnsConfig, TInvoicesListData, TInvoices, TInvoiceApplyCurrencyRate } from "@inv/types";
import { searchInAllFields, transformServicePeriod } from "@inv/scripts/utils/utils";
import { InvoicesListTableColumns } from "@inv/modules/InvocesListModule/config/tableColumns";
import { InvoicesApi } from "@inv/scripts/api";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { useCollectionListener } from "../../../../scripts/context/hooks/useCollectionListener";
import { refInvoices } from "../../../../scripts/api/firebase/firebaseRootRefs";
import { UserContext } from "../../../../scripts/context/UserProvider";
import { ContactsContext } from "../../../../scripts/context/ContactsContext";
import { Contacts } from "@binale-tech/shared";
import { Contact } from "@binale-tech/shared/lib/contacts";

type TValue = {
    invoicesList?: Partial<TInvoicesListData>[];
    invoiceListColumnsConfig: TInvoicesListColumnsConfig;
    isOpenSettingsColumns: boolean;
    searchValue: string;
    invoicesData?: TInvoices[];
};

type TActionValue = {
    setColumnConfig: (newValue: TInvoicesListColumnsConfig) => void;
    setViewConfig: (newValue: boolean) => void;
    updateCurrencyRate: (arg: Omit<TInvoiceApplyCurrencyRate, "companyId">) => Promise<void>;
    setSearchValue: (newValue: string) => void;
};

const initialValue = {
    invoiceListColumnsConfig: InvoicesListTableColumns.invoicesListInitColumnsConfig,
    isOpenSettingsColumns: false,
    searchValue: "",
};

const initialActionValue = {
    setColumnConfig: () => {},
    setViewConfig: () => {},
    setSearchValue: () => {},
    updateCurrencyRate: () => Promise.resolve(),
};

export const InvoicesListContext = createContext<TValue>(initialValue);
export const InvoicesListControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoicesListContextProvider: FC<TProps> = ({ children }) => {
    const { companyGQL } = useContext(CompanyContext);
    const { fireUser } = useContext(UserContext);
    const { contacts } = useContext(ContactsContext);

    const companyId = companyGQL?.id;
    const userId = fireUser?.uid;
    const mutator = useGqlMutator();

    useEffect(() => {
        InvoicesApi.mutator = mutator;
        InvoicesApi.companyId = companyId;
    }, [companyId, mutator]);

    const [invoiceListColumnsConfig, setInvoiceListColumnsConfig] = useState<TInvoicesListColumnsConfig>(
        initialValue.invoiceListColumnsConfig
    );
    const [isOpenSettingsColumns, setIsOpenSettingsColumns] = useState<boolean>(initialValue.isOpenSettingsColumns);
    const [searchValue, setSearchValue] = useState<string>("");

    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);
    const ref = useMemo(() => child(refInvoices, `${companyId}/${userId}`), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => snap.val(), []);

    const invoicesMap = useCollectionListener(ref, initializer, shouldSkipLoad);
    const invoicesData: TInvoices[] = useMemo(() => Array.from(invoicesMap.state.values()), [invoicesMap]);

    const transformInvoicesData = useCallback(
        (invoices: TInvoices[], contacts: Contact[]): TInvoicesListData[] =>
            invoices.map(invoice => {
                const { lineItems, isTaxIncluded } = invoice;

                const contact = contacts.find(contact => invoice?.contactId === contact.uuid);
                const contactName = contact ? Contacts.getLabelName(contact) : null;

                const lineItemsTotal = (lineItems || []).reduce(
                    (totals, item, index) => {
                        const originalAmount = item.quantity * item.discount;

                        if (isTaxIncluded) {
                            const tax = item.tax / 100;
                            totals.generalTax += originalAmount * tax;
                            totals.generalTaxRate =
                                index === 0
                                    ? item.tax.toString()
                                    : totals.generalTaxRate !== item.tax.toString()
                                      ? "Diverse"
                                      : totals.generalTaxRate;
                        } else {
                            totals.generalTax = null;
                        }

                        totals.originalAmount += originalAmount;
                        return totals;
                    },
                    {
                        originalAmount: 0,
                        generalTax: 0,
                        generalTaxRate: "",
                    }
                );

                const servicePeriodDaysValue = invoice?.servicePeriodDays
                    ? transformServicePeriod(invoice?.servicePeriodDays)
                    : null;
                const servicePeriodMonthsValue = invoice?.servicePeriodMonths
                    ? transformServicePeriod(invoice?.servicePeriodMonths)
                    : null;

                return {
                    id: invoice.id,
                    invoiceDate: invoice?.date,
                    invoiceNumber: "DRAFT",
                    serviceDescription: "N/A",
                    contact: contactName,
                    currency: invoice?.currencyCode,
                    project: "N/A",
                    serviceDate:
                        invoice?.serviceDate ??
                        invoice?.deliveryDate ??
                        servicePeriodDaysValue ??
                        servicePeriodMonthsValue,
                    currencyRate: invoice.currencyRate,
                    isTaxIncluded,
                    lineItemsList: lineItems,
                    ...lineItemsTotal,
                };
            }),
        [invoicesData]
    );

    const invoicesList = useMemo(
        () =>
            transformInvoicesData(invoicesData, contacts).filter(el => {
                if (!searchValue) {
                    return true;
                }
                return searchInAllFields(el, searchValue);
            }),
        [invoicesData, contacts, searchValue]
    );
    const value = { invoicesData, invoicesList, invoiceListColumnsConfig, isOpenSettingsColumns, searchValue };
    const actions = useMemo(
        () => ({
            setColumnConfig: (newValue: TInvoicesListColumnsConfig) => setInvoiceListColumnsConfig(newValue),
            setViewConfig: (newValue: boolean) => setIsOpenSettingsColumns(newValue),
            setSearchValue: (newValue: string) => setSearchValue(newValue),
            updateCurrencyRate: async (arg: Omit<TInvoiceApplyCurrencyRate, "companyId">) => {
                await InvoicesApi.invoiceApplyCurrencyRate(arg);
            },
        }),
        []
    );

    return (
        <InvoicesListContext.Provider value={value}>
            <InvoicesListControlContext.Provider value={actions}>{children}</InvoicesListControlContext.Provider>
        </InvoicesListContext.Provider>
    );
};

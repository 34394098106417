import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Form, Input, InputProps, InputRef, Typography } from "antd";
import { useIntl } from "react-intl";
import { getName } from "i18n-iso-countries";
import { countriesList } from "./countriesList";
import { useApolloClient } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { validateIbanQuery } from "./queries/queries";

// TODO temporary solution
import { electronicFormatIBAN, extractIBAN } from "@banks/scripts/ibantools";
import { bankDataByIBAN } from "bankdata-germany";

interface Props extends Omit<InputProps, "onChange"> {
    onChange?: (value: string) => void;
}

export const getIBANcountryCode = (value: string) =>
    value && countriesList[value.substring(0, 2)] ? value.substring(0, 2) : "";
export const getIBANmaxLength = (value: string): number => countriesList[getIBANcountryCode(value)] || 34;

const transformValue = (value: string) => {
    let newValue = value || "";
    const maxLength = getIBANmaxLength(value);
    newValue = newValue.replaceAll(/[^A-Za-z0-9]/g, "");

    if (newValue.length > maxLength) {
        newValue = newValue.substring(0, maxLength);
    }
    return newValue.toUpperCase();
};

export const useIBANValidation = () => {
    const intl = useIntl();
    const client = useApolloClient();
    return useMemo(() => {
        return {
            countryCode: (value: string) => {
                const countryCode = getIBANcountryCode(value);
                if (value && !countryCode) {
                    return "IBAN country code is wrong";
                }
                return true;
            },
            maxLen: (value: string) => {
                const maxLength = getIBANmaxLength(value);
                if (value && value.length !== maxLength) {
                    return intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: maxLength });
                }
                return true;
            },
            elsterValidation: async (value: string) => {
                const countryCode = getIBANcountryCode(value);
                const maxLength = getIBANmaxLength(value);
                if (value.length === maxLength && countryCode) {
                    const isValid = await client
                        .query<Pick<GQL.IQuery, "validateIban">>({
                            query: validateIbanQuery,
                            variables: { iban: value },
                            fetchPolicy: "network-only",
                        })
                        .then(res => res.data.validateIban)
                        .catch(() => false);
                    if (!isValid) {
                        return "IBAN validation failed";
                    }
                }
                return true;
            },
        };
    }, [intl, client]);
};

interface LabelProps {
    value: string;
}

export const InputIBANLabelAddon: React.FC<LabelProps> = ({ value }) => {
    const intl = useIntl();
    const countryCode = getIBANcountryCode(value);
    const maxLength = getIBANmaxLength(countryCode);
    const valueLength = value?.length || 0;

    return (
        <Typography.Text type="secondary" data-testid="country-addon">
            {countryCode && <span style={{ maxWidth: 100 }}>{getName(countryCode, intl.locale)} | </span>}
            {`${valueLength} / ${maxLength}`}
        </Typography.Text>
    );
};

export const InputIBANLabel = ({ countryCode }: { countryCode: string }) => {
    const intl = useIntl();

    return (
        <span>
            <Typography.Text strong ellipsis>
                IBAN
            </Typography.Text>
            <Typography.Text type="secondary" data-testid="country-addon">
                {countryCode && (
                    <span style={{ marginLeft: 8, maxWidth: 100 }}>{getName(countryCode, intl.locale)}</span>
                )}
            </Typography.Text>
        </span>
    );
};

export const InputIBAN = forwardRef<InputRef, Props>(function InputIBAN({ value, onChange, ...props }, ref) {
    const [innerValue, setInnerValue] = useState(transformValue(value ? value.toString() : ""));

    useEffect(() => {
        const newValue = transformValue(value ? value.toString() : "");
        if (newValue !== value) {
            onChange && onChange(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = transformValue(e.target.value);
        if (innerValue !== newValue) {
            setInnerValue(newValue);
            onChange && onChange(newValue);
        }
    };

    return <Input {...props} value={innerValue} onChange={handleChange} ref={ref} />;
});

interface IProps extends Omit<InputProps, "onChange"> {
    value?: string;
    onChange?: (value: string) => void;
}

export const InputIbanToBanks = forwardRef<InputRef, IProps>(function InputIbanToBanks(
    { value, onChange, ...props },
    ref
) {
    const [innerValue, setInnerValue] = useState(value ? electronicFormatIBAN(value) : "");

    const form = Form.useFormInstance();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        const newValue = electronicFormatIBAN(e.target.value);
        setInnerValue(e.target.value ? electronicFormatIBAN(e.target.value) : "");
        onChange(newValue);
    };

    const handleBlur = () => {
        if (innerValue) {
            form.validateFields(["iban"]);
            const bankData = extractIBAN(innerValue);
            console.log({ bankData });
            if (!bankData.valid) {
                return;
            }
            if (bankData.countryCode === "DE") {
                const dataByDeIban = bankDataByIBAN(innerValue);
                form.setFieldValue("bankName", dataByDeIban?.bankName);
                form.setFieldValue("swiftBic", dataByDeIban?.bic);
            }
            form.setFieldValue("accountNumber", bankData.accountNumber);
            form.setFieldValue("bankId", bankData.bankIdentifier);
            form.validateFields(["accountNumber", "bankId"]);
        }
    };

    return <Input {...props} value={innerValue} onChange={handleChange} onBlur={handleBlur} ref={ref} />;
});

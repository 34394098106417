import React, { FC } from "react";
import { Col, Form, Row } from "antd";
import { ContactSelect } from "@app/components/shared/form/baseComponents/ContactSelect/ContactSelect";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { CountryCodeInput } from "@dms/components/DocumentForm/DocumentInputs/CountryCodeInput";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { useIntl } from "react-intl";
import { TOptions } from "@dms/components/DocumentForm/types";
import { InvoiceContact } from "@inv/components/InvoiceForm/components/InvoiceContact/InvoiceContact";

interface IProps {
    countriesArray: TOptions;
}

export const CustomerInformationBlocks: FC<IProps> = ({ countriesArray }) => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.CUSTOMER_DETAIL })}>
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.CONTACT}>
                        <InvoiceContact placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CONTACT })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.ADDRESS}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.ADDRESS })} />
                    </Form.Item>
                </Col>
                <Col span={17}>
                    <Form.Item name={InvoiceInputs.STREET}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.STREET })} />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item name={InvoiceInputs.HOUSE_NUMBER}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.HOUSE_NUMBER })} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={InvoiceInputs.COUNTRY_CODE}>
                        <CountryCodeInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.COUNTRY_CODE })}
                            options={countriesArray}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item name={InvoiceInputs.CITY}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CITY })} />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item name={InvoiceInputs.ZIP_CODE}>
                        <StringInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.ZIP_CODE })} />
                    </Form.Item>
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};

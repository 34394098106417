import { InvoiceColumns, InvoiceFormInputTranslate } from "@inv/types";

export const InvoiceColumnsTranslate = {
    [InvoiceColumns.POSITION]: InvoiceFormInputTranslate.POSITION,
    [InvoiceColumns.PRODUCTS_SERVICES]: InvoiceFormInputTranslate.PRODUCTS_SERVICES,
    [InvoiceColumns.UNIT]: InvoiceFormInputTranslate.UNIT,
    [InvoiceColumns.QUANTITY]: InvoiceFormInputTranslate.QUANTITY,
    [InvoiceColumns.PRICE]: InvoiceFormInputTranslate.PRICE,
    [InvoiceColumns.DISCOUNT]: InvoiceFormInputTranslate.DISCOUNT,
    [InvoiceColumns.TAX]: InvoiceFormInputTranslate.TAX,
    [InvoiceColumns.TOTAL]: InvoiceFormInputTranslate.TOTAL,
};

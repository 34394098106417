import React, { FC } from "react";
import { AddBankModule, EditBankModule, UploadModule } from "@banks/modules";
import { TransactionEditModule } from "@banks/modules/TransactionEditModule/TransactionEditModule";

export const BankModalList: FC = () => {
    return (
        <>
            <AddBankModule />
            <EditBankModule />
            <UploadModule />
            <TransactionEditModule />
        </>
    );
};

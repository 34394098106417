import { GridOptions } from "ag-grid-community";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";

export const baseOptions: GridOptions = {
    defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        minWidth: 50,
        enableCellChangeFlash: true,
        suppressHeaderFilterButton: true,
        suppressFloatingFilterButton: true,
    },
    suppressCellFocus: true,
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 1000],
    headerHeight: 50,
    autoSizePadding: 20,
    rowHeight: 40,
    rowGroupPanelShow: "onlyWhenGrouping",
    sortingOrder: ["asc", "desc", null],
    rowSelection: {
        mode: "multiRow",
        selectAll: "currentPage",
        headerCheckbox: true,
    },
    selectionColumnDef: {
        width: 50,
        suppressHeaderMenuButton: false,
        pinned: "left",
    },
    suppressAggFuncInHeader: true,
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    debounceVerticalScrollbar: true,
    suppressScrollOnNewData: true,
    rowClassRules: {},
    scrollbarWidth: 8,
    rowBuffer: 50,
    onSortChanged: event => {
        event.api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [RequiredTableCols.ROW_NUMBER],
        });
    },
    onFilterChanged: event => {
        event.api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [RequiredTableCols.ROW_NUMBER],
        });
    },
};

import React, { FC } from "react";
import { GridOptions } from "ag-grid-community";
import { IInvoiceFormLineItem, InvoiceColumns } from "@inv/types";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

interface IProps {
    isTaxIncluded: boolean;
    lineItemsList: IInvoiceFormLineItem[];
}

export const LineItemsModal: FC<IProps> = ({ lineItemsList, isTaxIncluded }) => {
    if (lineItemsList.length === 0) {
        return null;
    }

    const columns = isTaxIncluded
        ? InvoiceFormColumnsConfig.ModalColumnsConfig
        : InvoiceFormColumnsConfig.ModalColumnsConfig.filter(column => column.field !== InvoiceColumns.TAX);

    const gridOption: GridOptions<any> = {
        ...baseOptions,
        pagination: false,
        rowSelection: {
            mode: "multiRow",
            enableClickSelection: false,
            enableSelectionWithoutKeys: false,
            headerCheckbox: false,
            checkboxes: false,
        },
        defaultColDef: {
            enableCellChangeFlash: true,
            filter: false,
            suppressMovable: true,
        },
        selectionColumnDef: null,
    };

    return (
        <AgGridTable
            wrapperStyles={{ width: "100%", minHeight: "60px" }}
            gridOptions={gridOption}
            columnDefs={columns}
            rowData={lineItemsList}
        />
    );
};

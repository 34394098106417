import { useMemo } from "react";

import { DiscountEditor, SelectEditor } from "@inv/modules/InvoiceTableModule/Editors";
import { GridOptions } from "ag-grid-community";
import { InvoiceRowData } from "@inv/types";

import { QuantityEditor } from "@inv/modules/InvoiceTableModule/Editors/QuantityEditor";
import { PriceEditor } from "@inv/modules/InvoiceTableModule/Editors/PriceEditor";
import { NameEditor } from "@inv/modules/InvoiceTableModule/Editors/NameEditor/NameEditor";
import { SettingsColumns } from "@inv/components/InvoiceTableBlock/components/SettingsColumns";

import styles from "../styles/InvoiceTable.module.scss";

export const useConfig = () => {
    return useMemo(
        () => ({
            defaultColDef: {
                width: 100,
                editable: true,
                resizable: true,
                singleClickEdit: true,
                cellClass: [styles.cell, styles.cellEnd],
                enableCellChangeFlash: true,
                suppressMovable: true,
                sortable: false,
                filter: false,
            },
            scrollbarWidth: 8,
            domLayout: "autoHeight",
            rowDragManaged: true,
            cellFlashDuration: 0,
            components: {
                selectEditor: SelectEditor,
                discountEditor: DiscountEditor,
                quantityEditor: QuantityEditor,
                priceEditor: PriceEditor,
                nameEditor: NameEditor,
                settingsColumns: SettingsColumns,
            },
        }),
        []
    ) as GridOptions<InvoiceRowData>;
};

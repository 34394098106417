import React, { FC } from "react";
import { Flex, Typography } from "antd";
import { themeConfig } from "@inv/theme";

interface IProps {
    label: React.ReactNode;
    value: string;
    currencyCode: string;
    isBold: boolean;
}

const { Text } = Typography;

export const TotalItem: FC<IProps> = ({ label, value, currencyCode, isBold }) => (
    <Flex
        align="center"
        justify="space-between"
        style={{
            backgroundColor: isBold ? themeConfig.colorLight : null,
            fontWeight: isBold ? "bold" : "normal",
            width: "100%",
            padding: "5px 10px",
            borderBottom: !isBold ? themeConfig.border : null,
        }}
    >
        <Text>{label}</Text>
        <Text>
            {value} ({currencyCode})
        </Text>
    </Flex>
);

import React, { FC, useCallback, useContext, useMemo } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "@inv/modules/InvocesListModule/config/gridOptions";
import {
    CellValueChangedEvent,
    FilterChangedEvent,
    GridReadyEvent,
    RowClassRules,
    SortChangedEvent,
} from "ag-grid-community";
import { invoicesListTotal } from "@inv/scripts/utils/utils";
import { ITableDocument } from "@dms/types";
import { useColumnConfig } from "@inv/modules/InvocesListModule/hooks/useColumnConfig";
import { ActionList } from "@inv/components/ActionList";
import { ActionControlColumns } from "./components/ActionControlColumns";
import { RateComponent } from "@inv/components/InvoicesList/components/RateComponent";

import styles from "./InvoicesList.module.scss";
import { InvoicesListContext } from "@inv/modules/InvocesListModule/context";
import Container from "@app/components/shared/appearance/page/Container";
import { RateEditor } from "@inv/modules/InvocesListModule/editors";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";

export const InvoicesList: FC = () => {
    const { invoicesList } = useContext(InvoicesListContext);
    const columns = useColumnConfig();

    const components = {
        rateEditor: RateEditor,
        rateComponent: RateComponent,
        actionControlColumns: ActionControlColumns,
        actionList: ActionList,
    };

    const onGridReady = useCallback(({ api }: GridReadyEvent) => {
        const { amountNetto, vat, amountBrutto } = invoicesListTotal(api);
        api.updateGridOptions({
            pinnedBottomRowData: [{ amountNetto, vat, amountBrutto }],
        });
    }, []);

    const onCellValueChanged = useCallback(({ api }: CellValueChangedEvent) => {
        const { amountNetto, vat, amountBrutto } = invoicesListTotal(api);
        api.updateGridOptions({
            pinnedBottomRowData: [{ amountNetto, vat, amountBrutto }],
        });
    }, []);

    const rowClassRules = useMemo<RowClassRules<ITableDocument>>(
        () => ({
            [styles.agTotalRow]: params => Boolean(params.node.rowPinned),
        }),
        []
    );

    return (
        <Container absolute flex>
            {(w, h) => (
                <AgGridTable
                    columnDefs={columns}
                    gridOptions={gridOptions}
                    rowData={invoicesList}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    rowClassRules={rowClassRules}
                    components={components}
                    wrapperStyles={{ height: h }}
                />
            )}
        </Container>
    );
};

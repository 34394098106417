import React, { FC, useContext } from "react";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import { EditInvoiceControlContext } from "@inv/modules/EditInvoiceModule/context";
import { InvoicesListContext } from "@inv/modules/InvocesListModule/context";
import { useParams } from "react-router-dom";
import { TInvoicesValues } from "@inv/types";

export const EditInvoiceModule: FC = () => {
    const { id } = useParams();
    const { invoicesData } = useContext(InvoicesListContext);
    const { updateInvoice } = useContext(EditInvoiceControlContext);

    const initialValue = invoicesData.find(invoice => invoice.id === id);

    const submit = async (inputValues: TInvoicesValues) => {
        await updateInvoice({ ...inputValues, id });
    };

    if (!initialValue) {
        return null;
    }

    return <InvoiceForm initialValues={initialValue as TInvoicesValues} handleSave={submit} />;
};

import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { DocumentCountryCodeField } from "@dms/components/DocumentForm/DocumentFormFields/DocumentCountryCodeField";
import { DocumentUStIdNrField } from "@dms/components/DocumentForm/DocumentFormFields/DocumentUstIdNrField";
import { useEuroCountryArray } from "../../../../scripts/infrastructure/hooks/useEuroCountryArray";
import { FormItemCheckbox } from "@dms/components/DocumentForm/DocumentInputs/FormItemCheckbox";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

export const VatNumberInputs = () => {
    const euroCountriesArray = useEuroCountryArray();
    const { isGroupEditor } = useContext(FormWrapperContext);
    const { isCancel } = useContext(GroupFormContext);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    return (
        <Row>
            <Col span={24}>
                <Row gutter={[10, 15]}>
                    {isGroupEditor ? (
                        <div style={{ position: "relative" }}>
                            <Form.Item
                                initialValue={false}
                                name={"UStIdNrCheckbox"}
                                valuePropName={"checked"}
                                noStyle={true}
                            >
                                <FormItemCheckbox
                                    parentFieldName={"discountDays"}
                                    setIsDisabled={arg => setIsDisabled(arg)}
                                />
                            </Form.Item>
                        </div>
                    ) : null}
                    <Col span={5}>
                        <DocumentCountryCodeField options={euroCountriesArray} disabled={isDisabled} />
                    </Col>
                    <Col span={19}>
                        <DocumentUStIdNrField options={euroCountriesArray} disabled={isDisabled} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

import { IMessagesType } from "./de";

const auth = {
    "app.registration.caption": "Registration",
    "app.registration.description": "Please fill the data to create new account",
    "app.registration.firm": "Firm / Name",
    "app.registration.agb": "AGB",
    "app.registration.agb.agree": "I agreee with {agbLink} and {datenschutzLink}.",
    "app.registration.message.send_verification_email": "Confirmation E-mail sent",
    "app.registration.almost_done": "Almost done",
    "app.registration.almost_done_description":
        "Thanks for signing up for Binale! Please confirm your email adress. Go to the link we have sent you to your email address.",
    "app.registration.send_again": "Send again",
    "app.registration.resend_again_in": "You can resend the email in",

    "app.login.description": "Welcome to Binale",
    "app.login.error.fail": "Wrong E-Mail address or password.",
    "app.login.loose_2fa_app": "Authenticator app unavailable?",
    "app.login.loose_2fa_app_instructions":
        "If your authentication app no longer available, please contact us at {email}",

    "app.reset_password.caption": "Password forgotten?",
    "app.reset_password.description": "Please enter your E-mail address and click 'Send' to reset your password.",
    "app.reset_password.confirmation":
        "We have send a link to reset your password on your E-Mail address. Please check your postbox.",

    "app.new_password.caption": "New password",
    "app.new_password.description": "Enter your new password.",
    "app.new_password.confirmation": "New password was saved.",

    "app.2fa.button.enable2fa": "Enable 2fa",
    "app.2fa.button.showPrivateKey": "Show My Private Key",
    "app.2fa.button.disable2fa": "Disable 2fa",
    "app.2fa.step.title": "Enable Authentificator",
    "app.2fa.input_label": "Enter the 6 digit code from your Authenticator app.",
    "app.2fa.confirm_delete": "Are you sure you want to disable 2fa?",
    "app.2fa.description":
        "With <b>two-factor-authentification</b> you add an additional layer of security to your user account. When logging in, in addition to your password, you use a one-time verification random code that is generated with an authentication app.",
    "app.2fa.enabled2fa_description": "<b>Two-Faktor-Authentification</b> ist enabled for your account!",

    "app.2fa.step.download.title": "Download App",
    "app.2fa.step.download.description": "Download and install one of the Athenticator apps below on your smartphone",

    "app.2fa.step.scan.title": "Scan QR Code",
    "app.2fa.step.scan.description": "Scan this QR code in your app",
    "app.2fa.step.scan.manual_enter":
        "If you are unable to scan the QR code, please enter this code manually into the app.",

    "app.2fa.step.backup.title": "Backup Key",
    "app.2fa.step.backup.description":
        "Please save this Key on paper. This Key will allow you to recover your Authenticator in case of phone loss.",

    "app.2fa.step.enable.title": "Enable authenticator",
    "app.2fa.token_error": "Incorrect 2FA verification code.",
};

const errors = {
    // firebase errors
    "app.firebase.error.auth_requires_recent_login": "To change the password, you need to re-login to the system.",
    "app.firebase.error.auth_user_not_found":
        "There is no user record corresponding to this identifier. The user may have been deleted.",
    "app.firebase.error.auth_too_many_requests": "Too many requests.",
    "app.firebase.error.duplicate_email": "E-mail address is already used.",
    "app.firebase.error.auth_weak_password": "Password is weak",
    "app.firebase.error.auth_invalid_email": "E-mail is invalid",
    "app.firebase.error.auth_invalid_token": "Token is invalid",

    // error pages
    "app.error_page.title.something_went_wrong": "Something doesn't seem to have gone well...",
    "app.error_page.we_already_fix_it": "We'll take care of it as soon as possible. Please refresh the page.",
    "app.error_page.button.reload_page": "Reload",
    "app.error_page.title.problem_with_db": "There seems to be a problem with the connection to the database.",
    "app.error_page.fix_problems_with_db.1":
        'Please check first that you are not in a so-called "private window" of your browser. In this case the data cannot be loaded.',
    "app.error_page.fix_problems_with_db.2": "Please reload the page again.",
    "app.error_page.fix_problems_with_db.3":
        "If the problem persists or occurs repeatedly, please write to us briefly at {email}. We take care of this.",
    "app.error_page.not_found": "This page does not exist.",
    "app.error_page.button.back_home": "Home",

    // validation
    "app.validation.error.field_empty": "The field cannot be empty",
    "app.global.error": "Unexpected error.",
    "app.validation.error.short_password": "Password must be at least {length} symbols",
    "app.validation.error.passwords_not_match": "Passwords do not match",
    "app.validation.error.invalid_email": "E-mail address is not valid",
    "app.validation.error.strictLength": "{length} signs",

    "app.error.message.incomplete_data": "The data is incomplete. Please check the entries",
    "app.error.message.bank_not_available": "The Bank is not available in the selected year",
    "app.error.message.kb_not_available": "The Ledger is not available in the selected year",
    "app.error.message.some_incorrect": "Some entries are incorrect",
};

export const messagesEn: IMessagesType = {
    ...auth,
    ...errors,

    "app.header.workplace": "Working place",
    "app.header.logout": "Log out",
    "app.header.login": "Log in",
    "app.settings.button.columns.text": "Columns",
    "app.settings.button.columns.title": "Select columns",
    "app.companies.view.table.admin": "Main user",
    "app.companies.view.table.user": "User",
    "app.companies.detail_request.select_skr": "Please select SKR of your company",
    "app.companies.detail_request.select_kb": "Create or select the Ledger",
    "app.companies.detail_request.select_bank": "Create or select the Bank",
    "app.companies.permission": "Permission",
    "app.companies.permission.no": "No",
    "app.companies.permission.read": "Audit (Read)",
    "app.companies.permission.write": "Participant (Write)",
    "app.companies.permission.admin": "Admin",
    "app.companies.permission.admin-read": "Admin Read",
    "app.profile.select.title": "Language",
    "app.profile.button.change_password": "Change password",
    "app.profile.button.re_login": "Login",
    "app.profile.email_verification.success_message": "Email confirmed successfully.",
    "app.profile.email_verification.fail_message": "Email verification failure",
    "app.messages.no_elements_found": "No elements found",
    "app.message.no_document_found": "No document found",
    "app.items.create_tag": "Create Tag",
    "app.items.create_creditor": "Create Creditor",
    "app.items.create_debitor": "Create Debitor",
    "app.items.create_category": "Create Account/Category",
    "app.items.create_article": "Create an Article",

    "app.titles.ER": "Purchase Invoices",
    "app.titles.ER_A": "Prepayments",
    "app.titles.Deb": "Outgoing Invoices",
    "app.titles.FE": "Free input",
    "app.titles.POS": "POS accounting",
    "app.titles.KB": "Ledger",
    "app.titles.Bank": "Bank",
    "app.titles.Bank.pl": "Banks",
    "app.titles.Common": "Common",
    "app.titles.SuSa": "Summen und Salden",
    "app.titles.Vat": "VAT Report",
    "app.titles.KB.pl": "Ledgers",
    "app.titles.ER.erfassung": "Compilation",
    "app.titles.ER.rechnungen": "Bills",
    "app.titles.ER.auswertung": "Analysis",
    "app.titles.filtern": "Filters and Search",
    "app.titles.ER.festschreibung": "Festschreibung",
    "app.titles.ER.za": "Remittance Advice",
    "app.titles.Lager": "Warehouse",
    "app.titles.LA": "Pay Slip",
    "app.titles.AVM": "Fixed Assets",
    "app.titles.OCR": "PDF Tool",
    "app.titles.KA": "Account overview",
    "app.titles.Contacts": "Contacts",
    "app.titles.DMS": "Documents",
    "app.titles.DMS_AI": "Documents: Binale AI",
    "app.titles.Banks": "Banks",
    "app.titles.Invoices": "Invoices",
    "app.titles.payments": "Payments",
    "app.titles.templates": "Templates",
    "app.titles.data_export": "Data Export",
    "app.titles.data_import": "Data Import",
    "app.titles.settings": "Settings",
    "app.titles.settings.manage": "Manage",
    "app.titles.settings.formSettings": "Form settings",
    "app.titles.settings.optionalFields": "Optional fields",
    "app.titles.security": "Security",
    "app.titles.company": "Company",
    "app.titles.company.pl": "Companies",
    "app.titles.category": "Category",
    "app.titles.category.pl": "Categories",
    "app.titles.creditor": "Creditor",
    "app.titles.creditor.pl": "Creditors",
    "app.titles.debitor": "Debitor",
    "app.titles.debitor.pl": "Debitors",
    "app.titles.tag": "Tag",
    "app.titles.tag.pl": "Tags",
    "app.titles.article": "Article",
    "app.titles.article.pl": "Articles",
    "app.titles.profile": "Profile",
    "app.titles.kontenverwaltung": "Account management",
    "app.titles.import_export": "Import/Export",
    "app.titles.accounting": "Accounting",
    "app.titles.contactPerson": "Contact Person",
    "app.titles.legalInformation": "Legal Information",
    "app.titles.termsOfPayment": "Terms of payment",
    "app.titles.communication": "Communication",
    "app.titles.contacts.client": "Customer",
    "app.titles.contacts.partner": "Vendor",
    "app.titles.contacts.other": "Other (Free input)",
    "app.titles.contacts.employee": "Employee",
    "app.titles.contacts.shareholder": "Shareholder",
    "app.titles.contacts.bankDetails": "Bank details",
    "app.titles.contacts.classification": "Classification",
    "app.titles.bulkEdit": "Multi-Change",
    "app.titles.reconciliation": "Reconciliation",
    "app.titles.copy_data_from": "Copy new Accounts from",
    "app.titles.bind_to_bank": "Associate to Bank",
    "app.titles.bind_to_kb": "Associate to Ledger",
    "app.titles.unbind": "Remove association",
    "app.titles.create_kb": "Add a Bank",
    "app.titles.update_kb": "Update the Bank",
    "app.titles.create_bank": "Add a Bank",
    "app.titles.update_bank": "Update the Bank",

    "app.button.login": "Log in",
    "app.button.register": "Register",
    "app.button.confirm": "Confirm",
    "app.button.send": "Send",
    "app.button.send_to_dms": "to DMS",
    "app.button.save": "Save",
    "app.button.saveAll": "Save all",
    "app.button.saveSelected": "Save selected",
    "app.button.create": "Create",
    "app.button.add": "Add",
    "app.button.cancel": "Cancel",
    "app.button.confAndClose": "Confirm and close",
    "app.button.close": "Close",
    "app.button.finish": "Finish",
    "app.button.done": "Done",
    "app.button.apply": "Apply",
    "app.button.unselect": "Unselect",
    "app.button.buchen": "Book",
    "app.button.delete": "Delete",
    "app.button.bind": "Associate",
    "app.button.unbind": "Remove association",
    "app.button.storno": "Cancel record",
    "app.button.edit": "Edit",
    "app.button.copy": "Copy",
    "app.button.download": "Download",
    "app.button.select": "Select",
    "app.button.loading": "Loading...",
    "app.button.saving": "Saving...",
    "app.button.color.remove_color": "Remove color",
    "app.button.color.red": "Red",
    "app.button.color.blue": "Blue",
    "app.button.color.purple": "Purple",
    "app.button.color.yellow": "Yellow",
    "app.button.color.green": "Green",
    "app.button.reorganise": "Reorganise",
    "app.button.upload": "Upload",
    "app.button.next": "Next",
    "app.button.back": "Back",
    "app.button.reset": "Reset",
    "app.button.default": "Default",
    "app.button.update": "Update",
    "app.button.openInNewTab": "Open in new Tab",

    "app.confirmation.pdf.finish": "Finish the job?",
    "app.confirmation.record_remove.body": "Do you really want to remove this record?",
    "app.confirmation.invoice_remove.body": "Do you really want to remove this invoice?",
    "app.confirmation.record_cancel.body": "Do you really want to cancel this record?",
    "app.confirmation.festschreiben_edit.body": "This record is journaled! Do you really want to edit this record?",
    "app.kb.journal.no_items_tooltip":
        "No records selected, Saldo for some selected records is negative or previous records are not journaled",
    "app.confirmation.header": "Confirmation",
    "app.confirmation.header.record_delete": "Delete record!",
    "app.confirmation.header.record_cancel": "Cancel record",
    "app.confirmation.header.confirmed_edit": "Edit confirmed record!",
    "app.confirmation.header.festschreiben_edit": "Edit journaled record!",
    "app.confirmation.body": "Please confirm!",
    "app.fields.lfdnr": "#",
    "app.fields.nr": "Nr.",
    "app.fields.date": "Date",
    "app.fields.date.month": "Month",
    "app.fields.date.year": "Year",
    "app.fields.date.erfassung": "Input period",
    "app.fields.creditor": "Creditor",
    "app.fields.debitor": "Debitor",
    "app.fields.creditor_view": "Creditor view",
    "app.fields.konto_view": "Account view",
    "app.fields.bezeichnung": "Name",
    "app.fields.name": "Name",
    "app.fields.contact": "Contact",
    "app.fields.products": "Products",
    "app.fields.rechnung_num": "Invoice Nr.",
    "app.fields.internal_num": "Internal Nr.",
    "app.fields.konto": "Account",
    "app.fields.gkonto": "Out. Account",
    "app.fields.gkonto.full": "Outgoing Konto",
    "app.fields.brutto": "Brutto",
    "app.fields.betrag": "Amount",
    "app.fields.betragType": "Way",
    "app.fields.totalAmount": "Total amount",
    "app.fields.partialAmount": "Partial amount",
    "app.fields.originalAmount": "Original Amount",
    "app.fields.currency.rate": "Rate",
    "app.fields.vat%": "VAT, %",
    "app.fields.vat": "VAT",
    "app.fields.netto": "Netto",
    "app.fields.open": "Open",
    "app.fields.buchtext": "Product/Service",
    "app.fields.buchtext2": "Additional information",
    "app.fields.skonto": "Discount",
    "app.fields.deadlineDays": "Deadline days",
    "app.fields.dueDate": "Due date",
    "app.fields.discountDays": "Discount days",
    "app.fields.discountDate": "Discount date",
    "app.fields.discountPercent": "Discount percent",
    "app.fields.discountAmount": "Discount amount",
    "app.fields.notes": "Notes",
    "app.fields.verrechnung": "Prepayment",
    "app.fields.wählen": "choose",
    "app.fields.rabatt": "Rabatt",
    "app.fields.offner_betrag": "Outstanding Amount",
    "app.fields.lastschrift": "Direct Debit/Lastschrift",
    "app.fields.draft": "Draft",
    "app.fields.uberweisung": "Bank transfer",
    "app.fields.barzahlung": "Cash payment",
    "app.fields.sonstiges": "Other",
    "app.fields.falligkeit": "Due date",
    "app.fields.actions": "Actions",
    "app.fields.saldo": "Saldo",
    "app.fields.payment": "Payment",
    "app.fields.konto_len": "Account length",
    "app.fields.color": "Color",
    "app.fields.user": "User",
    "app.fields.schleppend": "Follow",
    "app.fields.search": "Search",
    "app.fields.address": "Address",
    "app.fields.company_name": "Company name",
    "app.fields.producer": "Producer name",
    "app.fields.description": "Description",
    "app.fields.unit": "Unit",
    "app.fields.taxation": "Taxation of VAT",
    "app.fields.favouritePaymentType": "Main means of payment",
    "app.fields.datevNrConsultant": "Consultant number",
    "app.fields.datevNrCompany": "Client number",
    "app.fields.countryCode": "Country",
    "app.fields.euroCountryCode": "EU-Country",
    "app.fields.postcode": "Postcode",
    "app.fields.city": "Place",
    "app.fields.street": "Street",
    "app.fields.house": "House Nb.",
    "app.fields.additionalAddress": "Additional address",
    "app.fields.vatNb": "VAT-Nb.",
    "app.fields.taxNumber": "Tax Number",
    "app.fields.typeOfRegister": "Type of Register",
    "app.fields.registerCourt": "Register court",
    "app.fields.registerNumber": "Register number",
    "app.fields.13bUStG": "Option § 13b UStG",
    "app.fields.paymentPurpose": "Purpose of payment",
    "app.fields.paymentMethod": "Payment method",
    "app.fields.days": "Days",
    "app.fields.phone": "Phone",
    "app.fields.internalDesignation": "Internal designation",
    "app.fields.type": "Type",
    "app.fields.subType": "Subtype",
    "app.fields.companyName": "Company name",
    "app.fields.legalForm": "Legal form",
    "app.fields.salutation": "Salutation",
    "app.fields.title": "Title",
    "app.fields.firstName": "First name",
    "app.fields.lastName": "Last name",
    "app.fields.customerNumber": "Customer number",
    "app.fields.accountOwner": "Account owner",
    "app.fields.bankCodeNumber": "Bank code number",
    "app.fields.bankAccountNumber": "Bank account number",
    "app.fields.bankName": "Bank name",
    "app.fields.productService": "Product/Service",
    "app.fields.position": "Position",
    "app.fields.department": "Department",
    "app.fields.currency": "Currency",
    "app.fields.comment": "Comment",
    "app.fields.module": "Module",
    "app.fields.attachment": "Attachment",
    "app.fields.invoice": "Invoice",
    "app.fields.creditNote": "Credit note",

    "app.fields.fileUrl": "File URL",
    "app.fields.numPages": "Count pages",
    "app.fields.rowNumber": "No",

    "app.fields.recordReview": "Review",
    "app.fields.recordReview.ok": "OK",
    "app.fields.recordReview.new": "New",
    "app.fields.recordReview.changed": "Changed",
    "app.fields.recordReview.question": "Question",
    "app.fields.recordReview.error": "Error",

    "app.fields.ertrag": "Income",
    "app.fields.aufwand": "Expense",
    "app.fields.einnahme": "Receipt",
    "app.fields.ausgabe": "Spending",

    "app.fields.purpose": "Purpose",
    "app.fields.myIban": "My bank’s IBAN",
    "app.fields.myBankAccount": "My bank account",
    "app.fields.counterpartyName": "Counterparty name",
    "app.fields.counterpartyIbanOrAccountNum": "Counterparty Iban/Account",
    "app.fields.counterpartyBicOrBlz": "Counterparty BIC/BLZ",
    "app.fields.transactionType": "Transaction type",
    "app.fields.openingBalance": "Opening balance",
    "app.fields.openingBalanceDate": "Opening balance date",
    "app.fields.bankTitle": "Internal bank name",

    "app.labels.allYear": "Entire Year",
    "app.labels.paymentConditions": "Payment conditions",

    "app.components.split.title": "Bill separation",
    "app.components.editing": "Editing",
    "app.components.not_selected": "Not selected",
    "app.components.all_selected": "All selected",
    "app.components.nselected": "Selected",
    "app.components.all": "All",
    "app.components.all_months": "All months",
    "app.components.used": "My List",
    "app.components.creating": "Creating",
    "app.components.date.range_error": "Date should be in range",
    "app.components.table.no_items": "No items",
    "app.components.form.attachment": "Attach file",
    "app.components.form.clear": "Clear",

    // "app.cookie":
    // "This website might uses cookies to ensure that we give you the best experience on our website. If you continue we assume that you consent to receive all cookies.",
    "app.notification.update": "We have just released new version. Please reload the page to stay up to date!",
    "app.notification.copiedToClipboard": "Field copied",

    "app.global.email": "E-Mail address",
    "app.global.password": "Password",
    "app.global.password_repeat": "Repeat password",
    "app.global.error": "Unexpected error.",
    "app.global.login_now": "Log in now",
    "app.global.register_now": "Register now",
    "app.global.do_not_have_account": "New client?",
    "app.global.already_have_account": "Already have account?",
    "app.global.not_selected": "Not selected",
    "app.global.total": "Total",
    "app.global.components.payment_connection.created": "Record in Module {module} was automatically created.",
    "app.global.components.payment_connection.updated": "Record in Module {module} was automatically updated.",
    "app.global.components.payment_connection.removed": "Record in Module {module} was removed.",
    "app.global.components.payment_connection.markedRemoved": "Record in Module {module} was marked as removed.",
    "app.global.components.num_corrector.title": "Assignment to the new number",
    "app.global.yes": "Yes",
    "app.global.no": "No",
    "app.global.step": "Step",
    "app.global.previous_step": "Previous Step",
    "app.global.bank": "Bank",
    "app.global.confirmRemoveInfo": "Do you really want to delete this information?",
    "app.global.confirmSentToTrash": "Send to Trash",
    "app.global.confirmRemoveDocument": "Удалить навсегда?",
    "app.global.saveChanges": "Save Changes?",
    "app.global.binaleNeedUpdate": "New Binale version is available. Please update the program!",

    "app.payments.verrechnungen": "Select a prepayment.",
    "app.paymentConnections.record": "Record",
    "app.paymentConnections": "Payment connection",
    "app.paymentConnections.helperText": "There are some records where we could assign this payment",
    "app.paymentConnections.deleteAssignment": "Delete assignment",
    "app.paymentConnections.saveAssignment": "Save assignment",
    "app.paymentConnections.showAllOpenItems": "Show all open items",

    "app.contacts.new": "Create Contact",
    "app.contacts.type.organization": "Organization",
    "app.contacts.type.individual": "Natural Person",
    "app.contacts.type.institution": "Institution",
    "app.contacts.add": "Add an adress",
    "app.contacts.confirmRemoveContact": "Do you really want to delete this conctact?",

    "app.message.progress": "Action in progress...",
    "app.message.success": "Action successfully completed",
    "app.message.userSelectCompany": "Active company was switched",
    "app.message.companyCreateBank": "Bank was created",
    "app.message.companyCreateKB": "Ledger (Kasse) was created",
    "app.message.companyUpdateBank": "Bank was updated",
    "app.message.companyUpdateKB": "Ledger (Kasse) was updated",
    "app.message.companyDeleteBank": "Bank was removed",
    "app.message.companyDeleteKB": "Ledger (Kasse) was removed",
    "app.message.companySetProducts": "Company products were updated",
    "app.message.companyCreate": "Company was created",
    "app.message.companyUpdate": "Company was updated",
    "app.message.companyDelete": "Company was removed",
    "app.message.companySaveMember": "Company member was saved",
    "app.message.companyDeleteMember": "Company member was removed",
    "app.message.nothingSelected": "Please select the type of contact ({ relations }) in the “General“ selection.",

    "app.tooltip.manageFavouritePaymentType": "Manage favourite payment type",
    "app.tooltip.noOpenItems": "no open items",
    "app.tooltip.form.correct": "Please correct {amount} {currency}",

    "app.ocr.button.checkAll": "check all",
    "app.ocr.button.deleteEmptyPages": "delete empty pages",
    "app.ocr.button.hideDeletedPages": "hide deleted pages",
    "app.ocr.button.showDeletedPages": "show deleted pages",
    "app.ocr.button.deletePage": "delete page",
    "app.ocr.button.restorePage": "restore page",
    "app.ocr.button.rotatePage": "rotate",
    "app.ocr.button.splitDocs": "split docs",
    "app.ocr.button.unifyDocs": "unify docs",
    "app.ocr.button.uncheckAll": "uncheck all",
    "app.ocr.button.addDocument": "add document",
    "app.ocr.button.selectDocument": "Select document",

    "app.ocr.parking": "Parking",
    "app.ocr.page": "Page",
    "app.ocr.document": "Document",
    "app.ocr.allPages": "All pages",
    "app.ocr.totalPages": "Total pages",
    "app.ocr.documentsNumber": "Number of documents",
    "app.ocr.selectedPages": "Selected pages",

    "app.ocr.mode.put": "Put together",
    "app.ocr.mode.qr": "Separate (QR-Code)",
    "app.ocr.mode.every": "Separate (every Page)",
    "app.ocr.mode.second": "Separate (every second Page)",
    "app.ocr.mode.manual": "Manual processing",

    "app.ocr.error.encrypted": "The selected document is encrypted or protected and cannot be processed.",
    "app.ocr.dragDocument": "Drag the required document here",
    "app.ocr.placeDocumentHere": "Place document here",

    "app.titles.taxes": "Taxes",
    "app.titles.contracts": "Contracts",
    "app.titles.uncategorized": "Uncategorized",

    "app.dms.add_documents": "Add Documents",
    "app.titles.new_documents": "New documents",

    "app.dms.typeOfDocuments": "Folder",
    "app.titles.trash": "Deleted documents",
    "app.dms.add_subtype": "add item",

    "app.dms.list": "LIST",
    "app.dms.grid": "Cards",

    "app.dms.empty": "Empty",
    "app.dms.fileName": "File name",
    "app.dms.documentNumber": "Document number",
    "app.dms.documentDate": "Date of documents",
    "app.dms.userName": "User name",
    "app.dms.fileInformation": "File information",
    "app.dms.createdBy": "Created by",
    "app.dms.createdAt": "Created at",
    "app.dms.updatedAt": "Updated at",
    "app.dms.status": "Status",
    "app.dms.closeEditor": "Close Editor",
    "app.dms.total": "Total",
    "app.dms.totalDocuments": "{count, plural, one {# document} other {# documents}}",

    "app.dms.selectAll": "Select all",
    "app.dms.copyId": "Copy ID",
    "app.dms.copyUrl": "Copy URL",
    "app.dms.idCopied": "ID copied",
    "app.dms.idCopyFailed": "ID copy failed",
    "app.dms.urlCopied": "URL copied",
    "app.dms.clickOrDrug": "Click or drag file (up to 20M) to this area to upload",
    "app.dms.supportFor": "Multiple selection is possible",
    "app.dms.identicalFiles": "Identical files. Select one file to upload",
    "app.dms.confirmResolving": "Confirm resolving",
    "app.dms.deleteAll": "Delete all",
    "app.dms.view": "View",
    "app.dms.moveTo": "Move to",
    "app.dms.emptyDate": "Without date",

    "app.dms.complete": "Complete",
    "app.dms.allDocuments": "All documents",
    "app.dms.changeModeEnabled": "Change mode enabled",
    "app.dms.noticeOfDelete": "Notice of delete mode",
    "app.dms.cantDelete": "You can't delete this document from bucket",
    "app.dms.moveSelectedDocuments": "Move selected documents to preferred type!",
    "app.dms.pages": "{count, plural, one {# page} other {# pages}}",
    "app.dms.mainTypes": "Main types",
    "app.dms.tooltipDocumentNumber": "Please use the following characters",
    "app.dms.letters": "Letters",
    "app.dms.number": "Number",
    "app.dms.specialSymbols": "Special symbols",

    "app.dms.vat": "VAT",
    "app.dms.corporation_tax": "Corporate income tax",
    "app.dms.business_tax": "Trade tax",
    "app.dms.statement": "Separate and uniform determination",
    "app.dms.income_tax": "Income tax",
    "app.dms.other_taxes": "Other taxes",
    "app.dms.tax_office": "Tax office",
    "app.dms.more": "More",
    "app.dms.isAttached": "booked",
    "app.dms.isn'tAttached": "not booked",
    "app.dms.showAll": "Show all",
    "app.dms.filterActive": "filter is active",
    "app.dms.selectBank": "select a bank",
    "app.dms.selectCashJournal": "select a cash journal",
    "app.dms.selectTaxes": "select a tax",
    "app.dms.noInternetConnection": "No internet connection",
    "app.dms.addDocumentId": "Add document-ID",
    "app.dms.selectInDms": "Select in DMS",
    "app.dms.noAssignment": "No assignment",
    "app.dms.filterColor": "Filter color",

    "app.invoice.createInvoice": "Create invoice",
    "app.invoice.invoicesList": "Invoices list",

    "app.invoice.brutto": "with VAT",
    "app.invoice.netto": "without VAT",
    "app.invoice.contactDetails": "Contact Details",
    "app.invoice.contact": "Search or enter contact",
    "app.invoice.address": "Address",
    "app.invoice.street": "Street",
    "app.invoice.houseNumber": "House number",
    "app.invoice.zeep": "Post code",
    "app.invoice.city": "City",
    "app.invoice.countryCode": "Country",

    "app.invoice.invoiceDetails": "Invoice details",
    "app.invoice.invoiceNum": "Invoice number",
    "app.invoice.customerNum": "Customer number",
    "app.invoice.date": "Date",
    "app.invoice.deliveryDate": "Delivery date",
    "app.invoice.serviceDate": "Service date",
    "app.invoice.servicePeriodDays": "Service period(Days)",
    "app.invoice.servicePeriodMonths": "Service period(Months)",
    "app.invoice.noShowDate": "Not show delivery date",

    "app.invoice.head": "Header section",
    "app.invoice.invoiceTitle": "Invoice title",
    "app.invoice.introductionText": "Introduction text",

    "app.invoice.lineItems": "Line items",
    "app.invoice.viewer": "Invoice viewer",
    "app.invoice.position": "Position",
    "app.invoice.productService": "Product / Service",
    "app.invoice.quantity": "Quantity",
    "app.invoice.price": "Price",
    "app.invoice.unit": "Unit",
    "app.invoice.tax": "VAT",
    "app.invoice.discount": "Discount",
    "app.invoice.total": "Total",
    "app.invoice.newLine": "New line",
    "app.invoice.subTotalNet": "Subtotal Net",
    "app.invoice.piece": "Piece",
    "app.invoice.hour": "Hour",
    "app.invoice.vat": "USt",
    "app.invoice.totalAmount": "Total amount",

    "app.invoice.foot": "Footer section",
    "app.invoice.paymentTerm": "Payment term",
    "app.invoice.description": "Description",

    "app.invoice.caching": "Caching",
    "app.invoice.goToPreview": "Go to preview",

    "app.invoiceList.invoiceDate": "Invoice date",
    "app.invoiceList.serviceDescription": "Service description",
    "app.invoiceList.project": "Project",
    "app.invoiceList.amountNetto": "Amount Netto (EUR)",
    "app.invoiceList.amountBrutto": "Amount Brutto (EUR)",

    "app.banks.allBanks": "All Banks",
    "app.banks.myBank": "My Bank",
    "app.banks.transactionInfo": "Transaction Info",
    "app.banks.counterparty": "Counterparty",
    "app.banks.newBank": "New Bank",
    "app.banks.editBank": "Edit Bank",
    "app.banks.editTransaction": "Edit transaction",
    "app.banks.uploadTransactions": "Upload your bank transaction file",
    "app.banks.unmapped": "Unmapped",
    "app.banks.selectDataField": "Select data field",
};

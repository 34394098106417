import React, { FC, useContext, useState } from "react";
import { Button, Col, Divider, Flex, Form, Row, Space, Spin, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { ITransaction, ITransactionUpdateInput } from "@banks/types";
import { BanksAppControlContext } from "@banks/scripts/context";

import styles from "./TransactionEditForm.module.scss";
import { TransactionEditControlContext } from "@banks/modules/TransactionEditModule/context";
import { TransactionInterneNumberField } from "@banks/components/FormFields/TransactionInterneNumberField";
import { TransactionRechnungNumberField } from "@banks/components/FormFields/TransactionRechnungNumberField";
import { Info2Field, InfoField } from "@banks/components/FormFields/InfoField";
import { TransactionContactField } from "@banks/components/FormFields/TransactionContactField";
import { Utils } from "@binale-tech/shared";

const { Text } = Typography;

type TProps = {
    transaction: Partial<ITransaction> & {
        contact: {
            name?: string;
            id: string;
        };
    };
};

export const TransactionEditForm: FC<TProps> = ({ transaction }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const { setEditTransactionData } = useContext(BanksAppControlContext);
    const { updateTransaction } = useContext(TransactionEditControlContext);
    const [form] = Form.useForm();

    const handleFinish = async (
        inputValues: ITransactionUpdateInput & {
            contact: {
                name: string;
                id: string;
            };
        }
    ) => {
        setIsLoading(true);
        try {
            const res = await updateTransaction({
                ...inputValues,
                companyId: transaction.companyId,
                id: transaction.id,
            });
            if (res) {
                setSuccess(true);
            } else {
                setIsError(true);
            }
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);

            setTimeout(() => {
                setEditTransactionData({ transactionData: null, isModalOpen: false });
            }, 500);
        }
    };

    return (
        <>
            <Flex
                vertical
                style={{
                    margin: "0 0 20px",
                    padding: "10px",
                    width: "100%",
                    backgroundColor: "#f0f0f0",
                    borderRadius: 10,
                }}
                gap={5}
            >
                <Space>
                    <Text strong>
                        <FormattedMessage id={"app.fields.date"} />:
                    </Text>
                    <Text>{transaction?.bookingDate}</Text>
                </Space>
                <Space>
                    <Text strong>
                        <FormattedMessage id={"app.fields.betrag"} />:
                    </Text>
                    <Space size={1}>
                        <Text>{Utils.CurrencyUtils.currencyFormat(transaction?.amount)}</Text>{" "}
                        <Text>{transaction?.currencyCode}</Text>
                    </Space>
                </Space>

                <Space>
                    <Text strong>
                        <FormattedMessage id={"app.fields.purpose"} />:
                    </Text>
                    <Text>{transaction?.purpose}</Text>
                </Space>
            </Flex>
            <Form
                key={"transactionEditForm"}
                form={form}
                layout="vertical"
                name={"transactions-edit-form"}
                autoComplete={"off"}
                onFinish={handleFinish}
                initialValues={transaction}
                clearOnDestroy
            >
                <Row gutter={[10, 10]} className={styles.bankFormRow}>
                    <Col span={12}>
                        <TransactionContactField />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className={styles.bankFormRow}>
                    <Col span={12}>
                        <TransactionRechnungNumberField />
                    </Col>
                    <Col span={12}>
                        <TransactionInterneNumberField />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className={styles.bankFormRow}>
                    <Col span={24}>
                        <InfoField />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className={styles.bankFormRow}>
                    <Col span={24}>
                        <Info2Field />
                    </Col>
                </Row>

                <Divider />
                <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                    {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                    {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                    {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                    <Button id={"addBankConfirm"} type={"primary"} onClick={() => form.submit()}>
                        <FormattedMessage id="app.button.confirm" />
                    </Button>
                    <Button id={"addBankReset"} icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
                        <FormattedMessage id="app.button.reset" />
                    </Button>
                    <Button onClick={() => setEditTransactionData({ transactionData: null, isModalOpen: false })}>
                        <FormattedMessage id="app.button.cancel" />
                    </Button>
                </Space>
            </Form>
        </>
    );
};

import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { FieldLabel } from "../../../../appearance/components/shared/form/baseComponents/FieldLabel/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

const INPUT_MAX_LENGTH = 60;

const SingleDescriptionField: FC = () => {
    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    {<FormattedMessage id="app.fields.description" />}
                </Text>
            }
            name={"description"}
        >
            <StringInput maxLength={INPUT_MAX_LENGTH} showCount />
        </FieldLabel>
    );
};

const GroupDescriptionField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField?.includes("description") ? "Diverse" : undefined;

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"descriptionCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"description"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        {<FormattedMessage id="app.fields.description" />}
                    </Text>
                }
                name={"description"}
            >
                <StringInput placeholder={placeholder} disabled={isDisabled} maxLength={INPUT_MAX_LENGTH} showCount />
            </FieldLabel>
        </>
    );
};

export const DocumentDescriptionField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupDescriptionField /> : <SingleDescriptionField />}</>;
};

import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Flex } from "antd";
import { TransactionsTable } from "@banks/components";
import { BanksAppContext } from "@banks/scripts/context";
import { BanksContext } from "scripts/context/BanksContext";
import { BankTransactionTableColumns } from "@banks/modules/BankTransactionsModule/bankTransactionTableColumns";
import { gridOptions } from "@banks/modules/BankTransactionsModule/gridOptions";
import { useParams } from "react-router-dom";
import { BankTransactionsToolbar } from "@banks/modules/BankTransactionsModule/components/BankTransactionsToolbar";
import dayjs, { extend as dayjsExtend } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { GridApi, GridReadyEvent, IRowNode } from "ag-grid-community";
import { getDateFormat } from "@banks/scripts/helpers";
import Container from "@app/components/shared/appearance/page/Container";
import { ITransaction } from "@banks/types";

const searchingFields = [
    "bookingDate",
    "amount",
    "belegfeld1",
    "belegfeld2",
    "counterpartyBicOrBlz",
    "counterpartyIbanOrAccountNum",
    "currencyCode",
    "purpose",
    "text",
    "text2",
    "transactionType",
] as const;

export const BankTransactionsModule: FC = () => {
    const { banksData, transactionsData } = useContext(BanksContext);
    const { activeBankId } = useContext(BanksAppContext);
    const [searchText, setSearchText] = useState("");
    const [gridApi, setGridApi] = useState<GridApi>(null);

    const { id } = useParams();

    const tableRef = useRef(null);

    const { transactionBank, bankTransactions } = useMemo(() => {
        const bankId = activeBankId ?? id;
        const bankData = banksData[bankId];
        const transactionsObj = transactionsData[bankId];

        dayjsExtend(isSameOrAfter);
        const transactionsArr = Object.values(transactionsObj || {}).sort((a, b) => {
            return (
                dayjs(a.bookingDate, getDateFormat(a.bookingDate)).unix() -
                dayjs(b.bookingDate, getDateFormat(b.bookingDate)).unix()
            );
        });

        return { bankTransactions: transactionsArr, transactionBank: bankData };
    }, [activeBankId, banksData, id, transactionsData]);

    const { reducedBankTransactions, balance } = useMemo(() => {
        let balanceCount = transactionBank.openingBalance ?? 0;
        return {
            reducedBankTransactions: bankTransactions.map(tr => {
                const dynamicBalance = balanceCount + tr.amount;
                balanceCount = dynamicBalance;
                return {
                    ...tr,
                    dynamicBalance,
                };
            }),
            balance: balanceCount,
        };
    }, [bankTransactions, transactionBank]);

    const onGridReady = useCallback(({ api }: GridReadyEvent<any>) => {
        setGridApi(api);
    }, []);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        gridApi.updateGridOptions({
            rowData: reducedBankTransactions,
        });
    }, [gridApi, reducedBankTransactions]);

    const handleSearch = useCallback((text: string) => {
        setSearchText(text);
        tableRef?.current?.api.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback((): boolean => {
        return !!searchText.length;
    }, [searchText]);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode<ITransaction>): boolean => {
            if (node.data) {
                let isIncludes = false;
                for (const key of searchingFields) {
                    if (isIncludes) {
                        break;
                    }
                    isIncludes = String(node.data[key] || "").includes(searchText);
                }
                return isIncludes;
            }
            return false;
        },
        [searchText]
    );

    return (
        <Flex vertical gap={10} style={{ height: "100%" }}>
            <BankTransactionsToolbar
                transactionBank={transactionBank}
                balance={balance}
                balanceDate={bankTransactions[bankTransactions.length - 1]?.bookingDate}
                handleSearch={handleSearch}
            />
            <Container absolute flex>
                {(w, h) => (
                    <TransactionsTable
                        // rowData we will get from gridApi.updateGridOptions here
                        ref={tableRef}
                        gridOptions={gridOptions}
                        tableColumns={BankTransactionTableColumns.tableColumnConfig}
                        viewHeight={h}
                        onGridReady={onGridReady}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                    />
                )}
            </Container>
        </Flex>
    );
};

import React, { FC, useContext } from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import styles from "./TableActions.module.scss";
import { BanksAppControlContext } from "@banks/scripts/context";

interface IProps {
    transactionId?: string;
    bankId?: string;
}

export const TableActions: FC<IProps> = ({ transactionId, bankId }) => {
    const { setEditTransactionData } = useContext(BanksAppControlContext);
    const transactionData = transactionId && bankId ? { transactionId, bankId } : null;

    return (
        <Button
            icon={<FormOutlined />}
            style={{ padding: 0 }}
            className={styles.actionsItem}
            type="link"
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                setEditTransactionData({
                    transactionData,
                    isModalOpen: true,
                });
            }}
        />
    );
};

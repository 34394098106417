import React, { FC, useContext } from "react";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";

import { RecordFormStateContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { RechnungInputBlock } from "@binale-tech/ui-components";
import { useIsItemFieldDisabledOutsideModal } from "../hooks/useIsItemFieldDisabledOutsideModal";
import { useCheckAutoPaymentConnection } from "../hooks/useCheckAutoPaymentConnection";
import { useFormHandlerItemBF1 } from "../hooks/handlers/useFormHandlerItemBF1";

type Props = {
    isModalMode?: boolean;
};
export const FormItemBelegfeld1: FC<Props> = ({ isModalMode }) => {
    const isModalOnly = useIsItemFieldDisabledOutsideModal(isModalMode);
    const { refsHtml } = useContext(RecordFormPropsContext);
    const isDisabled = useFormIsRefDisabled();
    const { editableRecordItem, itemValidationStates } = useContext(RecordFormStateContext);
    const onItemBelegfeld1Change = useFormHandlerItemBF1();
    const checkAutoBinding = useCheckAutoPaymentConnection();

    const validation = itemValidationStates.get(refsHtml.REF_iBELEGFELD1);
    return (
        <RechnungInputBlock
            ref={refsHtml.REF_iBELEGFELD1}
            value={isModalOnly ? undefined : editableRecordItem.itemBelegfeld1}
            onChange={onItemBelegfeld1Change}
            labelProps={{ fieldError: validation ? { type: "error", message: " " } : undefined }}
            className="InputBlock__ShortBlock"
            disabled={isModalOnly || isDisabled(refsHtml.REF_iBELEGFELD1)}
            onBlur={checkAutoBinding}
        />
    );
};

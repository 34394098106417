import { AgGridReact } from "ag-grid-react";
import { Button, Flex, Form } from "antd";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import {
    CellValueChangedEvent,
    GridApi,
    GridReadyEvent,
    RowDataUpdatedEvent,
    RowDragEndEvent,
} from "ag-grid-community";
import {
    InvoiceTableContext,
    InvoiceTableControlContext,
} from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { IInvoiceFormLineItem, InvoiceColumns, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { useConfig } from "@inv/modules/InvoiceTableModule/config";
import { FormattedMessage } from "react-intl";
import { useColumnConfig } from "@inv/modules/InvoiceTableModule/hooks/useColumnConfig";
import { InvoiceTotal } from "@inv/components/InvoiceTableBlock/components/InvoiceTotal";
import { InvoiceFormContext } from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";

export const InvoiceTableBlock: FC = () => {
    const gridApi = useRef<AgGridReact>();
    const gridOptions = useConfig();
    const form = Form.useFormInstance();
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const { initRow } = useContext(InvoiceFormContext);
    const { totalState } = useContext(InvoiceTableContext);
    const { calculateTotal } = useContext(InvoiceTableControlContext);
    const { currencyCode, lineItems } = form.getFieldsValue();

    const columns = useColumnConfig();

    const onAddRow = useCallback(() => {
        if (!gridApi.current) {
            return;
        }
        gridApi.current.api.applyTransaction({ add: [{ ...initRow }] });
    }, [gridApi.current]);

    const sendLineItems = (api: GridApi<IInvoiceFormLineItem>) => {
        const nodes = api.getRenderedNodes();
        const newLineItems = nodes.map(node => {
            const { productsServices, unit, quantity, price, tax, discount } = node.data;
            return { productsServices, unit, quantity, price, tax, discount };
        });
        form.setFieldValue(InvoiceInputs.LINE_ITEMS, newLineItems);
    };

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<IInvoiceFormLineItem>) => {
            api.applyTransaction({ add: lineItems });
            calculateTotal(lineItems);
            sendLineItems(api);
        },
        [lineItems]
    );

    const onCellValueChanged = useCallback(
        ({ data, node, colDef, api }: CellValueChangedEvent<IInvoiceFormLineItem>) => {
            const { discount, quantity } = data;
            if (colDef.field === "discount" || colDef.field === "price" || colDef.field === "quantity") {
                const total = quantity * discount;
                node.setDataValue(InvoiceColumns.TOTAL, total);
            }

            const updatedRows = api.getRenderedNodes().map(row => row.data);
            calculateTotal(updatedRows);
            sendLineItems(api);
        },
        [lineItems]
    );

    const onRowDataUpdated = useCallback(
        (params: RowDataUpdatedEvent<IInvoiceFormLineItem>) => {
            const { api } = params;

            api.forEachNode(node => {
                const { discount, quantity } = node.data;
                const total = quantity * discount;
                node.setDataValue(InvoiceColumns.TOTAL, total);
            });

            const updatedRows = api.getRenderedNodes().map(row => row.data);
            calculateTotal(updatedRows);

            api.refreshCells({
                force: true,
                suppressFlash: true,
                columns: ["position"],
            });

            sendLineItems(api);
        },
        [lineItems]
    );

    const onRowDragEnd = useCallback(({ api }: RowDragEndEvent<IInvoiceFormLineItem>) => {
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [InvoiceColumns.POSITION],
        });
    }, []);

    useEffect(() => {
        const api = gridApi.current?.api;
        if (!api) {
            return;
        }
        const rowsList = api.getRenderedNodes().map(row => row.data);
        calculateTotal(rowsList);
    }, [invoiceTotalView]);

    return (
        <Flex vertical={true} gap={20} style={{ width: "100%", paddingBottom: 20 }}>
            <AgGridTable
                ref={gridApi}
                wrapperStyles={{ width: "100%", minHeight: "60px" }}
                gridOptions={gridOptions}
                columnDefs={columns}
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                onRowDataUpdated={onRowDataUpdated}
                onRowDragEnd={onRowDragEnd}
            />

            <Flex align="start" justify="space-between" style={{ width: "100%" }}>
                <Button type="dashed" icon={<PlusOutlined />} onClick={onAddRow}>
                    <FormattedMessage id={InvoiceFormInputTranslate.NEW_LINE} />
                </Button>
                {totalState ? <InvoiceTotal currencyCode={currencyCode} /> : null}
            </Flex>
        </Flex>
    );
};

import React, { FC, useContext, useState } from "react";
import { Button, Col, Divider, Form, Row, Space, Spin } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { IBank, IBankCreateInput } from "@banks/types";
import { AddBankControlContext } from "@banks/modules/AddBankModule/context/AddBankContext";
import { BanksAppControlContext } from "@banks/scripts/context";
import {
    AccountNumberField,
    BankIdField,
    BankNameField,
    BankTitleField,
    IbanField,
    SwiftBicField,
    BankCurrencyField,
} from "@banks/components/FormFields";

import styles from "./AddBankForm.module.scss";
import { ColorField } from "@banks/components/FormFields/ColorField";
import { OpeningBalanceField } from "@banks/components/FormFields/OpeningBalanceField";
import { OpeningBalanceDateField } from "@banks/components/FormFields/OpeningBalanceDateField";

type TProps = {
    initialValue?: Partial<IBank>;
};

export const AddBankForm: FC<TProps> = ({ initialValue }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const { setAddBankData } = useContext(BanksAppControlContext);
    const { createBank } = useContext(AddBankControlContext);
    const [form] = Form.useForm();

    const ibanValue: string = Form.useWatch(["iban"], form);

    const handleFinish = async (inputValues: IBankCreateInput) => {
        setIsLoading(true);
        try {
            await createBank(inputValues);
            setSuccess(true);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);

            setTimeout(() => {
                setAddBankData({ isModalOpen: false });
            }, 500);
        }
    };

    return (
        <Form
            key={"addBankForm"}
            form={form}
            layout="vertical"
            name={"add-bank-form"}
            autoComplete={"off"}
            onFinish={handleFinish}
            initialValues={initialValue}
            clearOnDestroy
        >
            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={14}>
                    <IbanField value={ibanValue} />
                </Col>

                <Col span={10}>
                    <SwiftBicField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={8}>
                    <BankIdField />
                </Col>

                <Col span={16}>
                    <AccountNumberField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <BankNameField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <BankTitleField />
                </Col>
            </Row>
            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={10}>
                    <OpeningBalanceField />
                </Col>
                <Col span={7}>
                    <OpeningBalanceDateField />
                </Col>
                <Col span={7}>
                    <BankCurrencyField />
                </Col>
            </Row>
            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <ColorField />
                </Col>
            </Row>

            <Divider />
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <Button id={"addBankConfirm"} type={"primary"} onClick={() => form.submit()}>
                    <FormattedMessage id="app.button.confirm" />
                </Button>
                <Button id={"addBankReset"} icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
                    <FormattedMessage id="app.button.reset" />
                </Button>
                <Button onClick={() => setAddBankData({ isModalOpen: false })}>
                    <FormattedMessage id="app.button.cancel" />
                </Button>
            </Space>
        </Form>
    );
};

import { Form, Typography } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { DiscountCurrencyInput } from "../DocumentInputs/DiscountCurrencyInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

export const SingleDiscountAmountField: FC = () => {
    const { formatMessage } = useIntl();
    const ctx = Form.useFormInstance();
    const { code } = ctx.getFieldValue("currencyData")?.currency || {};

    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.discountAmount" />
                </Text>
            }
            name={"discountAmount"}
        >
            <DiscountCurrencyInput suffix={code} placeholder={formatMessage({ id: "app.fields.discountAmount" })} />
        </FieldLabel>
    );
};

const GroupDiscountAmountField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const { notEqualField, isCancel, requiredFields } = useContext(GroupFormContext);
    const ctx = Form.useFormInstance();

    const { code } = ctx.getFieldValue("currencyData")?.currency || {};
    const placeholder = notEqualField?.includes("discountAmount") ? "Diverse" : undefined;

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item
                    initialValue={false}
                    name={"discountAmountCheckbox"}
                    valuePropName={"checked"}
                    noStyle={true}
                >
                    <FormItemCheckbox parentFieldName={"discountAmount"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        <FormattedMessage id="app.fields.discountAmount" />
                    </Text>
                }
                rules={[{ required: requiredFields?.includes("discountAmount"), message: "" }]}
                name={"discountAmount"}
            >
                <DiscountCurrencyInput suffix={code} placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </>
    );
};

export const DocumentDiscountAmountField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupDiscountAmountField /> : <SingleDiscountAmountField />}</>;
};

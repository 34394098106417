import React, { PropsWithChildren, useContext, useEffect } from "react";
import RecordFormUtils from "./utils/RecordFormUtils";
import { PaymentBindingUtils } from "../../../scripts/models/utils/PaymentBindingUtils";
import { RecordFormStateContext, RecordFormStateControlContext } from "./context/RecordFormState";
import { FormExternalRef } from "./types/ref";
import {
    focusFirstFormField,
    focusNextElement,
    focusRef,
    FormClassName,
} from "../../../scripts/infrastructure/helpers/focus";
import { useIsMounted } from "../../../scripts/infrastructure/hooks";
import { useFormRecordLifecycle } from "./hooks/useFormRecordLifecycle";
import { useFormConfig } from "./hooks/useFormConfig";
import { RecordFormPropsContext } from "./context/RecordFormPropsContext";

import { useFormHandlerItemTag } from "./hooks/handlers/useFormHandlerItemTag";
import { useFormHandlerRecordAccount } from "./hooks/handlers/useFormHandlerRecordAccount";
import { useFormHandlerItemAccount } from "./hooks/handlers/useFormHandlerItemAccount";

export const GenericRecordFormLifecycle = React.forwardRef<FormExternalRef, PropsWithChildren>(({ children }, ref) => {
    const isMounted = useIsMounted();
    const { isUpdating, record, periodBound, yearBound, refsHtml, refsData, isTemplate } =
        useContext(RecordFormPropsContext);
    const productFormConfig = useFormConfig();

    const { setRecordDate, setSelectedPayment } = useContext(RecordFormStateControlContext);

    const onTagChange = useFormHandlerItemTag();
    const onRecordCategoryCreditorChange = useFormHandlerRecordAccount();
    const onItemCategoryCreditorChange = useFormHandlerItemAccount();

    const { receiveRecord, onClearForm } = useFormRecordLifecycle();

    // componentDidMount
    useEffect(() => {
        if (isMounted()) {
            focusRef(refsHtml.REF_rDATE);
        }
    }, [refsHtml.REF_rDATE, isMounted]);

    // componentWillReceiveProps
    // receiveDate
    // reacts on changes: record+isUpdating, yearBound, periodBound
    useEffect(() => {
        const applyDate = () => {
            if (isTemplate) {
                setRecordDate({ date: new Date(yearBound, 0, 1, 12, 0, 0), period: 1 });
                return;
            }
            const recordDate = RecordFormUtils.resolveDateChange(
                refsData.formStateRef.current.recordDate,
                yearBound,
                periodBound,
                { date: record?.date, isUpdating }
            );
            refsData.dateTouchedRef.current = false;
            if (recordDate) {
                setRecordDate(recordDate);
            }
        };
        Promise.resolve().then(async () => {
            while (!refsData.formStateRef.current) {
                await new Promise(r => setTimeout(r, 5));
            }
            applyDate();
        });
    }, [record?.date, isUpdating, yearBound, periodBound, refsData, setRecordDate, isTemplate]);

    useEffect(() => {
        receiveRecord(record);
    }, [receiveRecord, record]);

    // componentDidUpdate
    useEffect(() => {
        setSelectedPayment(() => {
            // in case of KB / Bank / FE we need to check and set connected via payment record
            if (!productFormConfig.usePaymentBinding || !record?.key) {
                return null;
            }
            return PaymentBindingUtils.getRepresentationRecordPaymentProto({
                allRecords: refsData.allRecordsRef.current,
                paymentsRecordRelation: refsData.paymentsRecordRelationRef.current,
                representationRecord: record,
            });
        });
        refsData.savedItemsRef.current = {};
    }, [refsData, setSelectedPayment, record, productFormConfig]);

    React.useImperativeHandle(ref, () => {
        return {
            flashForm: () => {
                refsHtml.inlineFormRef.current.classList.add("ErfassungForm--flash");
                setTimeout(() => focusFirstFormField(), 50);
                setTimeout(() => {
                    if (refsHtml.inlineFormRef.current) {
                        refsHtml.inlineFormRef.current.classList.remove("ErfassungForm--flash");
                    }
                }, 200);
            },
            forceCleanup: () => onClearForm(),
            focusFirstField: () => focusFirstFormField(),
            setRecordCategoryCreditor: v => onRecordCategoryCreditorChange(v),
            setItemCategoryCreditor: v => onItemCategoryCreditorChange(v),
            setItemTag: v => onTagChange(v),
            focusAfterRechnung: () => {
                focusNextElement({
                    goBack: false,
                    parentSelector: `.${FormClassName}`,
                    activeElement: refsHtml.REF_rBELEGFELD1.current.input,
                });
            },
        };
    }, [
        refsHtml.inlineFormRef,
        onClearForm,
        onRecordCategoryCreditorChange,
        onItemCategoryCreditorChange,
        onTagChange,
        refsHtml.REF_rBELEGFELD1,
    ]);
    return <>{children}</>;
});

import React, { useRef } from "react";
import { Button, Divider, Form, Rate, Row, Space, Tooltip } from "antd";
import { Contacts } from "@binale-tech/shared";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FieldLabel } from "@binale-tech/ui-components";
import { FormattedMessage, useIntl } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";
import { getNames } from "i18n-iso-countries";

import { BaseInput } from "appearance/components/shared/form/baseComponents";
import { CountriesCombobox, ICountriesOption } from "../../components/CountriesCombobox";
import { RemoveButton } from "../../components/RemoveButton";
import { removeEmpty } from "scripts/infrastructure/helpers";

interface IProps {
    index: number;
    fieldsLength: number;
    onRemove: () => void;
}

const Address: React.FC<IProps> = ({ index, fieldsLength, onRemove }) => {
    const { setValue, getValues } = useFormContext<Contacts.Contact>();
    const { locale } = useIntl();

    const dataValue: ICountriesOption[] = React.useMemo(() => {
        return Object.entries(getNames(locale, { select: "official" })).map(([code, countryName]) => ({
            value: code,
            name: countryName,
        }));
    }, [locale]);

    return (
        <div data-testid={`address-card-${index}`}>
            <Divider orientation="left" plain style={{ borderTopColor: "#1890ff" }}>
                <b>
                    <FormattedMessage id="app.fields.address" /> {index + 1}
                    <Tooltip title="Make address primary" placement="top">
                        <Controller
                            name={`addresses.${index}.isPrimary`}
                            shouldUnregister
                            render={({ field }) => (
                                <Rate
                                    {...field}
                                    count={1}
                                    value={field.value ? 1 : 0}
                                    style={{ marginLeft: 4 }}
                                    onChange={() => {
                                        new Array(fieldsLength).fill(0).forEach((zero, indexF) => {
                                            if (indexF !== index) {
                                                setValue(`addresses.${indexF}.isPrimary`, undefined);
                                            }
                                        });
                                        field.onChange(!field.value);
                                    }}
                                />
                            )}
                        />
                    </Tooltip>
                </b>
            </Divider>
            <Row>
                <Space>
                    <Controller
                        name={`addresses.${index}.name`}
                        defaultValue={undefined}
                        render={({ field }) => (
                            <FieldLabel
                                label={<FormattedMessage id="app.fields.bezeichnung" />}
                                style={{ minWidth: 300 }}
                            >
                                <BaseInput data-testid="address-name" {...field} maxLength={35} showCount />
                            </FieldLabel>
                        )}
                    />
                </Space>
            </Row>
            <Row>
                <Space>
                    <Controller
                        name={`addresses.${index}.countryCode`}
                        render={({ field }) => (
                            <Form.Item
                                label={<FormattedMessage id="app.fields.countryCode" />}
                                data-testid="address-countryCode"
                            >
                                <CountriesCombobox {...field} optionsList={dataValue} onlyCountry />
                            </Form.Item>
                        )}
                    />
                    <Controller
                        name={`addresses.${index}.postCode`}
                        render={({ field }) => (
                            <FieldLabel label={<FormattedMessage id="app.fields.postcode" />} style={{ maxWidth: 100 }}>
                                <BaseInput data-testid="address-postcode" {...field} maxLength={10} showCount />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name={`addresses.${index}.city`}
                        render={({ field }) => (
                            <FieldLabel label={<FormattedMessage id="app.fields.city" />} style={{ minWidth: 250 }}>
                                <BaseInput data-testid="address-city" {...field} maxLength={30} showCount />
                            </FieldLabel>
                        )}
                    />
                </Space>
            </Row>
            <Row>
                <Space>
                    <Controller
                        name={`addresses.${index}.street`}
                        render={({ field }) => (
                            <FieldLabel label={<FormattedMessage id="app.fields.street" />} style={{ minWidth: 300 }}>
                                <BaseInput data-testid="address-street" {...field} maxLength={35} showCount />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name={`addresses.${index}.house`}
                        render={({ field }) => (
                            <FieldLabel label={<FormattedMessage id="app.fields.house" />} style={{ maxWidth: 120 }}>
                                <BaseInput data-testid="address-house" {...field} maxLength={5} showCount />
                            </FieldLabel>
                        )}
                    />
                </Space>
            </Row>
            <Row justify="space-between">
                <Controller
                    name={`addresses.${index}.addressLine2`}
                    render={({ field }) => (
                        <FieldLabel
                            label={<FormattedMessage id="app.fields.additionalAddress" />}
                            style={{ minWidth: 300 }}
                        >
                            <BaseInput data-testid="address-additional" {...field} maxLength={35} showCount />
                        </FieldLabel>
                    )}
                />
                <Form.Item label=" ">
                    <RemoveButton
                        isItemEmpty={() => {
                            const address = getValues(`addresses.${index}`);
                            const slimmedAddress = removeEmpty(address || {});
                            const isAddressEmpty =
                                !slimmedAddress ||
                                (Object.keys(slimmedAddress).length === 1 && !slimmedAddress.isPrimary);
                            return isAddressEmpty;
                        }}
                        onRemove={onRemove}
                    />
                </Form.Item>
            </Row>
        </div>
    );
};

export const Addresses: React.FC = React.memo(function Addresses() {
    const addBtnRef = useRef<HTMLButtonElement>();
    const { setFocus } = useFormContext<Contacts.Contact>();
    const { append, fields, remove } = useFieldArray({ name: "addresses", shouldUnregister: true });

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {fields.map((field, index) => (
                <Address
                    key={field.id}
                    index={index}
                    fieldsLength={fields.length}
                    onRemove={async () => {
                        remove(index);
                        await new Promise(r => setTimeout(r, 100));
                        if (fields.length > 1 && fields.length - 1 > index) {
                            setFocus(`addresses.${index}.name`);
                        } else {
                            addBtnRef.current?.focus();
                        }
                    }}
                />
            ))}
            {fields.length < 3 && (
                <Row justify={fields.length === 0 ? "start" : "end"}>
                    {fields.length > 0 && <Divider style={{ borderTopColor: "#1890ff", margin: "8px 0" }} />}
                    <Form.Item noStyle>
                        <Button
                            type="primary"
                            onClick={() => {
                                append(
                                    {
                                        name: "",
                                        countryCode: "",
                                        postCode: "",
                                        city: "",
                                        street: "",
                                        house: "",
                                        addressLine2: "",
                                        isPrimary: false,
                                    },
                                    { focusName: `addresses.${fields.length}.name` }
                                );
                            }}
                            icon={<PlusOutlined />}
                            ref={addBtnRef}
                            data-testid="button-add-address"
                        >
                            <FormattedMessage id="app.fields.address" tagName="span" />
                        </Button>
                    </Form.Item>
                </Row>
            )}
        </Space>
    );
});

import React, { FC, useEffect, useState } from "react";
import { DocumentPdfViewer } from "@app/components/shared/PdfViewer/DocumentPdfViewer";
import { useGenerateInvoicePdf } from "@inv/hooks/useGenerateInvoicePdf";

interface IProps {
    invoiceId: string;
}

export const InvoiceViewer: FC<IProps> = ({ invoiceId }) => {
    const [documentData, setDocumentData] = useState<{ url: string; filename: string; type: string }>(null);
    const createInvoicePdf = useGenerateInvoicePdf(invoiceId);

    useEffect(() => {
        createInvoicePdf().then(setDocumentData);
    }, [invoiceId]);

    if (!documentData) {
        return null;
    }

    return (
        <DocumentPdfViewer
            style={{ height: "100%", width: "100%" }}
            url={documentData.url}
            type={documentData.type}
            filename={documentData.filename}
        />
    );
};

import React, { FC } from "react";
import { InvoiceFormItemWrapper } from "@inv/components/InvoiceForm/components/InvoiceFormItemWrapper";
import { Col, Form, Row } from "antd";
import { InvoiceFormBlocksTranslate, InvoiceFormInputTranslate, InvoiceInputs } from "@inv/types";
import { DateInput } from "@dms/components/DocumentForm/DocumentInputs/DateInput";
import { DeliverySwitch } from "@inv/components/InvoiceForm/components/DeliverySwitch";
import { useIntl } from "react-intl";
import { StringInput } from "@app/components/shared/form/baseComponents/StringInput/StringInput";
import { InvoiceNumberInput, InvoiceNumberLabel } from "@binale-tech/ui-components";

export const InvoiceInputBlocks: FC = () => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.INVOICE_DETAILS })}>
            <Row gutter={[10, 0]}>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.INVOICE_NUMBER}>
                        <InvoiceNumberInput
                            suffix={<InvoiceNumberLabel hideLabel />}
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.INVOICE_NUMBER })}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.CUSTOMER_NUMBER}>
                        <StringInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.CUSTOMER_NUMBER })}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.DATE}>
                        <DateInput placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.DATE })} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <DeliverySwitch />
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};

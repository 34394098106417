import React, { FC, useContext } from "react";
import { Divider, Modal, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import styles from "@inv/components/LineItemsModal/LineItemsList.module.scss";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import {
    InvoiceModalContext,
    InvoiceModalControlContext,
} from "@inv/modules/InvoiceModalModule/context/InvoiceModalModule";

export const InvoiceModalModule: FC = () => {
    const { modalData } = useContext(InvoiceModalContext);
    const { closeModal } = useContext(InvoiceModalControlContext);

    if (!modalData) {
        return null;
    }

    const { title, height, component } = modalData;

    return (
        <Modal
            open={!!modalData}
            onCancel={closeModal}
            destroyOnClose
            style={{
                top: 100,
            }}
            closable={false}
            footer={null}
            title={
                <Divider orientation={"left"}>
                    <Typography.Title level={5}>
                        <FormattedMessage id={title} />
                    </Typography.Title>
                </Divider>
            }
            className={styles.modal}
            styles={{
                body: {
                    height,
                    minWidth: "60vw",
                    backgroundColor: "white",
                    display: "flex",
                    paddingTop: 10,
                },
            }}
        >
            <CloseButton onClose={closeModal} />
            {component}
        </Modal>
    );
};

import React, { FC, useContext } from "react";
import { Button, Flex, Input, Space, Typography } from "antd";
import { getDateFormat, getTransactionBankTitle } from "@banks/scripts/helpers";
import { Utils } from "@binale-tech/shared";
import { IBank } from "@banks/types";

import styles from "./BankTransactionsToolbar.module.scss";
import cn from "classnames";
import dayjs from "dayjs";
import { BanksAppControlContext } from "@banks/scripts/context";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../scripts/routing/routeConstants";
import { FormattedMessage } from "react-intl";
import { ArrowLeftOutlined, CloudUploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

type TProps = {
    transactionBank: IBank;
    balance: number;
    balanceDate?: string;
    handleSearch: (arg: string) => void;
};

export const BankTransactionsToolbar: FC<TProps> = ({ transactionBank, balance, balanceDate, handleSearch }) => {
    const { openTransactionUploader } = useContext(BanksAppControlContext);

    const navigate = useNavigate();

    const goToAllBank = () => {
        navigate(AppRoutes.banks);
    };

    const handleUpdateBank = () => {
        openTransactionUploader(transactionBank);
    };
    return (
        <>
            <Flex gap={10} align={"center"} justify={"space-between"}>
                <Title style={{ margin: 0 }} level={5}>
                    {`${getTransactionBankTitle(transactionBank ? { exist: transactionBank } : undefined)}`}
                </Title>
                <Space>
                    <Button type={"text"} onClick={goToAllBank} icon={<ArrowLeftOutlined />}>
                        <FormattedMessage id={"app.banks.allBanks"} />
                    </Button>
                    <Button type={"primary"} onClick={handleUpdateBank} icon={<CloudUploadOutlined />}>
                        <FormattedMessage id={"app.banks.uploadTransactions"} />
                    </Button>
                </Space>
            </Flex>
            <Flex gap={20}>
                <Flex gap={5} align={"center"} style={{ fontSize: 16 }}>
                    <span className={styles.balanceHeaderText}>
                        <FormattedMessage id={"app.fields.openingBalance"} />
                    </span>
                    <span
                        className={cn(styles.balanceHeaderText, {
                            [styles.positive]: transactionBank.openingBalance > 0,
                            [styles.negative]: transactionBank.openingBalance < 0,
                        })}
                    >
                        {`${
                            transactionBank.openingBalance
                                ? Utils.CurrencyUtils.currencyFormat(transactionBank.openingBalance)
                                : "0,00"
                        }`}
                    </span>
                    <span className={styles.balanceHeaderText}>EUR</span>
                    <span className={styles.balanceHeaderText}>
                        {transactionBank.openingBalanceDate
                            ? `on ${dayjs(transactionBank.openingBalanceDate, "DD.MM.YYYY").format("DD MMMM YYYY")}`
                            : null}
                    </span>
                </Flex>
                <Flex gap={5} align={"center"} style={{ fontSize: 16 }}>
                    <span className={styles.balanceHeaderText}>
                        <FormattedMessage id={"app.fields.saldo"} />
                    </span>
                    <span
                        className={cn(styles.balanceHeaderText, {
                            [styles.positive]: balance > 0,
                            [styles.negative]: balance < 0,
                        })}
                    >
                        {`${balance ? Utils.CurrencyUtils.currencyFormat(balance) : "0,00"}`}
                    </span>
                    <span className={styles.balanceHeaderText}>EUR</span>
                    <span className={styles.balanceHeaderText}>
                        {balanceDate
                            ? `on ${dayjs(balanceDate, getDateFormat(balanceDate)).format("DD MMMM YYYY")}`
                            : null}
                    </span>
                </Flex>
                <Flex justify={"end"} style={{ flexGrow: 1 }}>
                    <Input.Search
                        style={{ maxWidth: 500, width: "100%" }}
                        onChange={e => handleSearch(e.target.value)}
                        allowClear
                    />
                </Flex>
            </Flex>
        </>
    );
};

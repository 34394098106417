import { gql } from "@apollo/client";

export const getUserData = gql`
    query getUserData {
        me {
            id
            email
            isAdmin
            isAdminWrite
            is2faEnabled
            companies {
                id
                name
                datevNrConsultant
                datevNrCompany
            }
        }
    }
`;

export const getPrivateToken = gql`
    query getPrivateToken {
        privateToken {
            token
        }
    }
`;

export const getCompanyAccessData = gql`
    query getCompanyAccessData($id: ID!) {
        company(id: $id) {
            id
            name
            color
            favouritePaymentType
            datevNrConsultant
            datevNrCompany
            accountingYears
            legalForm
            legalName
            invoiceName
            invoiceEmail
            invoiceReceiver
            invoiceAddress {
                addressLine2
                city
                countryCode
                house
                postCode
                street
            }
            invoiceTax {
                bundesland
                stnr
            }
            owner {
                id
                email
            }
            bankList {
                id
                year
                name
                accountNum
            }
            kasseList {
                id
                year
                name
                accountNum
            }
            members {
                id
                emailKey
                roles
                products {
                    id
                    productKey
                    access
                }
            }
            products {
                id
                productKey
            }
            accountingConfigs {
                id
                skr
                kontoExt
                taxation
                year
                fiscalYearStart
            }
            datevOAuth {
                id
                issuedAt
                accessTokenExpiresIn
                refreshTokenExpiresIn
            }
        }
    }
`;

export const getDmsDocumentTypes = gql`
    query getDmsDocumentTypes($companyId: ID!) {
        dmsDocumentTypes(companyId: $companyId) {
            id
            icon
            subTypes {
                id
                name
                sortOrder
                icon
                dmsDocumentTypeId
                bankId
                kbId
            }
        }
    }
`;

export const datevUserInfoQuery = gql`
    query datevUserInfo {
        datevUserInfo {
            user {
                id
                name
                email
                familyName
                emailVerified
            }
            clients {
                id
                clientNumber
                consultantNumber
                name
                services {
                    name
                    scopes
                }
            }
        }
    }
`;

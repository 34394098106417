import React, { FC, useContext, useEffect } from "react";
import { Segmented } from "antd";
import { InvoiceFormInputTranslate } from "@inv/types";
import { useIntl } from "react-intl";
import {
    InvoiceFormContext,
    InvoiceFormControlContext,
} from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";

interface IProps {
    value?: boolean;
    onChange?: (value: boolean) => void;
}

export const BruttoToNettoSwitch: FC<IProps> = ({ value, onChange }) => {
    const intl = useIntl();
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const { setInvoiceTotalView } = useContext(InvoiceFormControlContext);

    useEffect(() => {
        setInvoiceTotalView(value);
    }, [value]);

    return (
        <Segmented
            options={[
                {
                    value: true,
                    label: intl.formatMessage({ id: InvoiceFormInputTranslate.BRUTTO }),
                },
                {
                    value: false,
                    label: intl.formatMessage({ id: InvoiceFormInputTranslate.NETTO }),
                },
            ]}
            value={invoiceTotalView}
            onChange={(value: boolean) => onChange?.(value)}
        />
    );
};

import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { FieldLabel } from "../../../../appearance/components/shared/form/baseComponents/FieldLabel/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";
import { TypeInput } from "../DocumentInputs/TypeInput";
import { TOptions } from "../types";

const { Text } = Typography;

export const SinglePaymentTypeField: FC<{ options: TOptions }> = ({ options }) => {
    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.paymentMethod" />
                </Text>
            }
            name={"paymentType"}
        >
            <TypeInput options={options} />
        </FieldLabel>
    );
};

const GroupPaymentTypeField: FC<{ options: TOptions }> = ({ options }) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const { notEqualField, isCancel, requiredFields } = useContext(GroupFormContext);
    const placeholder = notEqualField?.includes("discountAmount") ? "Diverse" : undefined;

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"paymentTypeCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"paymentType"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        <FormattedMessage id="app.fields.paymentMethod" />
                    </Text>
                }
                rules={[{ required: requiredFields?.includes("paymentType"), message: "" }]}
                name={"paymentType"}
            >
                <TypeInput options={options} disabled={isDisabled} placeholder={placeholder} />
            </FieldLabel>
        </>
    );
};

export const DocumentPaymentTypeField: FC<{ options: TOptions }> = ({ options }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return (
        <>
            {isGroupEditor ? <GroupPaymentTypeField options={options} /> : <SinglePaymentTypeField options={options} />}
        </>
    );
};

import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SettingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Divider, Flex, List, Popover, Switch, Typography } from "antd";

import styles from "./ViewColumnsControl.module.scss";

const { Text } = Typography;

interface SettingsColumnsProps {
    columnsConfig: Record<string, boolean>;
    requiredColumns: string[];
    columnTranslations: Record<string, string>;
    isOpenSettingsCol: boolean;
    setColumnsConfig: (config: Record<string, boolean>) => void;
    setIsOpenSettingsCol: (open: boolean) => void;
}

export const ViewColumnsControl: FC<SettingsColumnsProps> = ({
    columnsConfig,
    requiredColumns,
    columnTranslations,
    isOpenSettingsCol,
    setColumnsConfig,
    setIsOpenSettingsCol,
}) => {
    const [columnState, setColumnState] = useState([]);

    const columnsList = useMemo(() => {
        if (!columnsConfig) {
            return [];
        }

        const values = Object.keys(columnsConfig).filter(el => !requiredColumns.includes(el));

        return values.map(el => ({
            value: el,
            checked: columnsConfig[el],
            translate: columnTranslations[el],
        }));
    }, [columnsConfig, requiredColumns, columnTranslations]);

    const handleChange = (value: string, checked: boolean) => {
        setColumnState(prev => prev.map(item => (item.value === value ? { ...item, checked } : item)));
    };

    const handleClose = () => {
        const newColumnConfig = columnState.reduce(
            (acc, item) => {
                acc[item.value] = item.checked;
                return acc;
            },
            {} as Record<string, boolean>
        );
        const updatedConfig = { ...columnsConfig, ...newColumnConfig };
        setColumnsConfig(updatedConfig);
        setIsOpenSettingsCol(false);
    };

    useEffect(() => {
        setColumnState(columnsList);
    }, [columnsList]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Divider: {
                        marginLG: 8,
                        margin: 8,
                    },
                    Collapse: {
                        headerPadding: 0,
                        padding: 0,
                    },
                },
            }}
        >
            <div className={styles.wrapper}>
                <Popover
                    destroyTooltipOnHide={true}
                    open={isOpenSettingsCol}
                    trigger="click"
                    title={
                        <div>
                            <div className={styles.title}>
                                <FormattedMessage id={"app.settings.button.columns.title"} />
                            </div>
                            <Divider />
                        </div>
                    }
                    content={
                        <Flex vertical={true} gap={10} className={styles.content}>
                            <List
                                itemLayout="horizontal"
                                dataSource={columnState}
                                grid={{ column: 2 }}
                                renderItem={item => (
                                    <List.Item>
                                        <Switch
                                            id={item.value}
                                            checked={item.checked}
                                            onChange={checked => handleChange(item.value, checked)}
                                            size={"small"}
                                        />
                                        <Text style={{ marginLeft: "15px" }}>
                                            <FormattedMessage id={item.translate} />
                                        </Text>
                                    </List.Item>
                                )}
                            />
                            <Divider />
                            <Button onClick={handleClose} style={{ marginLeft: "auto", maxWidth: "100px" }}>
                                <FormattedMessage id={"app.button.confirm"} />
                            </Button>
                        </Flex>
                    }
                >
                    <Button
                        type="link"
                        icon={<SettingOutlined />}
                        className={styles.settings}
                        onClick={() => setIsOpenSettingsCol(true)}
                    />
                </Popover>
            </div>
        </ConfigProvider>
    );
};

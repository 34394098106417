import CompanyQueriesGraphql from "./companyQueries.graphql";
import React, { useEffect, useState } from "react";
import { Button, Card, Drawer, Flex, Form, Input, Select, Space, Spin } from "antd";
import { CountriesCombobox, ICountriesOption } from "@app/views/productContacts/components/CountriesCombobox";
import { FormattedMessage, useIntl } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { UserContext } from "scripts/context/UserProvider";
import { getNames } from "i18n-iso-countries";
import { useGQLRetriever } from "../../../scripts/graphql/gqlRetriever";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { validateStnrQuery, validation } from "../../../scripts/infrastructure/helpers/validation";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useApolloClient } from "@apollo/client";

type InvoiceFormValues = {
    invoiceEmail: string;
    invoiceName: string;
    invoiceAddress: GQL.IContactAddress;
    invoiceTax: GQL.ICompanyTax;
    invoiceReceiver: string;
};
type Props = {
    company: GQL.ICompany;
    onComplete: (v: GQL.ICompany) => void;
};

const GermanStates: DefaultOptionType[] = [
    { label: "", value: null },
    { label: "Baden-Württemberg", value: "bw" },
    { label: "Bayern", value: "by" },
    { label: "Berlin", value: "be" },
    { label: "Brandenburg", value: "bb" },
    { label: "Bremen", value: "hb" },
    { label: "Hamburg", value: "hh" },
    { label: "Hessen", value: "he" },
    { label: "Mecklenburg-Vorpommern", value: "mv" },
    { label: "Niedersachsen", value: "nd" },
    { label: "Nordrhein-Westfalen", value: "nw" },
    { label: "Rheinland-Pfalz", value: "rp" },
    { label: "Saarland", value: "sl" },
    { label: "Sachsen", value: "sn" },
    { label: "Sachsen-Anhalt", value: "st" },
    { label: "Schleswig-Holstein", value: "sh" },
    { label: "Thüringen", value: "th" },
];

export const CompanyInvoiceData: React.FC<Props> = ({ onComplete, company }) => {
    const intl = useIntl();
    const client = useApolloClient();
    const { fireUser } = React.useContext(UserContext);
    const [form] = Form.useForm<InvoiceFormValues>();
    const Mutator = useGqlMutator();
    const bundesland = Form.useWatch(["invoiceTax", "bundesland"], form);
    const stnr = Form.useWatch(["invoiceTax", "stnr"], form);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (!bundesland && stnr) {
            form.setFieldValue(["invoiceTax", "stnr"], null);
        }
        form.validateFields(["invoiceTax", "stnr"]);
    }, [form, bundesland, stnr]);
    const dataValue: ICountriesOption[] = React.useMemo(() => {
        return Object.entries(getNames(intl.locale, { select: "official" })).map(([code, countryName]) => ({
            value: code,
            name: countryName,
        }));
    }, [intl.locale]);
    const handleSubmit = async (values: InvoiceFormValues) => {
        const { invoiceEmail, invoiceName, invoiceAddress, invoiceReceiver, invoiceTax } = values;
        console.log(invoiceTax);

        setLoading(true);
        await Mutator.mutate<"companySetInvoiceData", GQL.ICompanySetInvoiceInput>({
            mutation: CompanyQueriesGraphql.mutationSetInvoiceData,
            input: {
                id: company.id,
                email: invoiceEmail,
                name: invoiceName,
                address: invoiceAddress,
                receiver: invoiceReceiver,
                tax: invoiceTax?.bundesland ? invoiceTax : undefined,
            },
        })
            .then(res => {
                form.resetFields();
                onComplete(res.companySetInvoiceData);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const initialValues: InvoiceFormValues = {
        invoiceEmail: company?.invoiceEmail || fireUser.email,
        invoiceName: company.invoiceName,
        invoiceAddress: company?.invoiceAddress || { countryCode: "DE" },
        invoiceTax: company?.invoiceTax,
        invoiceReceiver: company?.invoiceReceiver,
    };
    return (
        <Spin spinning={loading}>
            <Form onFinish={handleSubmit} layout="vertical" form={form} initialValues={initialValues}>
                <Flex gap="middle" vertical>
                    <Space>
                        <Form.Item
                            label={"Rechnung Email"}
                            name="invoiceEmail"
                            rules={[validation.required(intl), validation.max(intl, 200)]}
                            style={{ width: 200 }}
                        >
                            <Input maxLength={200} type="email" autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={"zu Händen von (optional)"}
                            name="invoiceName"
                            rules={[validation.max(intl, 200)]}
                            style={{ width: 200 }}
                        >
                            <Input maxLength={200} />
                        </Form.Item>
                        <Form.Item
                            label={"Rechnungsempfänger"}
                            name="invoiceReceiver"
                            rules={[validation.required(intl), validation.max(intl, 200)]}
                            style={{ width: 200 }}
                        >
                            <Input maxLength={200} />
                        </Form.Item>
                    </Space>

                    <Card title={"Steuerliche Informationen"} style={{ marginTop: 0 }}>
                        <Space.Compact>
                            <Form.Item label={"Bundesland"} name={["invoiceTax", "bundesland"]}>
                                <Select options={GermanStates} />
                            </Form.Item>
                            <Form.Item
                                validateDebounce={100}
                                hasFeedback
                                label={"Steuernummer"}
                                rules={[validation.stnr(client, bundesland)]}
                                name={["invoiceTax", "stnr"]}
                            >
                                <StnrField />
                            </Form.Item>
                        </Space.Compact>
                    </Card>

                    <Card title={<FormattedMessage id="app.fields.address" />} style={{ marginTop: 0 }}>
                        <Flex gap={"middle"}>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.countryCode" />}
                                rules={[validation.required(intl)]}
                                name={["invoiceAddress", "countryCode"]}
                            >
                                <CountriesCombobox optionsList={dataValue} onlyCountry />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.postcode" />}
                                rules={[validation.required(intl)]}
                                name={["invoiceAddress", "postCode"]}
                            >
                                <Input type="number" min={1000} max={999999} />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.city" />}
                                rules={[validation.required(intl)]}
                                name={["invoiceAddress", "city"]}
                            >
                                <Input />
                            </Form.Item>
                        </Flex>
                        <Flex gap={"middle"}>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.street" />}
                                rules={[validation.required(intl)]}
                                name={["invoiceAddress", "street"]}
                            >
                                <Input maxLength={35} />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.house" />}
                                rules={[validation.required(intl)]}
                                name={["invoiceAddress", "house"]}
                            >
                                <Input maxLength={5} />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="app.fields.additionalAddress" />}
                                name={["invoiceAddress", "addressLine2"]}
                            >
                                <Input maxLength={35} />
                            </Form.Item>
                        </Flex>
                    </Card>
                    <Space>
                        <Button type={"primary"} htmlType={"submit"}>
                            <FormattedMessage id={"app.button.add"} />
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Spin>
    );
};
const StnrField = React.forwardRef<any, React.ComponentProps<typeof Input>>((props, ref) => {
    const [hint, setHint] = useState<string>();
    const form = Form.useFormInstance();
    const client = useApolloClient();
    const bundesland = Form.useWatch(["invoiceTax", "bundesland"], form);
    const stnr = Form.useWatch(["invoiceTax", "stnr"], form);
    const { status } = Form.Item.useStatus();
    useEffect(() => {
        if (bundesland && stnr && status === "success") {
            client
                .query<Pick<GQL.IQuery, "validateStnr">, GQL.IQueryValidateStnrArgs>({
                    query: validateStnrQuery,
                    fetchPolicy: "network-only",
                    variables: {
                        input: {
                            stnr,
                            bundesland,
                        },
                    },
                })
                .then(v => {
                    setHint(v.data.validateStnr.formattedTaxNumber);
                })
                .catch(console.error);
        } else {
            setHint(undefined);
        }
    }, [client, stnr, bundesland, status]);
    return (
        <Space>
            <Input
                {...props}
                ref={ref}
                minLength={10}
                maxLength={11}
                disabled={!bundesland}
                onKeyDown={event => {
                    if (event.key.length > 1) {
                        return;
                    }
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
            {status === "validating" ? <div>...</div> : <div>{hint}</div>}
        </Space>
    );
});

type DrawerProps = {
    companyId?: string;
    onHide: () => void;
};
export const CompanyInvoiceDataDrawer: React.FC<DrawerProps> = ({ companyId, onHide }) => {
    const retriever = useGQLRetriever<"company">();
    const [company, setCompany] = React.useState<GQL.ICompany | null>();
    const refetch = React.useCallback(() => {
        retriever
            .query({ id: companyId, query: CompanyQueriesGraphql.queryCompanyForEdit })
            .then(data => setCompany(data?.company));
    }, [retriever, companyId]);

    React.useEffect(() => {
        if (companyId) {
            refetch();
        } else {
            setCompany(null);
        }
    }, [refetch, companyId]);
    return (
        <Drawer
            key="drawer"
            width={1000}
            title={<span>Invoice Data</span>}
            placement="right"
            open={Boolean(companyId)}
            onClose={onHide}
            destroyOnClose
        >
            {company ? <CompanyInvoiceData company={company} onComplete={onHide} /> : <Spin />}
        </Drawer>
    );
};

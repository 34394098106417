import { auth, getAppCheckToken } from "../firebase/firebase";

const urlMap = new Map<string, string>([
    ["steuerflow-testing", "https://api.binale.tech"],
    ["binale-app-de", "https://api.binale.app"],
    ["binale-dms", "https://europe-west3-binale-dms.cloudfunctions.net/serverless"],
]);
export const getServerlessHost = () => {
    const { projectId } = auth.app.options;
    const host = import.meta.env.VITE_URL_GQL || urlMap.get(projectId);
    return host;
};
export const getServerlessApi = async (): Promise<{ host: string; headers: Record<string, string> }> => {
    const host = getServerlessHost();

    const idToken = await auth.currentUser.getIdToken();
    return {
        host,
        headers: {
            authorization: `Bearer ${idToken}`,
            "X-Firebase-AppCheck": await getAppCheckToken(),
        },
    };
};

import { IntlShape, useIntl } from "react-intl";
import { Rule } from "antd/es/form";
import { useMemo } from "react";
import { ApolloClient, gql } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { validateIbanQuery } from "@app/components/shared/form/baseComponents/InputIBAN/queries/queries";

export const validateStnrQuery = gql`
    query validateStnr($input: CompanyTaxInput!) {
        validateStnr(input: $input) {
            isValid
            fullTaxNumber
            error
            formattedTaxNumber
        }
    }
`;

interface IValidationCommon {
    intl: IntlShape;
    max: number;
    min: number;
}

// for antd Form
const commonValidation = {
    required: (intl: IntlShape) => ({
        required: true,
        message: intl.formatMessage({ id: "app.validation.error.field_empty" }),
    }),
    max: (intl: IntlShape, max: number) => ({
        max,
        message: "max." + intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: max }),
    }),
    min: (intl: IntlShape, min: number) => ({
        min,
        message: "min. " + intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: min }),
    }),
    onlyNumber: { pattern: /^\d+$/, message: "should be a number" },
};

export const validation = {
    ...commonValidation,
    strict: (intl: IntlShape, count: number) => ({
        min: count,
        message: intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: count }),
    }),
    email: (intl: IntlShape): Rule => ({
        type: "email",
        message: intl.formatMessage({ id: "app.validation.error.invalid_email" }),
    }),
    commonNumber: ({ intl, max, min }: IValidationCommon): Rule[] => {
        return [commonValidation.max(intl, max), commonValidation.min(intl, min), commonValidation.onlyNumber];
    },
    unique: (itemList: string[], initial: string | null): Rule => {
        return {
            message: "should be unique",
            validator: (rule, value: string) => {
                if (itemList.includes(value)) {
                    if (initial === undefined || initial === null || initial !== value) {
                        return Promise.reject("not unique");
                    }
                }
                return Promise.resolve();
            },
        };
    },
    inRange: (min: number, max: number): Rule => {
        return {
            message: `should be in range [${min}, ${max}]`,
            validator: (rule, value: string) => {
                if (value) {
                    if (+value < +min) {
                        return Promise.reject("too low");
                    }
                    if (+value > +max) {
                        return Promise.reject("too high");
                    }
                }
                return Promise.resolve();
            },
        };
    },
    pattern: (pattern: RegExp, message: string): Rule => {
        return {
            message,
            validator: (rule, value: string) => {
                if (value && !pattern.test(value)) {
                    return Promise.reject("pattern");
                }
                return Promise.resolve();
            },
        };
    },
    stnr: (client: ApolloClient<any>, bundesland: string): Rule => {
        return {
            message: "Invalid StNr",
            validator: async (rule, value: string) => {
                if (!bundesland) {
                    return Promise.resolve();
                }
                if (!value) {
                    return Promise.resolve();
                }
                const res = await client
                    .query<Pick<GQL.IQuery, "validateStnr">, GQL.IQueryValidateStnrArgs>({
                        query: validateStnrQuery,
                        fetchPolicy: "network-only",
                        variables: {
                            input: {
                                stnr: value,
                                bundesland,
                            },
                        },
                    })
                    .then(v => {
                        return v.data.validateStnr?.isValid;
                    })
                    .catch(() => false);
                if (res) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
    iban: (client: ApolloClient<any>): Rule => {
        return {
            message: "IBAN is not valid",
            validator: async (rule, value: string) => {
                if (!value) {
                    return Promise.resolve();
                }
                const isValid = await client
                    .query<Pick<GQL.IQuery, "validateIban">>({
                        query: validateIbanQuery,
                        variables: { iban: value },
                        fetchPolicy: "network-only",
                    })
                    .then(res => res.data.validateIban)
                    .catch(() => false);

                if (isValid) {
                    return Promise.resolve();
                }
                return Promise.reject();
            },
        };
    },
};

// interface RuleProp {}
// for React-Hook-Form
export const useValidation = () => {
    const intl = useIntl();

    const validator = useMemo(() => {
        return {
            required: {
                message: intl.formatMessage({ id: "app.validation.error.field_empty" }),
                value: true,
            },
            minLength: (min: number) => ({
                message: "min." + intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: min }),
                value: min,
            }),
            maxLength: (max: number) => ({
                message: "max." + intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: max }),
                value: max,
            }),
            strict: (count: number) => ({
                message: intl.formatMessage({ id: "app.validation.error.strictLength" }, { length: count }),
                value: count,
            }),
            onlyNumber: {
                message: "should be a number",
                value: /^\d+$/,
            },
            unique:
                (itemList: string[] = [], initial: string | null) =>
                (val: string) => {
                    if (val && initial !== val && itemList.includes(val)) {
                        return "not unique";
                    }
                    return true;
                },
            email: (val: string) => {
                const emailPattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!val || emailPattern.test(val)) {
                    return true;
                }
                return intl.formatMessage({ id: "app.validation.error.invalid_email" });
            },
        };
    }, [intl]);

    return validator;
};

import React from "react";
import { CurrencyInputBlock } from "@binale-tech/ui-components";
import { GQL } from "@binale-tech/shared";
import { useApolloClient } from "@apollo/client";
import { retrieveCurrencyRate } from "../../../../../../scripts/infrastructure/queries/retrieveCurrencyRate";

type Props = Omit<React.ComponentProps<typeof CurrencyInputBlock>, "retrieveCurrencyRate"> & {
    recordDate?: Date | undefined;
};

export const CurrencyInputBlockWrapper: React.FC<Props> = props => {
    const client = useApolloClient();

    const handleRetrieveCurrencyRate = async (currencyCode: GQL.ICurrencyCode) => {
        return retrieveCurrencyRate({
            client,
            currencyCode,
            documentDate: props.recordDate,
        });
    };

    return (
        <CurrencyInputBlock
            {...props}
            retrieveCurrencyRate={props.recordDate ? handleRetrieveCurrencyRate : undefined}
        />
    );
};

import React, { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

import { Contacts } from "@binale-tech/shared";
import { ContactsContext } from "scripts/context/ContactsContext";
import { useValidation } from "scripts/infrastructure/helpers/validation";

export const useNothingSelectedMessage = () => {
    const intl = useIntl();
    const relations = Contacts.ContactConstants.relationTypeKontoOrder.map(key =>
        intl.formatMessage({ id: `app.titles.contacts.${key}` })
    );
    return <FormattedMessage id="app.message.nothingSelected" values={{ relations: relations.join(", ") }} />;
};

export const useCustomValidation = (initialContact: Contacts.Contact) => {
    const { contacts } = useContext(ContactsContext);
    const validators = useValidation();

    const validation = useMemo(() => {
        const uniqueData = contacts.reduce(
            (acc, contact) => {
                return {
                    IDNr: [...(acc.IDNr || []), contact.legalInfo?.IDNr],
                    EORI: [...(acc.EORI || []), contact.legalInfo?.EORI],
                    taxNumber: [...(acc.taxNumber || []), contact.legalInfo?.taxNumber],
                    internalName: [...(acc.internalName || []), contact.internalName],
                };
            },
            {} as Record<string, string[]>
        );

        return {
            internalName: validators.unique(uniqueData.internalName, initialContact?.internalName),
            IDNr: validators.unique(uniqueData.IDNr, initialContact?.legalInfo?.IDNr),
            EORI: validators.unique(uniqueData.EORI, initialContact?.legalInfo?.EORI),
            taxNumber: validators.unique(uniqueData.taxNumber, initialContact?.legalInfo?.taxNumber),
        };
    }, [contacts, initialContact, validators]);

    return validation;
};

const register = (legal: Contacts.Contact["legalInfo"] = {}) =>
    `${legal.typeOfRegister || ""} ${legal.registerCourt || ""} ${legal.registerNumber || ""}`;

const vat = (legal: Contacts.Contact["legalInfo"] = {}) => `${legal.landCode || ""} ${legal.UStIdNr || ""}`;

export const useLegalInfoValidation = (initialContact: Contacts.Contact) => {
    const { watch } = useFormContext<Contacts.Contact>();
    const { contacts } = useContext(ContactsContext);
    const legal = watch("legalInfo");

    const validation = useMemo(() => {
        const uniqueData = contacts.reduce(
            (acc, contact) => {
                if (initialContact?.uuid && contact.uuid === initialContact?.uuid) {
                    return acc;
                }
                return {
                    vat: [...(acc.vat || []), vat(contact.legalInfo)],
                    register: [...(acc.register || []), register(contact.legalInfo)],
                };
            },
            { vat: [], register: [] } as Record<"vat" | "register", string[]>
        );

        return {
            vat: () => {
                if (legal?.landCode && legal?.UStIdNr && uniqueData.vat.includes(vat(legal))) {
                    return "not unique";
                }
                return true;
            },
            register: () => {
                if (
                    legal?.registerCourt &&
                    legal?.registerNumber &&
                    legal?.typeOfRegister &&
                    uniqueData.register.includes(register(legal))
                ) {
                    return "not unique";
                }
                return true;
            },
        };
    }, [legal, contacts, initialContact]);

    return validation;
};

import React, {
    ContextType,
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import * as Sentry from "@sentry/react";
import { GQL } from "@binale-tech/shared";
import { useIntl } from "react-intl";

import ProgramSettings from "../models/ProgramSettings";
import { AppError, AppSettingsContext } from "./AppSettingsProvider";
import { BuContext, BuTimeframe, defaultBuContextValue } from "./BuContext";
import { BuTaxesSKR } from "../models/BuTaxUtils";
import { ProductAccessUtils } from "../models/utils/ProductAccessUtils";
import { ProductKey } from "../models/Product";
import { SKRRetriever } from "./SKRRetriever/SKRRetriever";
import { UserContext } from "./UserProvider";
import {
    companyCreateBank,
    companyCreateKB,
    companyDeleteBank,
    companyDeleteKB,
    companyUpdateBankYearConfig,
    companyUpdateKBYearConfig
} from "./mutations/companyMutations.graphql";
import { getCompanyAccessData } from "./queries/ctxQueries.graphql";
import { useGQLRetriever } from "../graphql/gqlRetriever";
import { useGqlMutator } from "../graphql/useGqlMutator";

type CompanyCtx = {
    companyGQL: GQL.ICompany;
    yearConfig: GQL.ICompanyAccountingYear;
    yearBanks: GQL.ICompanyBank[];
    yearKbs: GQL.ICompanyKasse[];
    isLoaded: boolean;
    programSettingsProvider: (pk: ProductKey) => ProgramSettings;
};
const defaultState: CompanyCtx = {
    companyGQL: null,
    yearConfig: null,
    yearBanks: [],
    yearKbs: [],
    isLoaded: false,
    programSettingsProvider: (pk: ProductKey) => ({}),
};
export const CompanyContext = createContext(defaultState);
type CtxSubset = Pick<ContextType<typeof CompanyContext>, "yearConfig" | "yearKbs" | "yearBanks">;
const ctxSunsetDefault: CtxSubset = {
    yearConfig: defaultState.yearConfig,
    yearBanks: defaultState.yearBanks,
    yearKbs: defaultState.yearKbs,
};
export const YearPeriodContext = createContext({
    year: new Date().getFullYear(),
    period: new Date().getMonth() + 1,
    onChangeYear: (v: number) => {},
    onChangePeriod: (v: number) => {},
});

type Controller = {
    onBankCreate: (data: Omit<GQL.ICompanyCreateBankInput, "companyId" | "year">) => Promise<unknown>;
    onBankUpdate: (data: Omit<GQL.ICompanyUpdateBankInput, "companyId" | "year">) => Promise<unknown>;
    onBankDelete: (data: Omit<GQL.ICompanyNumEntityDeleteInput, "companyId" | "year">) => Promise<unknown>;
    onKBCreate: (data: Omit<GQL.ICompanyCreateKbInput, "companyId" | "year">) => Promise<unknown>;
    onKBUpdate: (data: Omit<GQL.ICompanyUpdateKbInput, "companyId" | "year">) => Promise<unknown>;
    onKBDelete: (data: Omit<GQL.ICompanyNumEntityDeleteInput, "companyId" | "year">) => Promise<unknown>;
    triggerDataRefetch: () => Promise<unknown>;
    updateProductSettings: (productKey: ProductKey, settings: Partial<ProgramSettings>) => Promise<unknown>;
};
export const CompanyControlContext = createContext<Controller>({
    onBankCreate: () => Promise.reject(),
    onBankUpdate: () => Promise.reject(),
    onBankDelete: () => Promise.reject(),
    onKBCreate: () => Promise.reject(),
    onKBUpdate: () => Promise.reject(),
    onKBDelete: () => Promise.reject(),
    triggerDataRefetch: () => Promise.reject(),
    updateProductSettings: () => Promise.reject(),
});

export const CompanyProvider: FC<PropsWithChildren> = props => {
    const { isAuthenticated, selectedCompany, isUserDataLoaded } = useContext(UserContext);
    const Mutator = useGqlMutator();
    const retriever = useGQLRetriever<"company">();
    const intl = useIntl();
    const skrRetriever = useRef(new SKRRetriever());
    const [isLoaded, setLoaded] = useState(false);
    const [companyCtxYearSubset, setCompanyCtxYearSubset] = useState<CtxSubset>(ctxSunsetDefault);
    const [companyGQL, setCompanyGQL] = useState<GQL.ICompany>(null);
    const [buContextValue, setBuContextValue] = useState<ContextType<typeof BuContext>>(defaultBuContextValue);
    const [programSettingsProvider, setProgramSettingsProvider] = useState<(pk: ProductKey) => ProgramSettings>(
        () => ({})
    );
    const [programSettings, setProgramSettings] = useState<Record<ProductKey, ProgramSettings>>();
    const [year, onChangeYear] = useState<number>(new Date().getFullYear());
    const [period, onChangePeriod] = useState<number>(new Date().getMonth() + 1);
    const yearMonthValue: ContextType<typeof YearPeriodContext> = useMemo(
        () => ({
            year,
            period,
            onChangeYear,
            onChangePeriod,
        }),
        [year, period]
    );
    const companyId = companyGQL?.id;
    const companyContextValue: ContextType<typeof CompanyContext> = useMemo(() => {
        const { yearConfig, yearKbs, yearBanks } = companyCtxYearSubset;
        return {
            yearConfig,
            yearKbs,
            yearBanks,
            isLoaded,
            companyGQL,
            programSettingsProvider,
        };
    }, [companyCtxYearSubset, companyGQL, isLoaded, programSettingsProvider]);
    const { setAppError } = useContext(AppSettingsContext);

    const reset = useCallback(() => {
        setLoaded(false);
        setCompanyCtxYearSubset(ctxSunsetDefault);
        setCompanyGQL(null);
        setBuContextValue(defaultBuContextValue);
        setProgramSettingsProvider(() => {
            return () => ({});
        });
        Sentry.setTag("company", null);
        Sentry.setTag("year", null);
        BuTaxesSKR.setBuTimeframesCacheValue([], null);
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            reset();
        }
    }, [reset, isAuthenticated]);

    useEffect(() => {
        if (!companyId) {
            setProgramSettings({} as Record<ProductKey, ProgramSettings>);
            return;
        }

        ProgramSettings.getFields(companyId).then(res => {
            setProgramSettings(res);
        });
    }, [companyId]);

    useEffect(() => {
        setProgramSettingsProvider(() => {
            return (pk: ProductKey) => programSettings[pk] || {};
        });
    }, [programSettings]);

    const fetchSKRConfiguration = useCallback(
        async (
            configs: GQL.ICompanyAccountingYear[],
            shouldLoad: boolean
        ): Promise<{
            companyBuTimeframes: BuTimeframe[];
            allBuTimeframes: Omit<BuTimeframe, "defaultCategories">[];
        }> => {
            if (!shouldLoad) {
                return Promise.resolve(defaultBuContextValue);
            }
            return skrRetriever.current.fetch(configs);
        },
        []
    );

    const reFetchData = useCallback(async () => {
        if (!selectedCompany) {
            setLoaded(true);
            return;
        }
        const response = await retriever.query({ id: selectedCompany, query: getCompanyAccessData });
        if (!response?.company) {
            setInterval(() => {
                reFetchData();
            }, 1000);
            return;
        }
        const companyData = response.company;
        if (companyData.accountingYears.length > 0 && !companyData.accountingYears.includes(year)) {
            onChangeYear(Math.max(...companyData.accountingYears));
            return;
        }
        const configs = companyData?.accountingConfigs ?? [];
        const yearConfig = configs.find(v => v.year === year);
        const yearKbs = [...(companyData?.kasseList ?? [])].filter(v => v.year === year);
        const yearBanks = [...(companyData?.bankList ?? [])].filter(v => v.year === year);
        const hasAccounting = ProductAccessUtils.hasCompanyAccounting(companyData);

        try {
            const skr = await fetchSKRConfiguration(configs, hasAccounting);
            BuTaxesSKR.setBuTimeframesCacheValue(skr.companyBuTimeframes, companyData);
            setCompanyCtxYearSubset({ yearConfig, yearKbs, yearBanks });
            setCompanyGQL(companyData);
            setBuContextValue(skr);
            setLoaded(true);
            Sentry.setTag("company", companyData.id);
            Sentry.setTag("year", year);
        } catch (e) {
            console.log("AppError.IndexedDB");
            console.error(e);
            setAppError(AppError.IndexedDB);
        }
    }, [setAppError, year, fetchSKRConfiguration, retriever, selectedCompany]);

    useEffect(() => {
        if (!isUserDataLoaded) {
            return;
        }
        reset();
        reFetchData();
        return;
    }, [reFetchData, selectedCompany, isUserDataLoaded, reset]);

    const controller: Controller = useMemo(
        () => ({
            onBankCreate: async data => {
                await Mutator.mutate<"companyCreateBank", GQL.ICompanyCreateBankInput>(
                    {
                        mutation: companyCreateBank,
                        input: {
                            ...data,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyCreateBank" }),
                        loading: null,
                    }
                );
                reFetchData();
            },
            onBankUpdate: async data => {
                await Mutator.mutate<"companyUpdateBankYearConfig", GQL.ICompanyUpdateBankInput>(
                    {
                        mutation: companyUpdateBankYearConfig,
                        input: {
                            ...data,
                            year,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyUpdateBank" }),
                        loading: null,
                    }
                );
                reFetchData();
            },
            onBankDelete: async data => {
                await Mutator.mutate<"companyDeleteBank", GQL.ICompanyNumEntityDeleteInput>(
                    {
                        mutation: companyDeleteBank,
                        input: {
                            ...data,
                            year,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyDeleteBank" }),
                    }
                );
                reFetchData();
            },
            onKBCreate: async data => {
                await Mutator.mutate<"companyCreateKB", GQL.ICompanyCreateKbInput>(
                    {
                        mutation: companyCreateKB,
                        input: {
                            ...data,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyCreateKB" }),
                        loading: null,
                    }
                );
                reFetchData();
            },
            onKBUpdate: async data => {
                await Mutator.mutate<"companyUpdateKBYearConfig", GQL.ICompanyUpdateKbInput>(
                    {
                        mutation: companyUpdateKBYearConfig,
                        input: {
                            ...data,
                            year,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyUpdateKB" }),
                        loading: null,
                    }
                );
                reFetchData();
            },
            onKBDelete: async data => {
                await Mutator.mutate<"companyDeleteKB", GQL.ICompanyNumEntityDeleteInput>(
                    {
                        mutation: companyDeleteKB,
                        input: {
                            ...data,
                            year,
                            companyId,
                        },
                    },
                    {
                        success: intl.formatMessage({ id: "app.message.companyDeleteKB" }),
                    }
                );
                reFetchData();
            },
            triggerDataRefetch: () => reFetchData(),
            updateProductSettings: (productKey: ProductKey, settings: Partial<ProgramSettings>) => {
                setProgramSettings(prev => {
                    if (!prev) {
                        return {
                            [productKey]: settings,
                        } as Record<ProductKey, ProgramSettings>;
                    }

                    return {
                        ...prev,
                        [productKey]: {
                            ...prev[productKey],
                            ...settings,
                        },
                    };
                });

                ProgramSettings.updateFields(companyId, productKey, settings).then(() => {});

                return Promise.resolve();
            },
        }),
        [reFetchData, companyId, intl, Mutator, year]
    );
    return (
        <CompanyContext.Provider value={companyContextValue}>
            <BuContext.Provider value={buContextValue}>
                <CompanyControlContext.Provider value={controller}>
                    <YearPeriodContext.Provider value={yearMonthValue}>{props.children}</YearPeriodContext.Provider>
                </CompanyControlContext.Provider>
            </BuContext.Provider>
        </CompanyContext.Provider>
    );
};

import React, { FC, useContext } from "react";
import { InvoiceForm } from "@inv/components/InvoiceForm";
import {
    InvoiceFormContext,
    InvoiceFormControlContext,
} from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";

export const CreateInvoiceModule: FC = () => {
    const { initialValues } = useContext(InvoiceFormContext);
    const { createInvoice } = useContext(InvoiceFormControlContext);
    return <InvoiceForm initialValues={initialValues} handleSave={createInvoice} />;
};

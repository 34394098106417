import React, { ChangeEvent, useState } from "react";
import { Input } from "antd";
import { CustomCellEditorProps } from "ag-grid-react";
import { IInvoiceFormLineItem, InvoiceColumns, NavigationKeys } from "@inv/types";
import { stringToFloat } from "@inv/scripts/utils/utils";

import styles from "@inv/modules/InvoiceTableModule/Editors/editors.module.scss";

export const PriceEditor: React.FC<CustomCellEditorProps<IInvoiceFormLineItem>> = ({
    data,
    value,
    node,
    onValueChange,
    stopEditing,
}) => {
    const [inputValue, setInputValue] = useState<string>(value);
    const discountPercent = data.discount ? (data.price - data.discount) / data.price : data.price;

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => setInputValue(target.value);
    const handleFocus = ({ target }: React.FocusEvent<HTMLInputElement>) => target.select();

    const handleConfirm = (newValue: string) => {
        const parseValue = stringToFloat(newValue) * 100;
        const newDiscountPrice = parseValue - parseValue * discountPercent;

        if (isNaN(parseValue) || parseValue < 0) {
            return stopEditing();
        }

        node.setDataValue(InvoiceColumns.DISCOUNT, newDiscountPrice);
        onValueChange(parseValue);
        stopEditing();
    };

    return (
        <Input
            value={inputValue}
            onChange={handleChange}
            className={styles.agEditor}
            variant="borderless"
            onKeyDown={e => {
                if (e.key === NavigationKeys.ENTER) {
                    handleConfirm(inputValue);
                }
            }}
            onFocus={handleFocus}
            onBlur={() => handleConfirm(inputValue)}
            autoFocus
        />
    );
};

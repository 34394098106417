import { Form, Typography } from "antd";
import cn from "classnames";
import { PickerRef } from "rc-picker";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

import styles from "../../../components/DocumentForm/DocumentFormFields/DocumentFormField.module.scss";
import { DateInput } from "../DocumentInputs/DateInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

interface IProps {
    aiResult?: boolean;
}

const SingleDateField = forwardRef<PickerRef, IProps>((props, ref) => {
    const { necessaryFields } = useContext(SingleFormContext);
    return (
        <FieldLabel
            label={
                <Text strong ellipsis type={necessaryFields?.includes("documentDate") ? "warning" : undefined}>
                    {<FormattedMessage id="app.dms.documentDate" />}
                </Text>
            }
            className={cn(`DMSForm__Item`, {
                [styles.aiResult]: props.aiResult,
            })}
            name={"documentDate"}
        >
            <DateInput ref={ref} />
        </FieldLabel>
    );
});

const GroupDateField = forwardRef<PickerRef>((props, ref) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel, necessaryGroupFields } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField?.includes("documentDate") ? "Diverse" : undefined;

    return (
        <div className={"BinaleForm__Item DMSForm__Item"}>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"documentDateCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"documentDate"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                label={
                    <Text strong ellipsis type={necessaryGroupFields?.includes("documentDate") ? "warning" : undefined}>
                        {<FormattedMessage id="app.dms.documentDate" />}
                    </Text>
                }
                className={"DMSForm__Item"}
                name={"documentDate"}
            >
                <DateInput ref={ref} placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </div>
    );
});

export const DocumentDateField = forwardRef<PickerRef, IProps>((props, ref) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return (
        <>{isGroupEditor ? <GroupDateField ref={ref} /> : <SingleDateField ref={ref} aiResult={props.aiResult} />}</>
    );
});

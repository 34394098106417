import React, { useContext, useMemo } from "react";
import { Category, Creditor } from "../../../../scripts/models";
import { CompanyContext } from "scripts/context/CompanyContext";
import { BuMNF, GQL, Utils } from "@binale-tech/shared";
import { ProductAccessUtils } from "../../../../scripts/models/utils/ProductAccessUtils";
import { useFormValidators } from "./useFormValidators";
import { RecordFormStateContext } from "../context/RecordFormState";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useFormConfig } from "./useFormConfig";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";

export const useFormProperties = () => {
    const { product } = useContext(TableViewContext);
    const productFormConfig = useFormConfig();
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const formState = React.useContext(RecordFormStateContext);
    const { refsHtml, disabled } = useContext(RecordFormPropsContext);
    const { recordValidator, itemValidator } = useFormValidators();

    const itemAccount = formState.editableRecordItem?.itemCategoryCreditor;
    const recordAccount = formState.editableRecord?.recordCategoryCreditor;

    const isPartialEditMode = Boolean(formState.selectedPayment);

    const isUstDisabled = useMemo(() => {
        const productKey = product.productKey() as GQL.IProductKey;
        if (itemAccount instanceof Category) {
            if (productKey === GQL.IProductKey.ErA) {
                return false;
            }
            return itemAccount.isAutoBu();
        }
        if (itemAccount instanceof Creditor) {
            if (
                Utils.PaymentUtils.isProductPaymentRepresentation(productKey) &&
                BuMNF.isMNFLogicForKonto(yearConfig, itemAccount)
            ) {
                return false;
            }
            return true;
        }
        return productFormConfig.disableBu;
    }, [product, yearConfig, itemAccount, productFormConfig]);

    const isContactFieldDisabled = useMemo(() => {
        if (isPartialEditMode) {
            return true;
        }
        if (disabled) {
            return true;
        }
        if (!productFormConfig.useContact) {
            return true;
        }
        const hasContactsAccess = ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Contacts, companyGQL);
        if (!hasContactsAccess) {
            return true;
        }
        // if (this.isModalMode) {
        //     if (this.props.productFormConfig.useContact !== "afterDate") {
        //         return true;
        //     }
        // }
        return !hasContactsAccess;
    }, [companyGQL, disabled, isPartialEditMode, productFormConfig.useContact]);

    const isSplitDisabled = useMemo(() => {
        if (isPartialEditMode) {
            return true;
        }
        if (formState.recordItems.length > 0) {
            return false;
        }
        const { recordNum, recordCategoryCreditor } = formState.editableRecord;
        const { itemBrutto } = formState.editableRecordItem;
        const fieldsRecord: Map<React.RefObject<any>, any> = new Map([[refsHtml.REF_rBELEGFELD1, recordNum]]);
        if (!productFormConfig.putRecordCategoryCreditorAfterUst) {
            fieldsRecord.set(refsHtml.REF_rCATEGORYCREDITOR, recordCategoryCreditor as any);
        }
        const fieldsItem = new Map([[refsHtml.REF_iBRUTTO, itemBrutto]]);
        const errorsRecord = recordValidator.validate(fieldsRecord, formState);
        const errorsItem = itemValidator.validate(fieldsItem, formState);
        return errorsRecord.length + errorsItem.length > 0;
    }, [
        isPartialEditMode,
        formState,
        refsHtml.REF_rBELEGFELD1,
        refsHtml.REF_iBRUTTO,
        refsHtml.REF_rCATEGORYCREDITOR,
        productFormConfig.putRecordCategoryCreditorAfterUst,
        recordValidator,
        itemValidator,
    ]);

    const isUst13bDisabled = useMemo(
        () => itemAccount instanceof Category && Boolean(itemAccount.sv13b),
        [itemAccount]
    );

    const isBindingButtonDisabled = useMemo(
        () => !(itemAccount instanceof Creditor) && !(recordAccount instanceof Creditor),
        [itemAccount, recordAccount]
    );

    return useMemo(
        () => ({
            isUstDisabled,
            isUst13bDisabled,
            isBindingButtonDisabled,
            isPartialEditMode,
            isContactFieldDisabled,
            isSplitDisabled,
        }),
        [
            isUstDisabled,
            isUst13bDisabled,
            isBindingButtonDisabled,
            isPartialEditMode,
            isContactFieldDisabled,
            isSplitDisabled,
        ]
    );
};

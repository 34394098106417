import React, { FC, PropsWithChildren } from "react";
import { Col, Divider } from "antd";
import { themeConfig } from "@inv/theme";

interface IProps extends PropsWithChildren {
    title: string;
}

export const InvoiceFormItemWrapper: FC<IProps> = ({ children, title }) => {
    return (
        <Col
            style={{
                padding: "4px 20px",
                borderRadius: "8px",
                backgroundColor: themeConfig.colorWhite,
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            }}
        >
            <Divider orientation={"left"}>{title}</Divider>
            {children}
        </Col>
    );
};

import React, { useMemo } from "react";
import { FieldLabel, FieldLabelProps } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";
import { RefSelectProps } from "antd";

import {
    InputWithAutocomplete,
    InputWithAutocompleteProps
} from "../baseComponents/InputWithAutocomplete/InputWithAutocomplete";
import { useModuleRecords } from "scripts/infrastructure/hooks/useModuleRecords";

interface BuchungstextInputBlockProps extends Omit<InputWithAutocompleteProps, "storageId" | "autocompleteList"> {
    labelProps?: FieldLabelProps;
}

export const BuchungstextInputBlock = React.forwardRef<RefSelectProps, BuchungstextInputBlockProps>(
    function BuchungstextInputBlock({ labelProps, ...props }, ref) {
        const records = useModuleRecords();

        const autocompleteList = useMemo(() => {
            const textSet = new Set<string>();
            records.forEach(v => {
                v.items.forEach(i => {
                    if (i.text) {
                        textSet.add(i.text);
                    }
                });
            });
            return Array.from(textSet);
        }, [records]);

        return (
            <FieldLabel label={<FormattedMessage id="app.fields.buchtext" />} {...labelProps}>
                <InputWithAutocomplete
                    ref={ref}
                    {...props}
                    inputProps={{ showCount: true, ...props.inputProps }}
                    maxLength={60}
                    storageId="buchText1"
                    selectTextOnFocus
                    autocompleteList={autocompleteList}
                />
            </FieldLabel>
        );
    }
);

export const Buchungstext2InputBlock = React.forwardRef<RefSelectProps, BuchungstextInputBlockProps>(
    function Buchungstext2InputBlock({ labelProps, ...props }, ref) {
        const records = useModuleRecords();

        const autocompleteList = useMemo(() => {
            const textSet = new Set<string>();
            records.forEach(v => {
                v.items.forEach(i => {
                    if (i.text2) {
                        textSet.add(i.text2);
                    }
                });
            });
            return Array.from(textSet);
        }, [records]);

        return (
            <FieldLabel label={<FormattedMessage id="app.fields.buchtext2" />} {...labelProps}>
                <InputWithAutocomplete
                    ref={ref}
                    {...props}
                    inputProps={{ showCount: true, ...props.inputProps }}
                    maxLength={80}
                    storageId="buchText2"
                    selectTextOnFocus
                    autocompleteList={autocompleteList}
                />
            </FieldLabel>
        );
    }
);

import React, { FC, useContext } from "react";
import { BankModal } from "banks/components/BankModal";
import { BanksAppContext } from "@banks/scripts/context";
import { EditBankContextProvider } from "@banks/modules/EditBankModule/context";
import { EditBankForm } from "banks/modules/EditBankModule/components/EditBankForm";
import { FormattedMessage } from "react-intl";

export const EditBankModule: FC = () => {
    const { editBankData } = useContext(BanksAppContext);

    return (
        <EditBankContextProvider>
            <BankModal
                title={<FormattedMessage id={"app.banks.editBank"} />}
                open={editBankData.isModalOpen}
                footer={false}
                closable={false}
                style={{ maxWidth: "95vw" }}
                width={"800px"}
                destroyOnClose
            >
                <EditBankForm />
            </BankModal>
        </EditBankContextProvider>
    );
};

import React, { type FC, useContext, useEffect, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { ViewColumnsControl } from "@inv/components/ViewColumnsControl";
import {
    InvoiceTableContext,
    InvoiceTableControlContext,
} from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { InvoiceColumnsTranslate } from "./constants";
import { InvoiceFormContext } from "@inv/modules/CreateInvoiceModule/context/InvoiceFormContext";
import { InvoiceColumns } from "@inv/types";

export const SettingsColumns: FC<IHeaderParams> = () => {
    const { columnsConfig, isOpenSettingsCol } = useContext(InvoiceTableContext);
    const { setColumnsConfig, setIsOpenSettingsCol } = useContext(InvoiceTableControlContext);
    const { invoiceTotalView } = useContext(InvoiceFormContext);

    const [requiredTableCols, setRequiredTableCols] = useState(InvoiceFormColumnsConfig.RequiredTableCols);

    useEffect(() => {
        if (invoiceTotalView) {
            setRequiredTableCols(InvoiceFormColumnsConfig.RequiredTableCols);
            return;
        }
        setRequiredTableCols(prev => [...prev, InvoiceColumns.TAX]);
    }, [invoiceTotalView]);

    return (
        <ViewColumnsControl
            columnsConfig={columnsConfig}
            setColumnsConfig={setColumnsConfig}
            columnTranslations={InvoiceColumnsTranslate}
            setIsOpenSettingsCol={setIsOpenSettingsCol}
            isOpenSettingsCol={isOpenSettingsCol}
            requiredColumns={requiredTableCols}
        />
    );
};

import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { DateInput } from "../DocumentInputs/DateInput";
import { FieldLabel } from "../../../../appearance/components/shared/form/baseComponents/FieldLabel/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

export const SingleDueDateField = () => {
    const { formatMessage } = useIntl();

    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.dueDate" />
                </Text>
            }
            name={"dueDate"}
        >
            <DateInput placeholder={formatMessage({ id: "app.fields.dueDate" })} />
        </FieldLabel>
    );
};

const GroupDueDateField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField?.includes("dueDate") ? "Diverse" : undefined;

    return (
        <div className={"BinaleForm__Item DMSForm__Item"}>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"dueDateCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"dueDate"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                label={
                    <Text strong ellipsis>
                        {<FormattedMessage id="app.fields.dueDate" />}
                    </Text>
                }
                className={"DMSForm__Item"}
                name={"dueDate"}
            >
                <DateInput placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </div>
    );
};

export const DocumentDueDateField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupDueDateField /> : <SingleDueDateField />}</>;
};

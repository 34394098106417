import React, { forwardRef, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import type { AgGridReactProps } from "ag-grid-react/dist/types/src/shared/interfaces";

interface IProps extends AgGridReactProps {
    tableKey?: string;
    wrapperClassName?: string;
    wrapperStyles?: React.CSSProperties;
}

export const AgGridTable = memo(
    forwardRef<AgGridReact, IProps>((props, ref) => {
        const { className = "ag-theme-quartz", tableKey, wrapperStyles, ...restProps } = props;
        return (
            <div className={className} style={{ height: "100%", ...wrapperStyles }}>
                <AgGridReact ref={ref} key={tableKey} {...restProps} />
            </div>
        );
    })
);

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Bu } from "@binale-tech/shared";
import { Button, Input, InputProps, InputRef, RefSelectProps, Select, SelectProps, Space, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { FieldError } from "react-hook-form";
import { FieldLabel } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";

import Ust13bModal from "../../modals/Ust13b";
import { BaseSelect } from "appearance/components/shared/form/baseComponents/BaseSelect";
import { BuTaxDB } from "scripts/models/Bu";
import { focusNextElement } from "scripts/infrastructure/helpers/focus";

interface UstProps {
    options: BuTaxDB[];
    value: Bu.Bu;
    onChange: (v: Bu.Bu) => void;
}

const UstSelect = React.forwardRef<RefSelectProps, UstProps & Omit<SelectProps, "options">>(function UstSelect(
    { options, ...props },
    ref
) {
    const parseStringValue = (v: string): Bu.Bu => {
        const newValue = !v ? Bu.Bu.KU : (Number(v) as Bu.Bu);
        return options.find(option => option.bu === newValue) ? newValue : Bu.Bu.KU;
    };
    return (
        <BaseSelect
            ref={ref}
            notFoundContent={<FormattedMessage id="app.components.table.no_items" />}
            {...props}
            onChange={(value: string) => {
                props.onChange(parseStringValue(value));
            }}
        >
            {(options || []).map((option, idx) => (
                <Select.Option key={"option-" + idx} value={option.bu}>
                    {option.bu === Bu.Bu.KU ? <span>&nbsp;</span> : option.text}
                </Select.Option>
            ))}
        </BaseSelect>
    );
});

const ExpertUstInput = React.forwardRef<InputRef, UstProps & Omit<InputProps, "onChange">>(function ExpertUstInput(
    { options, value, onChange, ...rest },
    ref
) {
    const [innerValue, setInnerValue] = useState<string | null>(value === Bu.Bu.KU || !value ? "" : value.toString());
    useEffect(() => {
        setInnerValue(value === Bu.Bu.KU || !value ? "" : value.toString());
    }, [value]);

    const parseStringValue = (v: string): Bu.Bu => {
        const newValue = !v ? Bu.Bu.KU : (Number(v) as Bu.Bu);
        return options.find(option => option.bu === newValue) ? newValue : Bu.Bu.KU;
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (innerValue && parseStringValue(innerValue) === Bu.Bu.KU) {
            setInnerValue("");
        }
        rest.onBlur && rest.onBlur(e);
    };

    const handleChange = (v: string) => {
        setInnerValue(v);
        onChange(parseStringValue(v));
    };

    return (
        <Input
            value={innerValue}
            onChange={e => handleChange(e.target.value)}
            onFocus={e => e.target.select()}
            ref={ref}
            {...rest}
            onBlur={handleBlur}
        />
    );
});

interface Props13b {
    value?: Bu.USt13bOption;
    onChange?: (value: Bu.USt13bOption) => void;
    disabled: boolean;
    visible: boolean;
    setVisibility: (show: boolean) => void;
    validationState?: FieldError;
    bu: Bu.Bu;
}

export const Ust13bAddon: React.FC<Props13b> = ({
    onChange,
    value,
    disabled,
    visible,
    setVisibility,
    validationState,
    bu,
}) => {
    return (
        <>
            {value && (
                <Tooltip title={Bu.USt13bTitles[value]}>
                    <Button
                        tabIndex={-1}
                        style={{ padding: "4px 11px" }}
                        onClick={() => setVisibility(true)}
                        disabled={disabled}
                    >
                        {value}
                    </Button>
                </Tooltip>
            )}
            {!value && (
                <Button
                    disabled={disabled}
                    icon={<CopyOutlined style={{ color: validationState ? "#f5222d" : null }} />}
                    tabIndex={-1}
                    onClick={() => setVisibility(true)}
                />
            )}
            {visible && (
                <Ust13bModal
                    value13b={value}
                    onChange13b={newValue => {
                        onChange(newValue);
                        setVisibility(false);
                    }}
                    bu={bu}
                    onCancel={() => setVisibility(false)}
                />
            )}
        </>
    );
};

interface UstSelectBlockProps extends UstProps {
    validationState?: FieldError;
    useExpertBu?: boolean;
    onChange13b?: (value: Bu.USt13bOption) => void;
    value13b?: Bu.USt13bOption;
    disabled?: boolean;
    disabled13b?: boolean;
}

export const UstSelectBlock = React.forwardRef<InputRef | RefSelectProps, UstSelectBlockProps>(function UstSelectBlock(
    { validationState, useExpertBu, value13b, onChange13b, disabled13b, ...props },
    ref
) {
    const ustSelectRef = useRef<RefSelectProps>();
    const ustInputRef = useRef<InputRef>();
    const [show13bPopover, setShow13bPopover] = useState<boolean>();
    // open 13b modal after onBlur, and only one time
    const haveBeenClosed = useRef(false);

    // for correct typing forwardRef and using refs inside component
    useImperativeHandle(ref, () => (useExpertBu ? ustInputRef.current : ustSelectRef.current), [
        ustSelectRef,
        ustInputRef,
        useExpertBu,
    ]);

    const handleBlur = (e: React.FocusEvent) => {
        if (props.value && Bu.getUst13bData(props.value).options && !value13b && !haveBeenClosed.current) {
            setShow13bPopover(true);
        }
        if (haveBeenClosed) {
            haveBeenClosed.current = false;
        }
    };

    const handleChange13b = (value: Bu.USt13bOption) => {
        if (value) {
            onChange13b && onChange13b(value);
            setShow13bPopover(false);
        }
    };

    useEffect(() => {
        if (show13bPopover === false) {
            useExpertBu ? ustInputRef.current.focus() : ustSelectRef.current.focus();
            haveBeenClosed.current = true;
            focusNextElement({ parentSelector: "form" });
        }
    }, [show13bPopover, useExpertBu]);
    return (
        <FieldLabel
            fieldError={validationState ? { type: "error", message: " " } : undefined}
            label={useExpertBu ? "BU" : <FormattedMessage id="app.fields.vat%" />}
            labelAddon={<span style={{ marginRight: 4 }}>13b</span>}
        >
            <Space.Compact>
                {useExpertBu && (
                    <ExpertUstInput {...props} ref={ustInputRef} style={{ width: 50 }} onBlur={handleBlur} />
                )}
                {!useExpertBu && <UstSelect {...props} ref={ustSelectRef} style={{ width: 100 }} onBlur={handleBlur} />}
                <Ust13bAddon
                    value={value13b}
                    onChange={handleChange13b}
                    disabled={!props.value || !Bu.getUst13bData(props.value).options || disabled13b}
                    visible={show13bPopover}
                    setVisibility={setShow13bPopover}
                    validationState={validationState}
                    bu={props.value}
                />
            </Space.Compact>
        </FieldLabel>
    );
});

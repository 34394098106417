import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FieldLabel } from "@binale-tech/ui-components";
import { Form, Row, Space } from "antd";
import { FormattedMessage } from "react-intl";

import { BaseInput } from "appearance/components/shared/form/baseComponents";
import { Contacts, GQL } from "@binale-tech/shared";
import { CountriesCombobox } from "../../components/CountriesCombobox";
import { TypeOfRegisterCombobox } from "../../components/TypeOfRegisterCombobox";
import { useCustomValidation, useLegalInfoValidation } from "../utils";
import { useValidation } from "scripts/infrastructure/helpers/validation";
import { useEuroCountryArray } from "../../../../../scripts/infrastructure/hooks/useEuroCountryArray";
import { validateInput } from "../../../../../scripts/infrastructure/helpers/validateVatInput";

interface Props {
    item: Contacts.Contact | null;
}

export const LegalInformation = React.memo<Props>(function LegalInformation({ item }) {
    const { watch, setValue, setError, clearErrors } = useFormContext<Contacts.Contact>();
    const validation = useValidation();
    const legalInfo = watch("legalInfo");
    const type = watch("type");
    const customValidators = useCustomValidation(item);
    const legalValidators = useLegalInfoValidation(item);
    const euroCountriesArray = useEuroCountryArray();

    return (
        <>
            <Row>
                <Space>
                    <Controller
                        name={`legalInfo.landCode`}
                        rules={{ validate: legalValidators.vat, deps: ["legalInfo.UStIdNr"] }}
                        render={({ field, fieldState }) => {
                            return (
                                <FieldLabel
                                    label={<FormattedMessage id="app.fields.euroCountryCode" />}
                                    data-testid="legalInfo-landCode"
                                    fieldError={fieldState.error}
                                >
                                    <CountriesCombobox
                                        optionsList={euroCountriesArray}
                                        {...field}
                                        onChange={value => {
                                            setValue(`legalInfo.UStIdNr`, "");
                                            field.onChange(value);
                                        }}
                                    />
                                </FieldLabel>
                            );
                        }}
                    />
                    <Controller
                        name="legalInfo.UStIdNr"
                        rules={{ validate: legalValidators.vat, deps: "legalInfo.UStIdNr" }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.vatNb" />}
                            >
                                <BaseInput
                                    data-testid="UStIdNr"
                                    {...field}
                                    maxLength={
                                        legalInfo?.landCode
                                            ? Contacts.ContactConstants.CountriesUstIdLength[legalInfo.landCode]
                                            : 10
                                    }
                                    showCount
                                    disabled={!legalInfo?.landCode}
                                    status={fieldState.error ? "error" : ""}
                                    onChange={val => {
                                        setValue("legalInfo.UStIdNr", val);
                                        if (!validateInput(legalInfo?.landCode, val)) {
                                            setError("legalInfo.UStIdNr", {
                                                type: "manual",
                                            });
                                        } else {
                                            clearErrors("legalInfo.UStIdNr");
                                        }
                                    }}
                                />
                            </FieldLabel>
                        )}
                    />
                    <Form.Item label=" " data-testid="legalInfo-countryName">
                        {euroCountriesArray.find(option => option.value === legalInfo?.landCode)?.name || ""}
                    </Form.Item>
                </Space>
            </Row>

            <Space align="start">
                <Controller
                    name="legalInfo.taxNumber"
                    rules={{ validate: customValidators.taxNumber }}
                    render={({ field, fieldState }) => (
                        <FieldLabel
                            fieldError={fieldState.error}
                            label={<FormattedMessage id="app.fields.taxNumber" />}
                            style={{ maxWidth: 170 }}
                        >
                            <BaseInput data-testid="legalInfo-taxNumber" {...field} maxLength={15} showCount />
                        </FieldLabel>
                    )}
                />
                <Controller
                    name={"legalInfo.IDNr" as any}
                    // todo: there is some problem with reseting validation after type change
                    rules={{
                        validate: customValidators.IDNr,
                        minLength: validation.strict(11),
                        deps: ["type"],
                    }}
                    shouldUnregister
                    render={({ field, fieldState }) => (
                        <FieldLabel label="ID-Nr" style={{ maxWidth: 140 }} fieldError={fieldState.error}>
                            <BaseInput
                                {...field}
                                data-testid="legalInfo-IDNr"
                                disabled={type !== GQL.IContactType.Individual}
                                title={
                                    type !== GQL.IContactType.Individual ? "enabled if individual person selected" : ""
                                }
                                pattern="^[0-9]*$"
                                maxLength={11}
                                showCount
                            />
                        </FieldLabel>
                    )}
                />
                <Controller
                    name="legalInfo.EORI"
                    rules={{ validate: customValidators.EORI }}
                    render={({ field, fieldState }) => (
                        <FieldLabel fieldError={fieldState.error} label="EORI">
                            <BaseInput data-testid="legalInfo-EORI" {...field} maxLength={17} showCount />
                        </FieldLabel>
                    )}
                />
            </Space>

            <Row>
                <Space align="start">
                    <Controller
                        name="legalInfo.typeOfRegister"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.registerCourt", "legalInfo.registerNumber"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.typeOfRegister" />}
                                style={{ minWidth: 280 }}
                                data-testid="legalInfo-typeOfRegister"
                            >
                                <TypeOfRegisterCombobox
                                    {...field}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    dropdownRender={menu => (
                                        <div data-testid="legalInfo-typeOfRegister-dropdown">{menu}</div>
                                    )}
                                />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name="legalInfo.registerCourt"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.typeOfRegister", "legalInfo.registerNumber"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.registerCourt" />}
                                style={{ marginBottom: 0, maxWidth: 400, minWidth: 300 }}
                            >
                                <BaseInput
                                    data-testid="legalInfo-registerCourt"
                                    pattern="^[A-Za-z äöüßÄÖÜẞ]*$"
                                    {...field}
                                    maxLength={35}
                                    showCount
                                />
                            </FieldLabel>
                        )}
                    />
                    <Controller
                        name="legalInfo.registerNumber"
                        rules={{
                            validate: legalValidators.register,
                            deps: ["legalInfo.typeOfRegister", "legalInfo.registerCourt"],
                        }}
                        render={({ field, fieldState }) => (
                            <FieldLabel
                                fieldError={fieldState.error}
                                label={<FormattedMessage id="app.fields.registerNumber" />}
                                style={{ minWidth: 200 }}
                            >
                                <BaseInput data-testid="legalInfo-registerNumber" {...field} maxLength={17} showCount />
                            </FieldLabel>
                        )}
                    />
                </Space>
            </Row>
        </>
    );
});

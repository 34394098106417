import React from "react";
import { Contacts } from "@binale-tech/shared";
import { DefaultOptionType } from "antd/es/select";
import { RefSelectProps, Select, SelectProps, Typography } from "antd";

export const TypeOfRegisterCombobox = React.forwardRef<RefSelectProps, SelectProps>(
    function TypeOfRegisterCombobox(props, ref) {
        const dataValue: DefaultOptionType[] = Object.keys(Contacts.ContactConstants.RegisterList).map(
            (key: Contacts.ContactConstants.RegisterEnum) => ({
                label: (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ marginRight: 16 }}>{Contacts.ContactConstants.RegisterList[key]}</span>
                        <Typography.Text type="secondary" style={{ minWidth: 40, textAlign: "right" }}>
                            {key}
                        </Typography.Text>
                    </div>
                ),
                value: key,
            })
        );

        return <Select allowClear {...props} options={dataValue} ref={ref} />;
    }
);

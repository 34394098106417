import { orange } from "@ant-design/colors";
import { ConfigProvider, Switch } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { DmsAppContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";

import styles from "./selectFilters.module.scss";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

export const DraftSelectBtn: FC = () => {
    const { activeType } = useContext(DmsAppContext);
    const { userConfig, setFilterConfig } = useContext(DmsUserSettingsContext);

    const typeConfig = DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);

    const handleSelectDraft = () => {
        if (!typeConfig) {
            return;
        }

        const isDraftSelected = typeConfig.isDraftSelected ?? false;

        setFilterConfig(activeType, {
            isDraftSelected: !isDraftSelected,
        });
    };

    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: orange[5],
                        colorPrimaryHover: orange[4],
                        colorTextQuaternary: orange[2],
                        colorTextTertiary: orange[1],
                    },
                }}
            >
                <Switch
                    className={styles.switchWidth}
                    checkedChildren={<FormattedMessage id="app.fields.draft" />}
                    unCheckedChildren={<FormattedMessage id="app.fields.draft" />}
                    onChange={handleSelectDraft}
                    checked={typeConfig?.isDraftSelected ?? false}
                />
            </ConfigProvider>
        </div>
    );
};
